// ./src/lib/redux/reducers.js
import { combineReducers } from "redux";
import discountDataReducer from "./slices/discountDataSlice";
import productDataReducer from "./slices/productDataSlice";
import sliderDataReducer from "./slices/sliderDataSlice";
import favoritesReducer from "./slices/favoritesSlice";
import publisherReducer from "./slices/publisherSlice";
import discountsReducer from "./slices/discountsSlice";
import productsReducer from "./slices/productsSlices";
import locationReducer from "./slices/locationSlice";
import categoryReducer from "./slices/categorySlice";
import settingsReducer from "./slices/settingsSlice";
import invoiceReducer from "./slices/invoiceSlice";
import slidersReducer from "./slices/slidersSlice";
import sidebarReducer from "./slices/sidebarSlice";
import authorReducer from "./slices/authorSlice";
import resetReducer from "./slices/resetSlice";
import modalReducer from "./slices/modalSlice";
import authReducer from "./slices/authSlice";
import cartReducer from "./slices/cartSlice";

const rootReducer = combineReducers({
  productData: productDataReducer,
  publishers: publisherReducer,
  favorites: favoritesReducer,
  discounts: discountsReducer,
  discountData: discountDataReducer,
  sliderData: sliderDataReducer,
  products: productsReducer,
  category: categoryReducer,
  authors: authorReducer,
  location: locationReducer,
  settings: settingsReducer,
  sidebars: sidebarReducer,
  invoice: invoiceReducer,
  sliders: slidersReducer,
  modal: modalReducer,
  reset: resetReducer,
  auth: authReducer,
  cart: cartReducer,
});

export default rootReducer;
