import React, { useState, useRef } from "react";
import useClickOutside from "../../../../Hooks/useClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

function DropdownMenu({ title, icon, children, svgIcon }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div className="item-bar flex gap-3" onClick={() => setIsOpen(!isOpen)}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {svgIcon && svgIcon}
        {title}
      </div>
      {isOpen && <div className="sub-menu">{children}</div>}
    </div>
  );
}

DropdownMenu.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  svgIcon: PropTypes.element,
  children: PropTypes.node.isRequired,
};

export default DropdownMenu;
