// CmsRoute.jsx
import React, { Profiler } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// ماجهات المستخدم
import Index from "../pages/ui";
import Show from "../pages/ui/show/show";

// Products pages
import Products from "../pages/dashboard/page/products/products";
import AddProduct from "../pages/dashboard/page/products/add/addProducts";
import EditProduct from "../pages/dashboard/page/products/edit/EditProduct";

// Authors pages
import AddAuthor from "../pages/dashboard/page/Authors/addAuther";
import Author from "../pages/dashboard/page/Authors/authors";

// Discounts pages
import Discounts from "../pages/dashboard/page/discounts/discounts";
import AddDiscounts from "../pages/dashboard/page/discounts/addDiscounts";

// Publisher pages
import Publisher from "../pages/dashboard/page/publisher/addPublisher";

// Category pages
import Category from "../pages/dashboard/page/category/Category";

// Home page
import Home from "../pages/dashboard/page/home";

import Statistics from "../pages/dashboard/page/statistics/statistics";
import Poe from "../pages/dashboard/components/poe/poe";
import Setting from "../pages/dashboard/page/settings/setting";
import Director from "../pages/dashboard/page/director";

// Orders pages
import Requests from "../pages/dashboard/page/Order/requests";
import NewOrders from "../pages/dashboard/page/Order/newOrders";
import Shipping from "../pages/dashboard/page/Order/shipping";
import Cancelled from "../pages/dashboard/page/Order/cancelled";
import Delivered from "../pages/dashboard/page/Order/delivered";

// Users pages
import Users from "../pages/dashboard/page/Users/users";
import AddUser from "../pages/dashboard/page/Users/addUser";
import ProFile from "../pages/ui/user/profile/ProFile";

// import table from "../components/Table/dataTable";
import Login from "../pages/auth/login";
import Info from "../pages/auth/info";
import Register from "../pages/auth/register";
import { ContextProvider } from "../pages/auth/auth";
import RequireAuth from "../pages/auth/RequireAuth";
import Dashboard from "../pages/dashboard/page/Dashboard";
import PersistLogin from "../pages/auth/PersistLogin";

import Test from "../test/test";
import TestPage from "../test/TestPage";
import ProductDetails from "../pages/ui/products/ProductDetails";
import ProductsUI from "../pages/ui/products/products";
import Homepage from "../pages/ui/Homepage";
import Cart from "../pages/ui/cart/cart";
import CartPage from "../pages/dashboard/page/cart/cartPage";
import CartDeitails from "../pages/dashboard/page/cart/cartDeitails";
import ShowAuthors from "../pages/ui/show/authors/showAuthors";
import AuthorDetails from "../pages/ui/show/authors/AuthorDetails";
import Sliders from "../pages/dashboard/page/sliders/sliders";
import CheckoutPage from "../pages/ui/Checkout/CheckoutPage";
import Books from "../pages/ui/filters/books";

import NotFound from "../components/notFound/NotFound";
import DiscountProducts from "../pages/dashboard/page/discounts/DiscountProducts";

const CmsRoute = () => {
  return (
    <ContextProvider>
      <Routes>
        {/* public route */}
        <Route path="/*" element={<Index />}>
          <Route index element={<Homepage />} />
          <Route path="products/:slug" element={<ProductDetails />} />
          <Route path="books" element={<Books />} />
          <Route path="profile" element={<ProFile />} />
          <Route path="cart/*">
            <Route index element={<Cart />} />
            <Route path="checkout" element={<CheckoutPage />} />
          </Route>
          <Route path="show/*">
            <Route path="authors" element={<ShowAuthors />} />
            <Route path="author/:slug" element={<AuthorDetails />} />
          </Route>
        </Route>
        {/* protected Routers */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="admin/*" element={<Dashboard />}>
              <Route index element={<Home />} />
              <Route path="statistics" element={<Statistics />} />

              {/* add pages */}
              <Route path="discount/*">
                <Route path="products" element={<DiscountProducts />} />
                <Route path="coupon" element={<Discounts />} />
                <Route path="add" element={<AddDiscounts />} />
              </Route>

              <Route path="poe" element={<Poe />} />
              <Route path="publisher" element={<Publisher />} />
              <Route path="category" element={<Category />} />
              <Route path="Director" element={<Director />} />

              <Route path="users/*">
                <Route index element={<Users />} />
                <Route path="add" element={<AddUser />} />
              </Route>

              <Route path="products/*">
                <Route index element={<Products />} />
                <Route path="add" element={<AddProduct />} />
                <Route path="edit/:slug" element={<EditProduct />} />
              </Route>

              <Route path="authors/*">
                <Route index element={<Author />} />
                <Route path="add" element={<AddAuthor />} />
              </Route>

              <Route path="cart/*">
                <Route index element={<CartPage />} />
                <Route path="show/:id" element={<CartDeitails />} />
              </Route>

              <Route path="stor/*">
                <Route index element={<CartPage />} />
                <Route path="sliders" element={<Sliders />} />
              </Route>

              <Route path="discounts" element={<Discounts />} />
              <Route path="setting" element={<Setting />} />

              {/* orders */}
              <Route path="requests" element={<Requests />} />
              <Route path="newOrders" element={<NewOrders />} />
              <Route path="shipping" element={<Shipping />} />
              <Route path="cancelled" element={<Cancelled />} />
              <Route path="delivered" element={<Delivered />} />
              <Route path="table" element={<table />} />
              <Route path="test" element={<TestPage />} />
              <Route path="test2" element={<Test />} />
            </Route>
          </Route>

          {/* login */}
          <Route path="info" element={<Info />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ContextProvider>
  );
};

export default CmsRoute;
