import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import CustomTable from "../components/Table/CustomTable";
import DeletePopup from "../../../components/common/Popup modal/deletePopup";
import Pagination from "../../../components/common/pagination/pagination";
import { baseURL , GIT_ADMIN } from "../../../api/api";
import { Toaster, toast } from "sonner";

export default function DirectorComponent() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteUser, setDeleteUser] = useState(false);

  const itemsPerPage = 10;
  const columns = ["الأسم", "اسم المستخدم", "الأيميل", "رقم الهاتف", "العنوان"];
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("Bearer");

  const fetchUsers = async () => {
    if (!token) {
      return;
    }

    try {
      const response = await axios.get('http://localhost:8000/api/getAdmin', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error("حدث خطأ أثناء جلب البيانات:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (!token || !selectedItem) return;

    try {
      const res = await axios.delete(
        `http://127.0.0.1:8000/api/user/${selectedItem}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        toast.success("تم حذف المستخدم بنجاح");
      }

      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== selectedItem)
      );
      setShowModal(false);
      setDeleteUser(true);
      setSelectedItem(null);
    } catch (error) {
      console.error("خطأ في حذف المستخدم:");
      toast.error("حدث خطاء اثناء حذا المستخدم : ", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token, deleteUser]);

  const onPageChange = (page) => setCurrentPage(page);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = users
    .slice(indexOfFirstItem, indexOfLastItem)
    .map((user) => ({
      id: user.id,
      info: [
        user.name || "-",
        user.username || "-",
        user.email || "-",
        user.phone || "-",
        user.address || "-",
      ],
    }));

  console.log(loading);

  const handleAddUser = () => navigate("add");
  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>المدراء</h2>
        <button className="btn-style" onClick={handleAddUser}>
          إضافة مدير جديد
        </button>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">المدراء</div>
      </div>

      <div className="container-form">
        <div className="flex flex-wrap gap-3 justify-between">
          <input
            type="text"
            className="max-w-96"
            name="name"
            id="name"
            placeholder="البحث ..."
          />
          <select name="" className="max-w-md">
            <option value="1">الكل</option>
            <option value="2">محظور من الخدمة</option>
            <option value="3">مستخدم جديد</option>
            <option value="4">مستخدم قديم</option>
          </select>
        </div>
      </div>

      <CustomTable
        columns={columns}
        data={currentData}
        onDelete={handleDelete}
        loading={loading}
        Notfound="لا يوجد مدراء حاليًا"
      />
      <DeletePopup
        show={showModal}
        setOpenModal={setShowModal}
        onConfirm={confirmDelete}
      />
      {loading || currentPage === 1 ? null : (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(users.length / itemsPerPage)}
          onPageChange={onPageChange}
          previousLabel="السابق"
          nextLabel="التالي"
          showIcon={true}
        />
      )}
      <Toaster
        richColors
        // position="bottom-right"
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
          },
          className: "my-toast",
        }}
      />
    </div>
  );
}
