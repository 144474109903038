import React, { useState, useEffect } from "react";
import logo from "../../assets/logo/logoOnW.png";
import {
  Avatar,
  Bell,
  ShoppingCart,
  Gift,
  BookOpen,
  Hamburger,
  IconSearch,
} from "../../assets/icons/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCart,
  fetchTotalProducts,
  toggleCart,
} from "../../lib/redux/slices/cartSlice";
import { toggleSidebar } from "../../lib/redux/slices/sidebarSlice";
import SearchBar from "../common/Search/SearchBar";
import useClickOutside from "../../Hooks/useClickOutside";
import SidebarContent from "../Sidebar/SidebarContent";
import "./styleNavbar.css";
import { toggleModal } from "../../lib/redux/slices/modalSlice";

const NavBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [slidnotification, setSlidnotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(20);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const dispatch = useDispatch();
  const { totalProducts, loading, error } = useSelector((state) => state.cart);
  const dropdownRef = useClickOutside(setDropdownOpen, dropdownOpen);

  const handleOpen = () => {
    dispatch(toggleCart());
  };

  const handleClose = () => {
    dispatch(closeCart());
  };


  useEffect(() => {
    dispatch(fetchTotalProducts());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  const handleSidebarToggle = (id) => {
    dispatch(toggleSidebar({ id }));
  };

  return (
    <nav
      className={`bg-white shadow py-3 px-3 items-center justify-between transition-transform duration-300 ${
        isVisible ? "translate-y-0" : "-translate-y-full"
      }`}
      style={{
        position: "sticky",
        top: 0,
        width: "100%",
        zIndex: 899,
      }}
    >
      <div className="flex justify-between items-center gap-3 relative">
        <div className="flex items-center w-[212px]">
          <img src={logo} alt="Logo" className="h-9" />
          <div className="md:hidden flex-1">
            <IconSearch className="p-1.5 h-9 w-9 mx-3 text-gray-600" onClick={()=> dispatch(toggleModal("SearchPage"))} />
          </div>
        </div>
        {/* Search Bar */}
        <div className="hidden md:flex items-center justify-center gap-3">
          <SearchBar className="md:block hidden min-w-[400px]"  />
        </div>

        <div className={`flex-col flex gap-3 items-end`}>
          {/* Icons */}
          <div className="flex gap-2 items-center justify-end">
            <span className="hidden sm:block hover:bg-gray-300 relative h-9 w-9 p-1.5 rounded-full text-gray-600">
              <BookOpen className="w-full h-full" />
            </span>
            <span className="hover:bg-gray-300 hidden sm:block relative h-9 w-9 p-1.5 rounded-full text-gray-600">
              <Gift className="w-full h-full" />
              <span
                className="absolute top-1 right-1 inline-flex items-center justify-center w-5 h-5 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2"
                style={{
                  fontSize: "0.7rem",
                }}
              >
                {11}
              </span>
            </span>
            <span
              onClick={handleOpen}
              className="hover:bg-gray-300 relative h-9 w-9 p-1.5 rounded-full text-gray-600"
            >
              <ShoppingCart className="w-full h-full" />
              {totalProducts > 0 && (
                <span
                  className="absolute top-1 right-1 inline-flex items-center justify-center w-5 h-5 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2"
                  style={{
                    fontSize: "0.7rem",
                  }}
                >
                  {totalProducts > 99 ? "99+" : totalProducts}
                </span>
              )}
            </span>
            <span
              className="notification hover:bg-gray-300 relative h-9 w-9 p-1.5 rounded-full text-gray-600"
              onClick={() => handleSidebarToggle("NotificationsSidebar")}
            >
              <Bell notificationCount={notificationCount} />
              <span
                className="absolute top-1 right-1 inline-flex items-center justify-center w-5 h-5 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2"
                style={{
                  fontSize: "0.7rem",
                }}
              >
                {notificationCount > 99 ? "99+" : notificationCount}
              </span>
              {/* <span className="flex absolute z-0 justify-center items-center w-full h-full text-amber-700 bg-amber-300 rounded-full ring-2 ring-amber-400 animate-ping"></span> */}
            </span>

            <div className="relative hidden sm:block" ref={dropdownRef}>
              <span onClick={() => setDropdownOpen(!dropdownOpen)}>
                <Avatar className="outline outline-offset-2 rounded-full outline-gray-200 outline-2" />
              </span>
              {dropdownOpen && (
                <div
                  className="absolute left-0 mt-2 w-60 bg-white border z-50 rounded shadow py-2"
                  style={{ border: "1px solid hsl(0, 0%, 90%)" }}
                >
                  <SidebarContent />
                </div>
              )}
            </div>
            <span
              className="hover:bg-gray-300 relative h-9 w-9 p-1.5 rounded-full text-gray-600 sm:hidden"
              onClick={() => handleSidebarToggle("Sidebar")}
            >
              <Hamburger className="w-full h-full" />
            </span>
          </div>
        </div>
      </div>
      {/* Navigation Links */}
      <div
        className="mt-3 py-2 flex items-center flex-wrap gap-1"
        style={{ borderTop: "2px solid hsl(0, 0%, 95%)" }}
      >
        <a
          href="/"
          className="flex justify-center items-center text-[14px] sm:text-[16px] px-2 py-1.5 text-gray-700  text-center hover:text-slate-50 hover:bg-gray-900"
          style={{ borderRadius: "5px" }}
        >
          بطاقة الهدية
        </a>
        <a
          href="/books"
          className="flex justify-center items-center text-[14px] sm:text-[16px] px-2 py-1.5 text-gray-700 hover:text-slate-50 hover:bg-gray-900"
          style={{ borderRadius: "5px" }}
        >
          الكتب
        </a>
        <a
          href="/accessories"
          className="flex justify-center items-center text-[14px] sm:text-[16px] px-2 py-1.5 text-gray-700 hover:text-slate-50 hover:bg-gray-900"
          style={{ borderRadius: "5px" }}
        >
          الإكسسوارات
        </a>
        <a
          href="/kids"
          className="flex justify-center items-center text-[14px] sm:text-[16px] px-2 py-1.5 text-gray-700 hover:text-slate-50 hover:bg-gray-900"
          style={{ borderRadius: "5px" }}
        >
          قسم الأطفال
        </a>
      </div>
    </nav>
  );
};

export default NavBar;
