import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "../../../../components/icons/icons";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import axios from "axios";
import "./sidebar.css";
import {
  IconStore,
  IconStoreSolid,
} from "../../../../assets/icons/CustomIcons";
import { useMemo, useState } from "react";
import useClickOutside from "../../../../Hooks/useClickOutside";
import DropdownMenu from "./DropdownMenu";

function SideContent({ className }) {
  const [sectionsMenu, setSectionsMenu] = useState(false);
  const [requestsMenu, setRequestsMenu] = useState(false);
  const [storeMenu, setStoreMenu] = useState(false);
  const [salesMenu, setSalesMenu] = useState(false);
  const navigate = useNavigate();
  const cookie = useMemo(() => new Cookies(), []);
  const token = cookie.get("Bearer");

  const handleNavigation = (path) => {
    navigate(path);
  };

  // const Ref = useClickOutside(setSectionsMenu(false), setSectionsMenu(true));

  const conExit = async () => {
    try {
      await axios.post(`http://127.0.0.1:8000/api/logout`, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      cookie.remove("Bearer", { path: "/" });
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const sidebarState = useSelector((state) => state.sidebar || {});
  const isVisible = sidebarState.isVisible || false;
  return (
    <div
      className={`side-bar relative ${className}`}
      style={{
        height: "calc(100% - 75px)",
      }}
    >
      <div
        className="item-bar flex gap-3"
        onClick={() => handleNavigation("/admin")}
      >
        <FontAwesomeIcon icon={icons.home} />
        <div>الصفحة الرئيسية</div>
      </div>
      <div
        className="item-bar flex gap-3"
        onClick={() => handleNavigation("/admin/statistics")}
      >
        <FontAwesomeIcon icon={icons.chartPie} />
        <div>الأحصائيات</div>
      </div>
      <div
        className="item-bar flex gap-3"
        onClick={() => handleNavigation("/admin/products")}
      >
        <FontAwesomeIcon icon={icons.faBox} />
        المنتجات
      </div>

      <DropdownMenu title="المبيعات" icon={icons.faBox}>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/authors/add")}
        >
          المبيعات
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/authors")}
        >
          الفواتير
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 0 0 3 3h15a3 3 0 0 1-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125ZM12 9.75a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H12Zm-.75-2.25a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75ZM6 12.75a.75.75 0 0 0 0 1.5h7.5a.75.75 0 0 0 0-1.5H6Zm-.75 3.75a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75ZM6 6.75a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-3A.75.75 0 0 0 9 6.75H6Z"
              clipRule="evenodd"
            />
            <path d="M18.75 6.75h1.875c.621 0 1.125.504 1.125 1.125V18a1.5 1.5 0 0 1-3 0V6.75Z" />
          </svg>
        </div>
      </DropdownMenu>
      <div
        className="item-bar flex gap-3"
        onClick={() => handleNavigation("/admin/poe")}
      >
        <FontAwesomeIcon icon={icons.cashRegister} />
        البيع المباشر
      </div>

      <DropdownMenu title="الأقسام" icon={icons.faPuzzlePiece}>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/authors/add")}
        >
          أضافة مؤلف
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/authors")}
        >
          المؤلفين
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/publisher")}
        >
          دور النشر
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/category")}
        >
          التصنيفات
        </div>
      </DropdownMenu>

      <div className="item-bar" onClick={() => handleNavigation("/admin/cart")}>
        <FontAwesomeIcon className="ml-3" icon={icons.BasketShopping} />
        السلة
      </div>

      <DropdownMenu title="الطلبات" icon={icons.BasketShopping}>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/requests")}
        >
          الكل
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/newOrders")}
        >
          طلبات جديدة
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/shipping")}
        >
          تم الشحن
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/cancelled")}
        >
          تم الغاء الطلب
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/delivered")}
        >
          تم الأستللام
        </div>
      </DropdownMenu>
      <DropdownMenu title="أدارة الخصومات" icon={icons.Tags}>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/discount/coupon")}
        >
          كوبون خصم
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/discount/products")}
        >
          خصم المنتجات
        </div>
      </DropdownMenu>

      <DropdownMenu
        title="المتجر"
        svgIcon={<IconStoreSolid className="w-6 h-6 ml-3" />}
      >
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/ads")}
        >
          أضافة اعلانات
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("/admin/ads")}
        >
          البنرات
        </div>
        <div
          className="item-bar"
          onClick={() => handleNavigation("stor/sliders")}
        >
          سلايدر
        </div>
      </DropdownMenu>

      <div
        className="item-bar"
        onClick={() => handleNavigation("/admin/users")}
      >
        <FontAwesomeIcon className="ml-3" icon={icons.users} />
        المستخدمين
      </div>
      <div
        className="item-bar"
        onClick={() => handleNavigation("/admin/director")}
      >
        <FontAwesomeIcon className="ml-3" icon={icons.userTie} />
        المدراء
      </div>
      <div
        className="item-bar"
        onClick={() => handleNavigation("/admin/setting")}
      >
        <FontAwesomeIcon className="ml-3" icon={icons.seating} />
        إعدادات الموقع
      </div>
      <div className="item-bar" onClick={() => handleNavigation("/admin/test")}>
        <FontAwesomeIcon className="ml-3" icon={icons.seating} />
        تجربة
      </div>
      <div className="item-exit" onClick={conExit}>
        <FontAwesomeIcon className="ml-3" icon={icons.Exit} />
        تسجيل الخروج
      </div>
    </div>
  );
}

export default SideContent;
