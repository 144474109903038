import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ActionButton from "../button/actionButton";
import PropTypes from "prop-types";

const DynamicFormPopup = ({
  open,
  onClose,
  title,
  fields,
  onSubmit,
  loading,
  initialFormState,
}) => {
  const [form, setForm] = useState(initialFormState || {});
  const [accept, setAccept] = useState(false);
  const animatedComponents = makeAnimated();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAccept(true);

    const isValid = fields.every((field) => {
      if (field.required) {
        return form[field.name]?.trim() !== "";
      }
      return true;
    });
    if (isValid) {
      onSubmit(form);
    }
  };

  return (
    <div>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div
            className="bg-white p-6 rounded-lg shadow-lg relative"
            style={{ borderRadius: "5px", width: "400px" }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg">{title}</h2>
              <button
                className="h-6 w-6 p-1 text-gray-500 hover:text-gray-200 hover:bg-gray-700 text-center"
                style={{ borderRadius: "5px", opacity: "0.5" }}
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-full w-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              {fields.map((field) => (
                <div className="mb-3" key={field.name}>
                  <label htmlFor={field.name}>{field.label}</label>
                  {field.type === "select" ? (
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      placeholder={`حدد ${field.label}`}
                      isMulti={field.isMulti || false}
                      styles={field.customStyles || {}}
                      options={field.options}
                      onChange={(selectedOption) =>
                        handleSelectChange(field.name, selectedOption)
                      }
                    />
                  ) : (
                    <input
                      type={field.type}
                      className="mt-2 w-full p-2 border rounded"
                      name={field.name}
                      id={field.name}
                      value={form[field.name] || ""}
                      onChange={handleChange}
                      disabled={field.disabled}
                    />
                  )}
                  {field.required &&
                    form[field.name]?.trim() === "" &&
                    accept && (
                      <span className="text-red-600 error-message mx-2 mt-1">
                        هذا الحقل مطلوب
                      </span>
                    )}
                </div>
              ))}
              <ActionButton
                className="mt-5 w-full"
                style={{ backgroundColor: "#18bc9c" }}
                text="أضافة"
                type="submit"
                loading={loading}
              />
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

DynamicFormPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      options: PropTypes.arrayOf(PropTypes.object), // للحقول من نوع select
      isMulti: PropTypes.bool,
      customStyles: PropTypes.object,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  initialFormState: PropTypes.object,
};

export default DynamicFormPopup;
