import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../components/common/Spinner/Spinner";
import ActionButton from "../../../../components/common/button/actionButton";
import "./index.css";

const CustomTable = ({
  columns,
  data,
  onDelete,
  onEdit,
  loading,
  Notfound,
  element,
  showExtraButton = false,
  showEditButton = true,
  showDeleteButton = true,
  control = true,
  extraButtonUrl,
  controlElement,
  classExtraButton = "",
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();

  const handleRowSelect = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const isRowSelected = (rowId) => {
    return selectedRows.includes(rowId);
  };

  const handleExtraButtonClick = (rowId) => {
    navigate(`${extraButtonUrl}/${rowId}`);
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <table
        className="min-w-full border"
      >
        <thead
          className="border bg-[#f9f9f9]">
          <tr>
            <th>#</th>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
            {control && (<th style={{ maxWidth: "200px" }}>التحكم</th>)}
            <th style={{ maxWidth: "70px" }}>
              <input
                type="checkbox"
                checked={selectedRows.length === data.length}
                onChange={(e) =>
                  setSelectedRows(
                    e.target.checked ? data.map((row) => row.id) : []
                  )
                }
                className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length < 1 && !loading ? (
            <tr>
              <td colSpan={columns.length + 3}>
                <div className="flex items-center justify-center">
                  {Notfound && Notfound}
                </div>
              </td>
            </tr>
          ) : null}
          {loading ? (
            <tr>
              <td colSpan={columns.length + 3}>
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              </td>
            </tr>
          ) : (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{rowIndex + 1}</td>
                {Array.isArray(row.info) &&
                  row.info.map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      {typeof cell === "string" && cell.startsWith("http") ? (
                        <img
                          src={cell}
                          alt={`${rowIndex}-${cellIndex}`}
                          style={{ width: "auto", height: "50px" }}
                        />
                      ) : (
                        cell || "-"
                      )}
                    </td>
                  ))}
                  {element && (<td style={{ minWidth: "150px", maxWidth: "200px" }}>{element}</td>)}
                {control && (
                  <td style={{ minWidth: "150px", maxWidth: "200px" }}>
                    <div className="flex gap-2 w-full items-stretch justify-center ">
                      {showExtraButton && (
                        <ActionButton
                          text="تفاصيل"
                          className={classExtraButton}
                          style={{
                            backgroundColor: "#18bc9c",
                            border: "1px solid #18bc9c",
                          }}
                          onClick={() => handleExtraButtonClick(row.id)}
                        />
                      )}
                      {showEditButton && (
                        <ActionButton
                          text="تعديل"
                          style={{ backgroundColor: "#95a5a6", width: "90px" }}
                          svgIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="size-6"
                            >
                              <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                              <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                            </svg>
                          }
                          onClick={() => onEdit(row.id)}
                        />
                      )}
                      {showDeleteButton && (
                        <ActionButton
                          text="حذف"
                          style={{ width: "90px" }}
                          svgIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          }
                          onClick={() => onDelete(row.id)}
                        />
                      )}
                    </div>
                  </td>
                )}
                <td style={{ minWidth: "60px" }}>
                  <input
                    type="checkbox"
                    checked={isRowSelected(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                    className="rounded border-gray-300 text-indigo-600"
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  Notfound: PropTypes.string,
  showDeleteButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  showExtraButton: PropTypes.bool,
  control: PropTypes.bool,
  controlElement: PropTypes.element,
  extraButtonUrl: PropTypes.string,
  classExtraButton: PropTypes.string,
  element: PropTypes.element,
};

CustomTable.defaultProps = {
  onEdit: undefined,
  Notfound : "لا توجد بيانات"
 }

export default CustomTable;
