import React ,{ useState } from "react";
import useClickOutside from "../../Hooks/useClickOutside";
import SidebarComponent from "./SidebarComponent";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../lib/redux/slices/sidebarSlice";

import images from "./../../assets/images/2013-12-09_00292.jpg";
import SidebarContent from "./SidebarContent";

const Sidebar = ({ setOpen, open }) => {
  const SidebarRef = useClickOutside(setOpen, open);
  const dispatch = useDispatch();
  const handleSidebarToggle = (id) => {
    dispatch(toggleSidebar({ id }));
  };
  
  return (
    <SidebarComponent id="Sidebar">
      <div
        ref={SidebarRef}
        style={{ borderLeft: "1px solid var(--gray-border)" }}
      >
        <div
          className="py-4"
          style={{ borderBottom: "1px solid hsl(0, 0%, 90%)" }}
        >
          <div className="flex pr-4 pl-2">
            <span className="h-10 w-10 mt-1.5 overflow-hidden outline outline-offset-2 outline-gray-200 outline-2 rounded-full">
              <img src={images} className="h-full w-full object-cover" alt="" />
              {/* <Avatar className="rounded-full w-full h-full" /> */}
            </span>
            <div className="text-right mr-4">
              <div className="text-xl font-bold" title="Haider">
                Haider Abd Ali
              </div>
              <div
                className="text-sm text-gray-400"
                title="albsraryhydr@gmail.com"
              >
                albsraryhydr@gmail.com
              </div>
            </div>
          </div>
        </div>
        <SidebarContent /> 
      </div>
    </SidebarComponent>
  );
};

export default Sidebar;
