import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance";
import { toast } from "sonner";

// تعريف الحالة الابتدائية
const initialState = {
  cart: {
    id: null,
    items: [],
    total_amount: 0,
    user: null,
    created_at: null,
    updated_at: null,
  },
  totalProducts: 0,
  isOpen: false,
  loading: false,
  error: null,
  status: "idle",
  statusCode: null,
};

// جلب عناصر العربة من API
export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get("/api/cart");
      return response.data;
    } catch (err) {
      // تعيين statusCode هنا
      const statusCode = err.response?.status || 500;
      console.log(statusCode);
      return thunkAPI.rejectWithValue({ data: err.response.data, statusCode });
    }
  }
);

// جلب جميع العربات من API
export const fetchCarts = createAsyncThunk("cart/fetchCarts", async () => {
  const response = await axiosInstance.get("/admin/carts");
  return response.data;
});

// جلب العربة بواسطة ID من API
export const fetchCartById = createAsyncThunk(
  "cart/fetchCartById",
  async (cartId) => {
    const response = await axiosInstance.get(`/admin/getCartById/${cartId}`);
    return response.data;
  }
);

// إضافة منتج إلى العربة بواسطة slug
export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async ({ slug, quantity }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(`/cart/add/${slug}`, {
        quantity: quantity,
      });
      toast.success("تمت الإضافة بنجاح");
      dispatch(fetchTotalProducts());
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("يرجى تسجيل الدخول أولاً لإضافة المنتج إلى السلة.");
      } else {
        toast.error(
          "حدث خطأ أثناء الإضافة، يرجى المحاولة مرة أخرى. في حال تكرر الخطأ، تواصل مع الدعم الفني."
        );
      }
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// إزالة منتج من العربة
export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (slug, { dispatch, rejectWithValue }) => {
    const response = await axiosInstance.delete(`/cart/remove/${slug}`);
    dispatch(fetchTotalProducts());
    return response.data;
  }
);

// جلب إجمالي المنتجات من API
export const fetchTotalProducts = createAsyncThunk(
  "cart/fetchTotalProducts",
  async () => {
    const response = await axiosInstance.get("/cart/getTotalProducts");
    return response.data;
  }
);

export const showCart = createAsyncThunk(
  "cart/showCart",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/cart/show");
      return response.data;
    } catch (err) {
      const statusCode = err.response?.status || 500;
      console.log(err);
      return thunkAPI.rejectWithValue({
        data: err.response?.data || "Something went wrong",
        statusCode,
      });
    }
  }
);

export const updateQuantity = createAsyncThunk(
  "cart/updateQuantity",
  async ({ itemId, quantity, type }, { dispatch, rejectWithValue }) => {
    const response = await axiosInstance.post(
      `/cart/updateQuantity/${itemId}`,
      {
        quantity,
        type,
      }
    );
    dispatch(fetchTotalProducts());
    return response.data;
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    openCart: (state) => {
      state.isOpen = true;
    },
    closeCart: (state) => {
      state.isOpen = false;
    },
    toggleCart: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.cart = action.payload;
        state.status = "succeeded";
        state.statusCode = 200;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data || action.error.message;
        state.status = "failed";
        state.statusCode = action.payload?.statusCode || 500;
    })    
      .addCase(fetchCarts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(fetchCarts.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
        state.statusCode = 200;
      })
      .addCase(fetchCarts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = "failed";
        state.statusCode = action.error?.response?.status || 500;
      })
      .addCase(fetchCartById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(fetchCartById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.cart = action.payload.cart;
        state.status = "succeeded";
        state.statusCode = 200;
      })
      .addCase(fetchCartById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch cart";
        state.status = "failed";
        state.statusCode = action.error?.response?.status || 500;
      })
      .addCase(addToCart.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        const newItem = action.payload.cartItem;
        if (state.cart && Array.isArray(state.cart.items)) {
          const existingItem = state.cart.items.find(
            (item) => item.id === newItem.id
          );
          if (existingItem) {
            existingItem.quantity += newItem.quantity;
          } else {
            state.cart.items.push(newItem);
          }
        } else {
          state.cart.items = [newItem];
        }
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
        state.statusCode = 200;
      })
      .addCase(addToCart.rejected, (state, action) => {
        console.log(state.statusCode);
        state.loading = false;
        state.error = action.error.message;
        state.status = "failed";
        state.statusCode = action.error?.response?.status || 500;
      })
      .addCase(removeFromCart.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (state.cart && state.cart.items) {
          state.cart.items = state.cart.items.filter(
            (item) => item.product.slug !== action.payload.slug
          );
        }
        state.loading = false;
        state.statusCode = 200;
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
        state.statusCode = action.error?.response?.status || 500;
      })
      .addCase(fetchTotalProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(fetchTotalProducts.fulfilled, (state, action) => {
        state.totalProducts = action.payload.total_products;
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
        state.statusCode = 200;
      })
      .addCase(fetchTotalProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = "failed";
        state.statusCode = action.error?.response?.status || 500;
      })
      .addCase(showCart.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(showCart.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.cart = action.payload;
        state.status = "succeeded";
        state.statusCode = 200;
      })
      .addCase(showCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data || action.error.message;
        state.status = "failed";
        state.statusCode = action.payload?.statusCode || 500;
      })
      .addCase(updateQuantity.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(updateQuantity.fulfilled, (state, action) => {
        const { itemId, quantity } = action.payload;
        if (state.cart && Array.isArray(state.cart.items)) {
          const item = state.cart.items.find((item) => item.id === itemId);
          if (item) {
            item.quantity = quantity;
          }
        }
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
        state.statusCode = 200;
      })
      .addCase(updateQuantity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = "failed";
        state.statusCode = action.error?.response?.status || 500;
      });
  },
});

// تصدير الأكشن والـ reducer
export const { openCart, closeCart, toggleCart } = cartSlice.actions;
export default cartSlice.reducer;
