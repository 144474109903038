import React from "react";
import { Avatar } from "../../../../assets/icons/CustomIcons";

const ShowCard = ({ data , onClick }) => {
  return (
    <div onClick={onClick} className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
      {/* صورة المؤلف */}
      <div className="relative w-full aspect-square">
        {data.image ? (
          <img
            className="w-full h-full object-cover"
            src={data.image}
            alt={data.name}
          />
        ) : (
          <Avatar className="w-full h-full" classSvg="-bottom-6" />
        )}
      </div>
      {/* معلومات المؤلف */}
      <div className="p-4">
        <h2 className="text-lg font-bold mb-2 truncate">{data.name}</h2>
        <div className="flex items-center">
          <svg
            className="w-4 h-4 text-yellow-500 ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.1 3.374a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.1 3.374c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.84-.197-1.54-1.118l1.1-3.374a1 1 0 00-.364-1.118L2.61 8.8c-.783-.57-.38-1.81.588-1.81h3.462a1 1 0 00.95-.69l1.1-3.374z" />
          </svg>
          <span className="text-[#111827]">{data.rating}4.9</span>
        </div>
      </div>
    </div>
  );
};

export default ShowCard;
