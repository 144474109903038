import React from "react";
import SidebarComponent from "./SidebarComponent";
import { toggleSidebar } from "../../lib/redux/slices/sidebarSlice";
import { useDispatch } from "react-redux";
import CloseButton from "../common/button/CloseButton";
import CustomItems from "./CustomItems";

const NotificationsSidebar = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const handleSidebarToggle = (id) => {
    dispatch(toggleSidebar({ id }));
  };

  return (
    <SidebarComponent id="NotificationsSidebar">
      <div
        className="bg-white py-5 px-4 flex justify-between items-center gap-4 shadow-md rounded-t-md"
        style={{ borderBottom: "1px solid hsl(0, 0%, 90%)" }}
      >
        <h2 className="text-lg font-semibold text-gray-800">الإشعارات</h2>
        <CloseButton
          onClick={() => handleSidebarToggle("NotificationsSidebar")}
        />
      </div>
      <div className="flex-1 overflow-y-auto space-y-2 py-4 bg-[var(--bg)]">
        {/* قائمة الإشعارات */}
        <CustomItems
          className="px-4 py-3 border-b transition duration-300 ease-in-out hover:bg-gray-100"
          classSVG="p-2 border w-max h-max flex justify-between items-center rounded-full bg-gradient-to-r from-green-400 to-green-600 text-white shadow-lg"
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
              />
            </svg>
          }
          text="كتابك المفضل توفر من جديد, احجز نسختك الآن!"
        />
        <CustomItems
          className="px-4 py-3 border-b transition duration-300 ease-in-out hover:bg-gray-100"
          classSVG="p-2 border w-max h-max flex justify-between items-center rounded-full bg-gradient-to-r from-orange-400 to-orange-600 text-white shadow-lg"
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
              />
            </svg>
          }
          text="احصل على خصم 20% على كتب مختارة، انتهز الفرصة الآن!"
        />
        <CustomItems
          className="px-4 py-3 border-b transition duration-300 ease-in-out hover:bg-gray-100"
          classSVG="p-2 border w-max h-max flex justify-between items-center rounded-full bg-gradient-to-r from-blue-400 to-blue-600 text-white shadow-lg"
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
            >
              <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z" />
              <path d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z" />
              <path d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
            </svg>
          }
          text="تمت إضافة كتب جديدة لمؤلفك المفضل. تحقق منها الآن!"
        />
        <CustomItems
          className="px-4 py-3 border-b transition duration-300 ease-in-out hover:bg-gray-100"
          classSVG="p-2 border w-max h-max flex justify-between items-center rounded-full bg-gradient-to-r from-blue-400 to-blue-600 text-white shadow-lg"
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
            >
              <path d="M5.85 3.5a.75.75 0 0 0-1.117-1 9.719 9.719 0 0 0-2.348 4.876.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5ZM19.267 2.5a.75.75 0 1 0-1.118 1 8.22 8.22 0 0 1 1.987 4.124.75.75 0 0 0 1.48-.248A9.72 9.72 0 0 0 19.266 2.5Z" />
              <path
                fillRule="evenodd"
                d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z"
                clipRule="evenodd"
              />
            </svg>
          }
          text="تمت إضافة كتب جديدة لمؤلفك المفضل. تحقق منها الآن!"
        />
      </div>
    </SidebarComponent>
  );
};

export default NotificationsSidebar;
