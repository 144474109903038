// discountDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  title: "",
  category_id: "",
};

const sliderDataSlice = createSlice({
  name: "sliderData",
  initialState,
  reducers: {
    updateDiscountData(state, action) {
      return { ...state, ...action.payload };
    },
    resetDiscountData() {
      return initialState;
    },
  },
});

export const { updateSliderData, resetSliderData } = sliderDataSlice.actions;
export default sliderDataSlice.reducer;
