import React from "react";
import ProductSlider from "../../components/common/sliders/ProductSlider";

const SliderList = ({ sliders }) => {
  return (
    <>
      {sliders.length > 0 &&
        sliders.map(
          (slider) =>
            slider.category.products &&
            slider.category.products.length > 0 && (
              <ProductSlider
                key={slider.id}
                id={slider.id}
                label={slider.title}
                products={slider.category.products}
              />
            )
        )}
    </>
  );
};

export default SliderList;
