import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../../../lib/redux/slices/sidebarSlice";
import { Bars } from "../../../../assets/icons/CustomIcons";
const Headers = () => {
  const dispatch = useDispatch();

  const handleSidbar = (id) => {
    dispatch(toggleSidebar({ id }));
  };

  return (
    <div className="header z-[800]">
      <div className="flex">
        <button className="lg:hidden" onClick={() => handleSidbar("ControlPanelSide")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <h2>لوحة التحكم</h2>
      </div>
      <div className="header-user">
        <p>@5g.ur</p>
        <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
          <svg
            className="absolute w-12 h-12 text-gray-400 -left-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};
export default Headers;
