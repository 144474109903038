import React from "react";
const PercentageIndicator = ({ percentage }) => {
  const percentageString = String(percentage);
  const isPositive = !percentageString.includes("-"); // تحقق مما إذا كانت تحتوي على "-"
  const cleanPercentage = percentageString.replace("-", ""); // إزالة "-" إذا كانت موجودة

  return (
    <span
      className={`flex gap-1 items-center ${
        isPositive ? "text-green-500 bg-green-100" : "text-red-500 bg-red-100"
      } py-0.5 px-2 rounded-full`}
    >
      <p style={{fontSize:"0.9rem"}}>{cleanPercentage}%</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-[1rem]"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d={
            isPositive
              ? "M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" // أيقونة الاتجاه للأسفل
              : "M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181" // أيقونة الاتجاه للأعلى
          }
        />
      </svg>
    </span>
  );
};

export default PercentageIndicator;
