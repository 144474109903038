import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import LOGO from "../../assets/logo/logoOnW.png";
import { User } from "../../context/UserContext";
import ActionButton from "../../components/common/button/actionButton";
import "../../style/login.css";
import { IconGoogle } from "../../assets/icons/CustomIcons";
import { login } from "../../lib/redux/slices/authSlice";

const Login = () => {
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [accept, setAccept] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const UserNow = useContext(User);
  const cookie = new Cookies();

  const register = () => {
    navigate("/register");
  };
  const LOGIN_URL = "/login";

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(form));
  };

  async function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await dispatch(login(form));
      const expiresDate = new Date();
      expiresDate.setFullYear(expiresDate.getFullYear() + 1);

      if (res.status === 200) {
        const token = res.data.token;
        const userDetails = res.data.user;

        if (token) {
          UserNow.setAuth({ token, userDetails });
          cookie.set("Bearer", token, {
            // httpOnly: true,
            path: "/",
            expires: expiresDate,
            sameSite: "Strict",
          });
          navigate("/admin");
        } else {
          console.error("لم يتم العثور على التوكن في الاستجابة");
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setLoginError(error.response.data.message);
        }
      } else {
        console.log("حدث خطأ:", error.message);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });

    if (form.password.length < 7) {
      setInvalidPassword(true);
    } else {
      setInvalidPassword(false);
    }
  };

  return (
    <div className="login-form-cont">
      <form
        className="form sm:w-full shadow"
        method="post"
        onSubmit={handleLogin}
      >
        <div className="img-logo">
          <img src={LOGO} alt="Logo" />
        </div>
        <div>
          <label>أسم المستخدم</label>
          <input
            type="email"
            className="input-box"
            name="email"
            id="email"
            value={form.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <span>كلمة المرور</span>
          <input
            type="password"
            className={`input-box ${invalidPassword ? "border-red-500" : ""}`}
            name="password"
            value={form.password}
            onChange={handleChange}
            id="remeber-me"
          />
          {invalidPassword && accept && (
            <p className="text-red-500">
              كلمة المرور يجب أن تكون على الأقل 8 أحرف
            </p>
          )}
        </div>
        {loginError && (
          <span className="text-red-500 mt-3 text-center mr-3">
            {loginError}
          </span>
        )}
        <ActionButton
          className={`w-full my-4 mb-[30px] bg-[var(--primary)]`}
          text="تسجيل"
          type="submit"
          loading={loading}
        />
        <span className="flex items-center justify-center gap-4 my-2">
          <span
            className="flex-1"
            style={{ borderTop: "1px solid #ddd" }}
          ></span>
          او سجل دخول بواسطة
          <span
            className="flex-1"
            style={{ borderTop: "1px solid hsl(0, 0%, 90%)" }}
          ></span>
        </span>

        <ActionButton
          className="w-full mt-6 flex gap-2 items-center justify-center bg-gray-100 text-gray-800 hover:bg-gray-20 font-medium px-5 py-2.5 "
          text="تسجيل الدخول بواسطة Google"
          svgIcon={<IconGoogle />}
        />
      </form>
      <p className="mt-6 cursor-pointer w-full text-center" onClick={register}>
        انشاء حساب صديق جديد
      </p>
    </div>
  );
};

export default Login;
