import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setClosedSections,
  setSiteStatus,
  setSiteClosedMessage,
} from "../../../../lib/redux/slices/settingsSlice";

const AccessControl = () => {
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const handleInputChange = (setter, value) => {
    dispatch(setter(value));
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-3">إدارة الوصول</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          حالة الموقع
        </label>
        <select
          value={settings.siteStatus}
          onChange={(e) => handleInputChange(setSiteStatus, e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
        >
          <option value="online">مفتوح</option>
          <option value="maintenance">مغلق للصيانة</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          نص الرسالة عند إغلاق الموقع
        </label>
        <textarea
          value={settings.siteClosedMessage}
          onChange={(e) =>
            handleInputChange(setSiteClosedMessage, e.target.value)
          }
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          placeholder="أدخل النص الذي يظهر عند إغلاق الموقع"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          أقسام الموقع المغلقة
        </label>
        <div className="flex flex-col gap-3 mt-4">
          {Object.keys(settings.closedSections).map((section) => (
            <label
              key={section}
              className="inline-flex items-center cursor-pointer"
            >
              <input
                type="checkbox"
                checked={settings.closedSections[section]} // تأكد من أن قيمة `checked` تأخذ الحالة الصحيحة
                onChange={() =>
                  handleInputChange(setClosedSections, {
                    ...settings.closedSections,
                    [section]: !settings.closedSections[section],
                  })
                }
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--sec)]" />
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                {section}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccessControl;
