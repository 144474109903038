import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination } from "swiper";
import { Autoplay, Pagination } from 'swiper/modules';
import Slider1 from "../../assets/images/Slider1.png";
import Slider2 from "../../assets/images/Slider2.png";
import Slider3 from "../../assets/images/Slider3.png";
// import Slider4 from '../../assets/images/Slider4.png';
// import Slider5 from '../../assets/images/Slider5.png';

const SlickCarousel = () => {
  const sliders = [
    { image: Slider1, alt: "Slider 1" },
    { image: Slider2, alt: "Slider 2" },
    { image: Slider3, alt: "Slider 3" },
    // { image: Slider4, alt: 'Slider 4'},
    // { image: Slider5, alt: 'Slider 5'}
  ];

  return (
    <Swiper
      className="w-full"
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      pagination={{ clickable: true }}
      modules={[Autoplay, Pagination]}
    >
      {sliders.map((slider, index) => (
        <SwiperSlide className="flex items-center justify-center" key={index}>
          <div className="relative">
            <img
              src={slider.image}
              alt={slider.alt}
              className="w-full h-auto"
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SlickCarousel;
