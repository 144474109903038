import React from "react";
import TextInput from "../../../components/Input/TextInput";
import InputFields from "./inputFields";
import { useSelector } from "react-redux";

const FormInputs = ({ handleChange, handleSelectChange }) => {
  const productData = useSelector((state) => state.productData);
  const inputFields = InputFields();
  return (
    <>
      {inputFields.map((field, index) => (
        <TextInput
          key={index}
          name={field.name}
          label={field.label}
          tooltipContent={field.tooltipContent}
          placeholder={field.placeholder}
          type={field.type}
          options={field.options}
          value={productData[field.name] || ""}
          date={field.date}
          setDate={field.setDate}
          onChange={(e) =>
            field.type === "select"
              ? handleSelectChange(field.name, e)
              : handleChange(e)
          }
          onBlur={field.onBlur}
          validateInput={field.validateInput}
        />
      ))}
    </>
  );
};

export default FormInputs;
