import React, { useState, useEffect } from "react";
import CustomTable from "../../components/Table/CustomTable";
import DeletePopup from "../../../../components/common/Popup modal/deletePopup";
import { Toaster, toast } from "sonner";
import Pagination from "../../../../components/common/pagination/pagination";
import ModalForm from "../../../../components/common/Popup modal/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import {
  newPublisher,
  getPublisher,
} from "../../../../lib/redux/slices/publisherSlice";
import Cookies from "universal-cookie";
import ActionButton from "../../../../components/common/button/actionButton";

function Publisher() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { publishers, status, error } = useSelector(
    (state) => state.publishers
  );


  const [form, setForm] = useState({
    name: "",
    slug: "",
  });

  useEffect(() => {
    if (status === "idle") {
      dispatch(getPublisher());
    }
  }, [status, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!form.name) {
      toast.error("جميع الحقول مطلوبة.");
      return;
    }
  
    setLoading(true);
  
    try {
      await dispatch(newPublisher(form));
      toast.success("تمت إضافة دار النشر بنجاح.");
      
      // إعادة جلب قائمة الناشرين لتحديثها
      await dispatch(getPublisher());
      
      // إعادة تعيين الحقول إلى القيم الافتراضية
      setForm({
        name: "",
        slug: "",
      });
  
      // إغلاق النموذج
      setOpenModal(false);
    } catch (error) {
      toast.error("حدث خطأ أثناء إضافة دار النشر.");
      console.error("حدث خطأ أثناء إضافة دار النشر:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleDelete = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);

  };

  const confirmDelete = async () => {
    // حذف الناشر (يجب تنفيذ منطق الحذف هنا)
    setShowModal(false);
  };

  const onPageChange = (page) => setCurrentPage(page);
  const [showModal, setShowModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const itemsPerPage = 10;
  const columns = ["الصورة", "الأسم", "عدد المؤلفات"];
  const cookies = new Cookies();
  const token = cookies.get("Bearer");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = publishers
    .slice(indexOfFirstItem, indexOfLastItem)
    .map((list) => ({
      id: list.id,
      info: [
        list.image || "-",
        list.name || "-",
        list.products_count || "-",
      ],
    }));

  const fields = [
    { name: "name", label: "الأسم", type: "text", required: true },
    {
      label: "الرابط",
      name: "slug",
      type: "text",
      required: true,
      disabled: form.name === "",
    },
  ];

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>دور النشر</h2>
        <button className="btn-style" onClick={() => setOpenModal(true)}>
          إضافة دار نشر جديد
        </button>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">إضافة دار نشر</div>
      </div>

      <CustomTable
        columns={columns}
        data={currentData}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={loading}
      />
      <DeletePopup
        show={showModal}
        setOpenModal={setShowModal}
        onConfirm={confirmDelete}
      />
      {loading ? null : (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(publishers.length / itemsPerPage)}
          onPageChange={onPageChange}
          previousLabel="السابق"
          nextLabel="التالي"
          showIcon
        />
      )}
      <Toaster
        richColors
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
            textAlign: "right",
          },
          className: "my-toast",
        }}
      />
      <ModalForm
        open={openModal}
        onClose={() => setOpenModal(false)}
        formTitle="إضافة دار نشر جديد"
        form={form}
        setForm={setForm}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        fields={fields}
        loading={loading}
      />
    </div>
  );
}

export default Publisher;
