import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openSidebars: {}
};

const sidebarSlice = createSlice({
  name: 'sidebars',
  initialState,
  reducers: {
    toggleSidebar(state, action) {
      const { id } = action.payload;
      
      for (const key in state.openSidebars) {
        if (key !== id) {
          state.openSidebars[key] = false;
        }
      }
      
      state.openSidebars[id] = !state.openSidebars[id];
    }
  }
});

export const { toggleSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;

