import React from "react";
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  previousLabel = 'Previous',
  nextLabel = 'Next',
  showIcon = true,
}) => {
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const goToPage = (page) => {
    onPageChange(page);
  };

  return (
    <nav className="flex justify-center mt-4">
      <ul className="flex rounded-md overflow-hidden" style={{ border: '1px solid hsl(0, 0%, 90%)' }}>
        <li>
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className={`px-3 py-2 ${ 
              currentPage === 1
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
            style={{ borderRight: '1px solid hsl(0, 0%, 90%)' }}
          >
            {showIcon && <HiChevronRight className="inline mr-1" />}
            {previousLabel}
          </button>
        </li>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <li key={page}>
            <button
              onClick={() => goToPage(page)}
              className={`px-3 py-2 ${
                page === currentPage
                  ? 'bg-blue-100 text-blue-600'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
              style={{ borderRight: page < totalPages ? '1px solid hsl(0, 0%, 90%)' : 'none' }}
            >
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className={`px-3 py-2 ${
              currentPage === totalPages
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            {nextLabel}
            {showIcon && <HiChevronLeft className="inline ml-1" />}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;