import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../components/select/customSelect";
import { toast } from "sonner";
import {
  newPublisher,
  getPublisher,
  clearPublisherError,
} from "../../../../../lib/redux/slices/publisherSlice";
import {
  getAuthors,
  newAuthor,
  clearError as clearAuthorError,
} from "../../../../../lib/redux/slices/authorSlice";
import {
  getCategories,
  addCategory,
  clearCategoryError,
} from "../../../../../lib/redux/slices/categorySlice";
import { updateProductData } from "../../../../../lib/redux/slices/productDataSlice";

const FromCustomSelect = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const {
    categories,
    error: categoriesError,
    status: categoriesStatus,
  } = useSelector((state) => state.category);
  const {
    authors,
    error: authorsError,
    status: authorsStatus,
  } = useSelector((state) => state.authors);
  const {
    publishers,
    error: publishersError,
    status: publishersStatus,
  } = useSelector((state) => state.publishers);
  const productData = useSelector((state) => state.productData || {});

  useEffect(() => {
    setLoading(true);
    dispatch(getCategories());
    dispatch(getAuthors());
    dispatch(getPublisher());
  }, [dispatch]);

  useEffect(() => {
    if (
      categoriesStatus === "succeeded" &&
      authorsStatus === "succeeded" &&
      publishersStatus === "succeeded"
    ) {
      setLoading(false);
    }
  }, [categoriesStatus, authorsStatus, publishersStatus]);


  const handleSelectChange = (name, selectedOptions) => {
    const ids = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : selectedOptions
      ? [selectedOptions.value]
      : [];
    dispatch(updateProductData({ [name]: ids }));
  };

  const selectProps = {
    fetchAction: {
      authors: getAuthors,
      publishers: getPublisher,
      category: getCategories,
    },
    addAction: {
      authors: newAuthor,
      publishers: newPublisher,
      category: addCategory,
    },
    clearErrorAction: {
      authors: clearAuthorError,
      publishers: clearPublisherError,
      category: clearCategoryError,
    },
    entitySelector: {
      authors: (state) => state.authors,
      publishers: (state) => state.publishers,
      category: (state) => state.category,
    },
  };

  const getOptions = (entities) =>
    entities.map((entity) => ({
      label: entity.name,
      value: entity.id,
    }));

  const formatSelectedOptions = (selectedValues, options) => {
    return selectedValues.map((value) => ({
      label: options.find((option) => option.value === value)?.label || "",
      value: value,
    }));
  };

  const isProductType = productData.type === "Products";

  return (
    <>
      {!isProductType && (
        <>
          <CustomSelect
            isLoading={loading}
            fetchAction={selectProps.fetchAction.authors}
            addAction={selectProps.addAction.authors}
            clearErrorAction={selectProps.clearErrorAction.authors || (() => {})}
            entitySelector={(state) =>
              selectProps.entitySelector.authors(state)
            }
            entities={authors}
            fields={[
              { name: "name", label: "الأسم", type: "text", required: true },
              { name: "slug", label: "الرابط", type: "text", required: true },
              {
                name: "type",
                label: "نوع المؤلف",
                type: "select",
                options: [
                  { label: "كاتب", value: "كاتب" },
                  { label: "روائي", value: "روائي" },
                  { label: "شاعر", value: "شاعر" },
                  { label: "مترجم", value: "مترجم" },
                ],
                required: true,
              },
            ]}
            formTitle="أضافة مؤلف جديد"
            placeholder="اختر المؤلف"
            selectedOptions={
              productData.authors.length > 0
                ? formatSelectedOptions(
                    productData.authors,
                    getOptions(authors)
                  )
                : []
            }
            onChange={(selectedOption) =>
              handleSelectChange("authors", selectedOption)
            }
          />

          <CustomSelect
            isLoading={loading}
            fetchAction={selectProps.fetchAction.publishers}
            addAction={selectProps.addAction.publishers}
            clearErrorAction={selectProps.clearErrorAction.publishers || (() => {})}
            entitySelector={(state) =>
              selectProps.entitySelector.publishers(state)
            }
            entities={publishers}
            fields={[
              { name: "name", label: "الأسم", type: "text", required: true },
              { name: "slug", label: "الرابط", type: "text", required: true },
            ]}
            formTitle="أضافة ناشر جديد"
            placeholder="اختر الناشر"
            selectedOptions={
              productData.publishers.length > 0
                ? formatSelectedOptions(
                    productData.publishers,
                    getOptions(publishers)
                  )
                : []
            }
            onChange={(selectedOption) =>
              handleSelectChange("publishers", selectedOption)
            }
          />
        </>
      )}
      <CustomSelect
        isLoading={loading}
        fetchAction={selectProps.fetchAction.category}
        addAction={selectProps.addAction.category}
        clearErrorAction={selectProps.clearErrorAction.category || (() => {})}
        entitySelector={(state) => selectProps.entitySelector.category(state)}
        entities={categories}
        fields={[
          { name: "name", label: "الأسم", type: "text", required: true },
          { name: "slug", label: "الرابط", type: "text", required: true },
        ]}
        formTitle="أضافة تصنيف جديدة"
        placeholder="اختر التصنيف"
        selectedOptions={
          productData.category.length > 0
            ? formatSelectedOptions(
                productData.category,
                getOptions(categories)
              )
            : []
        }
        onChange={(selectedOption) =>
          handleSelectChange("category", selectedOption)
        }
      />
    </>
  );
};

export default FromCustomSelect;
