export const Countries = [
  { id: 1, name: "العراق" },
  { id: 2, name: "الإمارات العربية المتحدة" },
  { id: 3, name: "مصر" },
  { id: 4, name: "الأردن" },
  { id: 5, name: "السعودية" },
  { id: 6, name: "لبنان" },
  { id: 7, name: "فلسطين" },
  { id: 8, name: "سوريا" },
  { id: 9, name: "عُمان" },
  { id: 10, name: "الكويت" },
  { id: 11, name: "البحرين" },
  { id: 12, name: "قطر" },
  { id: 13, name: "اليمن" },
  { id: 14, name: "السودان" },
  { id: 15, name: "المغرب" },
  { id: 16, name: "الجزائر" },
  { id: 17, name: "تونس" },
  { id: 18, name: "ليبيا" },
  { id: 19, name: "موريتانيا" },
];

export const Provinces = {
  1: [
    // العراق
    { id: 1, name: "البصرة" },
    { id: 2, name: "بغداد" },
    { id: 3, name: "النجف" },
    { id: 4, name: "كربلاء" },
    { id: 5, name: "السليمانية" },
    { id: 6, name: "اربيل" },
    { id: 7, name: "دهوك" },
    { id: 8, name: "الأنبار" },
    { id: 9, name: "نينوى" },
    { id: 10, name: "صلاح الدين" },
    { id: 11, name: "ديالى" },
    { id: 12, name: "واسط" },
    { id: 13, name: "بابل" },
    { id: 14, name: "كركوك" },
    { id: 15, name: "المثنى" },
    { id: 16, name: "ذي قار" },
    { id: 17, name: "القادسية" },
    { id: 18, name: "النجف الأشرف" },
  ],
};

export const Districts = {
  1: [
    // محافظة البصرة
    { id: 1, name: "البكر والأحرار" },
    { id: 2, name: "حي الرسالة" },
    { id: 3, name: "حي الكفاءات" },
    { id: 4, name: "حي الخضراء" },
    { id: 5, name: "العشار" },
    { id: 6, name: "دور النفط" },
    { id: 7, name: "البراضعية" },
    { id: 8, name: "مناوي باشا" },
    { id: 9, name: "مناوي لجم" },
    { id: 10, name: "حي الجمهورية" },
    { id: 11, name: "التحسينية" },
    { id: 12, name: "منطقة العباسية" },
    { id: 13, name: "الأبلة" },
    { id: 14, name: "حي الجزائر" },
    { id: 15, name: "كرمة علي" },
    { id: 16, name: "المعقل" },
    { id: 17, name: "خمسة ميل" },
    { id: 18, name: "بريهة" },
    { id: 19, name: "الحكيمية" },
    { id: 20, name: "التميمية" },
    { id: 21, name: "الجنينة" },
    { id: 22, name: "الطويسة" },
    { id: 23, name: "القبلة" },
    { id: 24, name: "النجيبية" },
    { id: 25, name: "الموفقية" },
    { id: 26, name: "الأصمعي القديم" },
    { id: 27, name: "الأصمعي الجديد" },
    { id: 28, name: "الجبيلة" },
    { id: 29, name: "حي الأندلس" },
    { id: 30, name: "حي الطليعة" },
    { id: 31, name: "حي الخليج العربي" },
    { id: 32, name: "حي الجزيرة" },
    { id: 33, name: "حي القاسم الشمالي" },
    { id: 34, name: "حي الوحدة" },
    { id: 35, name: "حي الرافدين" },
    { id: 36, name: "السيمر" },
    { id: 37, name: "منطقة الساحات" },
    { id: 38, name: "البرجسية" },
    { id: 39, name: "الحي العسكري" },
    { id: 40, name: "صبخة العرب" },
    { id: 41, name: "العالية" },
    { id: 42, name: "كوت الحجاج الاولى" },
    { id: 43, name: "كوت الحجاج الثانية" },
    { id: 44, name: "حي الحكيم" },
    { id: 45, name: "حي المهندسين" },
    { id: 46, name: "الزبير" },
    { id: 47, name: "ابي الخصيب" },
  ],
};
