import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  slug: "",
  product_name: "",
  description: "",
  count_page: "",
  stock: "",
  price: "",
  wholesale_price: "",
  type: "Books",
  status: "Active",
  year_publication: "",
  publishers: [],
  category: [],
  authors: [],
  images: [],
};

const productDataSlice = createSlice({
  name: 'productData',
  initialState,
  reducers: {
    // تحديث الـ reducer لتعديل الحالة مباشرة
    updateProductData(state, action) {
        
      return { ...state, ...action.payload };
    },
    resetProductData() {
      return initialState;
    },
  },
});

export const { updateProductData, resetProductData } = productDataSlice.actions;
export default productDataSlice.reducer;
