// productsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance";
import { triggerReset } from "./resetSlice";
import { resetProductData } from "./productDataSlice";
import { toast } from "sonner";

export const getProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    try {
      const response = await axiosInstance.get("/products");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch products");
    }
  }
);

export const getProductsTable = createAsyncThunk(
  "products/fetchProductsTable",
  async ({ page, limit }) => {
    try {
      const response = await axiosInstance.get(
        `/ProductsTable?page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch products from ProductsTable");
    }
  }
);

// استدعاء خيارات المنتجات
export const OptionsProduct = createAsyncThunk(
  "products/fetchOptionsProduct",
  async () => {
    try {
      const response = await axiosInstance.get(`/OptionsProduct`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch product options");
    }
  }
);

// استرداد منتج معين عبر slug
export const showProduct = createAsyncThunk(
  "products/showProduct",
  async (slug) => {
    try {
      const response = await axiosInstance.get(`/product/${slug}`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch product details");
    }
  }
);

// إضافة منتج جديد
export const newProduct = createAsyncThunk(
  "products/newProduct",
  async (productData, thunkAPI) => {
    try {
      const formData = new FormData();

      Object.keys(productData).forEach((key) => {
        if (key === "images") {
          productData.images.forEach((image) =>
            formData.append("images[]", image)
          );
        } else if (["authors", "publishers", "category"].includes(key)) {
          if (Array.isArray(productData[key])) {
            productData[key].forEach((item) =>
              formData.append(`${key}[]`, item)
            );
          }
        } else {
          formData.append(key, productData[key]);
        }
      });

      const response = await axiosInstance.post("/product/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      thunkAPI.dispatch(resetProductData()); // إعادة تعيين بيانات المنتج بعد الإضافة
      thunkAPI.dispatch(triggerReset());

      toast.success("تم إضافة المنتج بنجاح");

      return response.data;
    } catch (error) {
      if (error.response.status === 426) {
        toast.error(
          "هذا الرابط موجود بالفعل في قاعدة البيانات. يرجى اختيار رابط مختلف."
        );
      } else {
        toast.error("خطأ أثناء إضافة المنتج");
      }
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// تحديث منتج موجود
export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ id, productData }, thunkAPI) => {
    try {
      const formData = new FormData();

      Object.keys(productData).forEach((key) => {
        if (key === "images") {
          productData.images.forEach((image) =>
            formData.append("images[]", image)
          );
        } else if (Array.isArray(productData[key])) {
          productData[key].forEach((item) =>
            formData.append(`${key}[]`, item.value)
          );
        } else {
          formData.append(key, productData[key]);
        }
      });

      const response = await axiosInstance.post(
        `/product/edit/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response.status === 426) {
        toast.error(
          "هذا الرابط موجود بالفعل في قاعدة البيانات. يرجى اختيار رابط مختلف."
        );
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// حذف منتج
export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (id, thunkAPI) => {
    try {
      await axiosInstance.delete(`/product/${id}`);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    currentProduct: null,
    productOptions: [], // حالة جديدة لتخزين خيارات المنتجات
    loading: false,
    error: null,
    statusCode: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductsTable.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.statusCode = null;
      })
      .addCase(getProductsTable.fulfilled, (state, action) => {
        state.products = action.payload;
        state.loading = false;
        state.statusCode = 200;
      })
      .addCase(getProductsTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.statusCode = action.error?.response?.status;
      })
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.statusCode = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.loading = false;
        state.statusCode = 200;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.statusCode = action.error?.response?.status;
      })
      .addCase(showProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.statusCode = null;
      })
      .addCase(showProduct.fulfilled, (state, action) => {
        state.currentProduct = action.payload;
        state.loading = false;
        state.statusCode = 200;
      })
      .addCase(showProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.statusCode = action.error?.response?.status;
      })
      .addCase(newProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.statusCode = null;
      })
      .addCase(newProduct.fulfilled, (state, action) => {
        state.products.push(action.payload);
        state.loading = false;
        state.statusCode = 200;
      })
      .addCase(newProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
        state.statusCode = action.error?.response?.status;
      })
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.statusCode = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const index = state.products.findIndex(
          (product) => product.id === action.payload.id
        );
        if (index !== -1) {
          state.products[index] = action.payload;
        }
        state.loading = false;
        state.statusCode = 200;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
        state.statusCode = action.error?.response?.status;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.statusCode = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.products = state.products.filter(
          (product) => product.id !== action.payload
        );
        state.loading = false;
        state.statusCode = 200;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
        state.statusCode = action.error?.response?.status;
      })
      .addCase(OptionsProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.statusCode = null;
      })
      .addCase(OptionsProduct.fulfilled, (state, action) => {
        state.productOptions = action.payload; // تحديث خيارات المنتجات
        state.loading = false;
        state.statusCode = 200;
      })
      .addCase(OptionsProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
        state.statusCode = action.error?.response?.status;
      })
      .addMatcher(
        (action) =>
          action.type.endsWith("/fulfilled") ||
          action.type.endsWith("/rejected"),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export default productsSlice.reducer;
