import React, { useState } from "react";
import { Toaster, toast } from "sonner";
import { SwiperSlide } from "swiper/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import SwiperSlider from "../../../../../components/common/sliders/Slider";
import ActionButton from "../../../../../components/common/button/actionButton";

import {
  resetProductData,
  updateProductData,
} from "../../../../../lib/redux/slices/productDataSlice";

import { newProduct } from "../../../../../lib/redux/slices/productsSlices";
import FileInput from "../../../../../components/common/button/FileInput";
import FilePreview from "../../../../../components/upload/FilePreview";
import Refresh from "../../../components/Refresh/Refresh";
import FromCustomSelect from "./fromCustomSelect";
import FormInputs from "./FormInputs";

const AddProduct = () => {
  const [descriptionData, setDescriptionData] = useState("");
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.productData || {});
  const [files, setFiles] = useState([]);
  const [fileLoading, setFileLoading] = useState([]);
  const loading = useSelector((state) => state.products.loading);
  const [isRotated, setIsRotated] = useState(false);

  const handleClick = () => {
    setIsRotated(!isRotated);
    dispatch(resetProductData());
  };

  const generateSlug = (name) => {
    return name
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\u0600-\u06FF\w-]+/g, "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newForm = { ...productData, [name]: value };
    if (name === "product_name") {
      newForm.slug = generateSlug(value);
    }
    dispatch(updateProductData(newForm));
  };

  const validateProductData = () => {
    const { publishers, category, authors } = productData;
    return (
      publishers.length > 0 &&
      category.length > 0 &&
      authors.length > 0 &&
      descriptionData.trim() !== ""
    );
  };

  const handleSelectChange = (name, event) => {
    const value = event.target.value;
    dispatch(updateProductData({ ...productData, [name]: value }));
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    dispatch(
      updateProductData({
        ...productData,
        images: [...productData.images, ...selectedFiles],
      })
    );
  };

  const removeFile = (indexToRemove) => {
    setFileLoading((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[indexToRemove] = true;
      return updatedLoading;
    });

    setTimeout(() => {
      setFiles((prevFiles) =>
        prevFiles.filter((_, index) => index !== indexToRemove)
      );
      setFileLoading((prevLoading) =>
        prevLoading.filter((_, index) => index !== indexToRemove)
      );
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateProductData()) {
      toast.error("يرجى التحقق من ملئ جميع البيانات بشكل صحيح.");
      return;
    }
    const actionResult = await dispatch(newProduct(productData));
    if (actionResult.meta.requestStatus === "fulfilled") {
      setFiles([]);
      setDescriptionData("");
    }
  };

  return (
    <div className="content-bar relative">
      <div className="title-bar">
        <h2>إضافة منتج جديد</h2>
      </div>
      <div className="path-bar relative">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">المنتجات</div>
        <div className="url-path slash">/</div>
        <div className="url-path">إضافة منتج جديد</div>
        <Refresh onClick={handleClick} />
      </div>
      <div className="container-form">
        <form onSubmit={handleSubmit}>
          <div className="grid gap-x-3 gap-y-4 grid-cols-1 md:grid-cols-3 mb-4">
            <FormInputs
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
            />
            <FromCustomSelect handleSelectChange={handleSelectChange} />
          </div>
          <DescriptionEditor
            descriptionData={descriptionData}
            setDescriptionData={setDescriptionData}
            productData={productData}
            setProductData={(data) => dispatch(updateProductData(data))}
          />
          <FileHandling
            files={files}
            fileLoading={fileLoading}
            handleFileChange={handleFileChange}
            removeFile={removeFile}
          />
          <div className="flex gap-3 mt-3">
            <FileInput onChange={handleFileChange} multiple={true} />
            <ActionButton
              type="submit"
              style={{ backgroundColor: "#18bc9c" }}
              text="اضافة المنتج"
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const DescriptionEditor = ({
  descriptionData,
  setDescriptionData,
  productData,
  setProductData,
}) => {
  return (
    <div>
      <h1 className="text-xl mb-2 mt-3">الوصف</h1>
      <CKEditor
        editor={ClassicEditor}
        data={descriptionData}
        onChange={(event, editor) => {
          const data = editor.getData();
          setDescriptionData(data);
          setProductData({ ...productData, description: data });
        }}
      />
    </div>
  );
};

const FileHandling = ({ files, fileLoading, handleFileChange, removeFile }) => {
  return (
    <div className="box relative gap-3 mt-3 justify-start">
      {files.length > 0 && (
        <SwiperSlider>
          {files.map((file, index) => (
            <SwiperSlide
              key={index}
              className="flex gap-3 items-center justify-start"
              style={{ width: "auto" }}
            >
              <FilePreview
                fileLoading={fileLoading}
                file={file}
                index={index}
                removeFile={removeFile}
              />
            </SwiperSlide>
          ))}
        </SwiperSlider>
      )}
    </div>
  );
};

export default AddProduct;
