import axios from "axios";
import React, { useState, useEffect } from "react";
import CustomTable from "../../components/Table/CustomTable";
import DeletePopup from "../../../../components/common/Popup modal/deletePopup";
import Pagination from "../../../../components/common/pagination/pagination";
import { Toaster, toast } from "sonner";
import ModalForm from "../../../../components/common/Popup modal/ModalForm";
import {
  getCategories,
  addCategory,
  deleteCategory,
  clearCategoryError,
} from "../../../../lib/redux/slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/select/customSelect";

function Category() {
  const [loadingCat, setLoadingCat] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [form, setForm] = useState({
    name: "",
    slug: "",
  });
  const dispatch = useDispatch();
  const { categories, status } = useSelector((state) => state.category);
  console.log(categories);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const fields = [
    { name: "name", label: "الأسم", type: "text", required: true },
    {
      name: "slug",
      label: "الرابط",
      type: "text",
      required: true,
      disabled: form.name === "",
    },
  ];

  // teble
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const columns = ["التصنيف", "عدد المنتجات"];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = categories
    .slice(indexOfFirstItem, indexOfLastItem)
    .map((category) => ({
      id: category.id,
      info: [category.name || "-", category.products_count || "-"],
    }));

  const generateSlug = (name) => {
    return name
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\u0600-\u06FF\w-]+/g, "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newForm = { ...form, [name]: value };
    if (name === "name") {
      newForm.slug = generateSlug(value);
    }
    setForm(newForm);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.name || !form.slug) {
      toast.error("يرجى ملء جميع الحقول المطلوبة");
      return;
    }
    setLoadingCat(true);
    dispatch(addCategory(form));
  };

  const handleDelete = async (categoryId) => {
    // dispatch(deleteCategory(formData));
  };

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>التصنيفات</h2>
        <button className="btn-style" onClick={() => setOpenModal(true)}>
          إضافة تصنيف جديد
        </button>
      </div>

      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الأقسام</div>
        <div className="url-path slash">/</div>
        <div className="url-path">التصنيفات</div>
      </div>
      <CustomTable
        columns={columns}
        data={currentData} // Use categories directly
        onDelete={handleDelete}
        loading={loadingCat} // Use loadingCat for specific loading state
      />
      {/* <DeletePopup
        show={showModal}
        setOpenModal={setShowModal}
        onConfirm={confirmDelete}
      /> */}
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(categories.length / itemsPerPage)}
        onPageChange={onPageChange}
        previousLabel="السابق"
        nextLabel="التالي"
        showIcon={true}
      />
      <ModalForm
        open={openModal}
        onClose={() => setOpenModal(false)}
        formTitle="إضافة دار نشر جديد"
        form={form}
        setForm={setForm}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        fields={fields}
        loading={loadingCat}
      />
      <Toaster
        richColors
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
          },
          className: "my-toast",
        }}
      />
    </div>
  );
}

export default Category;
