// src/slices/slidersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance";
import { toast } from "sonner";

// تعريف إجراءات غير متزامنة (Async Thunks)
export const fetchSliders = createAsyncThunk(
  "sliders/fetchSliders",
  async () => {
    const response = await axiosInstance.get("/stor/getSliders");
    return response.data;
  }
);

export const fetchSlider = createAsyncThunk(
  "sliders/fetchSlider",
  async (id) => {
    const response = await axiosInstance.get(`/stor/showSliders?id=${id}`);
    return response.data;
  }
);

export const addSlider = createAsyncThunk(
  "sliders/addSlider",
  async (slider, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/stor/addSliders", slider);
      toast.success("تمت الإضافة بنجاح.");
      dispatch(fetchSliders());
      return response.data;
    } catch (err) {
      toast.error("حدث خطأ أثناء إضافة السلايدر.");
      return rejectWithValue(err);
    }
  }
);

export const updateSlider = createAsyncThunk(
  "sliders/updateSlider",
  async (slider, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/stor/updateSliders`, slider);
      dispatch(fetchSliders());
      toast.success("تم التحديث بنجاح.");
      return response.data;
    } catch (err) {
      toast.error("حدث خطأ أثناء التحديث.");
      return rejectWithValue(err);
    }
  }
);

export const deleteSlider = createAsyncThunk(
  "sliders/deleteSlider",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/stor/deleteSliders?id=${id}`);
      dispatch(fetchSliders());
      toast.success("تم الحذف بنجاح.");
      return id;
    } catch (err) {
      toast.error("حدث خطأ أثناء الحذف، يرجى المحاولة مرة أخرى.");
      return rejectWithValue(err);
    }
  }
);


// إنشاء شريحة Redux
const slidersSlice = createSlice({
  name: "sliders",
  initialState: {
    sliders: [],
    status: "idle", // يمكن أن تكون 'idle', 'loading', 'succeeded', 'failed'
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliders.pending, (state) => {
        state.status = "loading";
        state.loading = true; // بدء تحميل
      })
      .addCase(fetchSliders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false; // إنهاء تحميل
        state.sliders = action.payload.sliders || action.payload;
      })
      .addCase(fetchSliders.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false; // إنهاء تحميل
        state.error = action.error.message;
      })
      .addCase(fetchSlider.pending, (state) => {
        state.status = "loading";
        state.loading = true; // بدء تحميل
      })
      .addCase(fetchSlider.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false; // إنهاء تحميل
        const index = state.sliders.findIndex(
          (slider) => slider.id === action.payload.id
        );
        if (index >= 0) {
          state.sliders[index] = action.payload;
        } else {
          state.sliders.push(action.payload);
        }
      })
      .addCase(fetchSlider.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false; // إنهاء تحميل
        state.error = action.error.message;
      })
      .addCase(addSlider.fulfilled, (state, action) => {
        state.sliders.push(action.payload);
      })
      .addCase(updateSlider.fulfilled, (state, action) => {
        const index = state.sliders.findIndex(
          (slider) => slider.id === action.payload.id
        );
        if (index >= 0) {
          state.sliders[index] = action.payload;
        }
      })
      .addCase(deleteSlider.fulfilled, (state, action) => {
        state.sliders = state.sliders.filter(
          (slider) => slider.id !== action.payload
        );
      });
  },
});

export const { clearError } = slidersSlice.actions;

export default slidersSlice.reducer;
