import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import CustomTable from "../../components/Table/CustomTable";
import DeletePopup from "../../../../components/common/Popup modal/deletePopup";
import Pagination from "../../../../components/common/pagination/pagination";
import { Toaster, toast } from "sonner";
import { fetchAuthors, deleteAuthor } from "../../../../api/api";

const Author = () => {
  const [authors, setAuthors] = useState([]);
  // loading
  const [deleteLoading, setDeleteLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteUser, setDeleteUser] = useState(false);


  const cookies = new Cookies();
  const token = cookies.get("Bearer");

  useEffect(() => {
    const fetchAuthorsList = async () => {
      setLoading(true);
      try {
        const data = await fetchAuthors(token);
        setAuthors(data);
      } catch (error) {
        console.error("حدث خطأ أثناء جلب البيانات:", error);
        toast.error("حدث خطأ أثناء جلب البيانات");
      } finally {
        setLoading(false);
      }
    };

    fetchAuthorsList();
  }, [token, deleteUser]);

  const handleDelete = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (!token || !selectedItem) return;
    setLoading(true);
    try {
      await deleteAuthor(selectedItem, token);
      toast.success("تم حذف المستخدم بنجاح");
      setAuthors((prevAuthors) =>
        prevAuthors.filter((author) => author.id !== selectedItem)
      );
      setShowModal(false);
      setDeleteUser(true);
      setSelectedItem(null);
    } catch (error) {
      console.error("خطأ في حذف المستخدم:", error);
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      } else {
        toast.error("حدث خطأ أثناء حذف المستخدم");
      }
    } finally {
      setDeleteLoading(false);
    }
  };
  
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const itemsPerPage = 10;
  const columns = ["الصورة", "الأسم", "عدد المؤلفات"];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = authors
    .slice(indexOfFirstItem, indexOfLastItem)
    .map((author) => ({
      id: author.id,
      info: [
        author.image || "-",
        author.name || "-",
        // author.products_count || "-",
        author.products_count = "77"|| "-",
      ],
    }));

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>الكتّاب</h2>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الأقسام</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الكتّاب</div>
      </div>
      <div className="container-form">
        <div className="flex flex-wrap gap-3 justify-between">
          <input
            type="text"
            className="max-w-96"
            name="name"
            id="name"
            placeholder="البحث ..."
          />
          <select name="" className="max-w-md">
            <option value="1">الكل</option>
            <option value="2">محظور من الخدمة</option>
            <option value="3">مستخدم جديد</option>
            <option value="4">مستخدم قديم</option>
          </select>
        </div>
      </div>
      <CustomTable
        columns={columns}
        data={currentData}
        onDelete={handleDelete}
        loading={loading}
      />
      <DeletePopup
        show={showModal}
        setOpenModal={setShowModal}
        onConfirm={confirmDelete}
      />
      {loading ? null : (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(authors.length / itemsPerPage)}
          onPageChange={onPageChange}
          previousLabel="السابق"
          nextLabel="التالي"
          showIcon
        />
      )}
      <Toaster
        richColors
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
            textAlign:"right"
          },
          className: "my-toast",
        }}
      />
    </div>
  );
};

export default Author;
