import React, { useEffect, useState } from "react";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs"; // تأكد من استيراد TabPanel
import { useDispatch, useSelector } from "react-redux";
import ProductPOE from "./productPOE";
import CartPOE from "./CartPOE";
import {
  addInvoice,
  updateInvoice,
  removeInvoice,
} from "../../../../lib/redux/slices/invoiceSlice";
import CloseButton from "../../../../components/common/button/CloseButton";
import SidebarComponent from "../../../../components/Sidebar/SidebarComponent";

const InvoiceTabs = () => {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.invoice.invoices);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (invoices.length === 0) {
      addNewTab();
    }

    const handleBeforeUnload = (event) => {
      const openInvoice = invoices.find((inv) => inv.data.items.length > 0);
      if (openInvoice) {
        const message =
          "لديك فاتورة تحتوي على منتجات. هل أنت متأكد أنك تريد مغادرة الصفحة؟";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [invoices, dispatch]);

  const addNewTab = () => {
    const newTabId = invoices.length + 1;
    const newInvoice = {
      id: newTabId,
      name: `الفاتورة رقم : ${newTabId}`,
      data: { items: [] },
    };
    dispatch(addInvoice(newInvoice));
    setActiveTabIndex(invoices.length);
  };

  const closeTab = (tabIndex) => {
    const tabId = invoices[tabIndex]?.id;
    if (tabId) {
      dispatch(removeInvoice(tabId));
      setActiveTabIndex(Math.max(0, activeTabIndex - 1));
    }
  };

  const updateTabData = (id, updateFn) => {
    const invoice = invoices.find((inv) => inv.id === id);
    if (invoice) {
      dispatch(updateInvoice({ id, data: updateFn(invoice.data) }));
    }
  };

  const Cart = () => {
    return (
      <CartPOE
        className="cart-poe"
        invoiceId={invoices[activeTabIndex]?.id}
        updateTabData={(data) =>
          updateTabData(invoices[activeTabIndex]?.id, () => data)
        }
      />
    );
  };

  return (
    <div className="p-0 flex-1 bg-white h-screen" style={{ height: "calc(100% - 75px)" }}>
      <Tabs
        className="w-full h-full grid grid-rows-[60px,auto]"
        selectedIndex={activeTabIndex}
        onSelect={(index) => setActiveTabIndex(index)}
      >
        <div className="flex items-center w-full mb-4">
          <TabList className="flex overflow-x-auto">
            {invoices.map((tab, index) => (
              <Tab
                key={tab.id}
                className={`flex gap-3 items-center`}
                style={{
                  height:"45px",
                  minWidth: "100px",
                  padding: "10px 15px",
                  backgroundColor: activeTabIndex === index ? "#18bc9c" : "#192330",
                  color: "white",
                }}
              >
                <span
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {tab.name}
                </span>
                {invoices.length > 1 && (
                  <CloseButton onClick={() => closeTab(index)} />
                )}
              </Tab>
            ))}
          </TabList>
          <button
            className="flex items-center justify-center"
            style={{ minWidth: "40px", height: "40px" }}
            onClick={addNewTab}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
        </div>

        {/* <div className="path-bar mt-4 mx-5">
          <div className="url-path active-path">لوحة التحكم</div>
          <div className="url-path slash">/</div>
          <div className="url-path">البيع المباشر</div>
          <div className="url-path slash">/</div>
          <div className="url-path">
            الفاتورة رقم: {invoices[activeTabIndex]?.name}
          </div>
        </div> */}

        <div className="lg:hidden">
          <SidebarComponent id="POECart">
            <Cart />
          </SidebarComponent>
        </div>

        <div className="overflow-hidden flex flex-col h-full px-[20px]">
          <div className="flex gap-3 flex-1 overflow-hidden relative">
            <div className="flex-1 max-w-[420px] hidden lg:block overflow-y-auto">
              <Cart />
            </div>
            <div className="flex-1 overflow-y-auto">
              <ProductPOE invoiceId={invoices[activeTabIndex]?.id} />
            </div>
          </div>
        </div>

        {/* إضافة TabPanel هنا */}
        {/* {invoices.map((invoice, index) => (
          <TabPanel key={invoice.id}>
            <div className="p-4">
              <h2>محتوى الفاتورة رقم: {invoice.name}</h2>
            </div>
          </TabPanel>
        ))} */}
      </Tabs>
    </div>
  );
};

export default InvoiceTabs;
