import React from "react";
import ActionButton from "../../../components/common/button/actionButton";

const OrderSummary = ({ total }) => {
  console.log(total);
  return (
    <div className="shadow bg-white p-5 xs:p-7 rounded-md mb-5 relative transition-height duration-1000">
      <h2 className="font-bold text-sm mb-5">ملخص الطلب</h2>
      <div className="flex justify-between text-sm mb-5">
        <span className="text-gray-500">مجموع المنتجات</span>
        <b id="sub-total">{total.toLocaleString()} د.ع</b>
      </div>
      <div
        id="shipping-cost"
        className="flex justify-between text-sm mb-5 {hidden}"
      >
      </div>
      <div className="border-t border-gray-200 border-b py-5 mb-5">
        <label htmlFor="coupon" className="block text-sm ">
          هل لديك كوبون خصم
        </label>
        <div className="mt-2.5 flex relative overflow-hidden border rounded-[5px]">
          <input
            placeholder="ادخل كود الخصم"
            className="rtl:pl-24 ltr:pr-24 text-sm focus-border-none"
            name="coupon"
            value=""
            type="text"
          />
          <button className="w-24 bg-[var(--primary)] text-[var(--neutral)]">
            أضافة
          </button>
        </div>
        <span className="text-xs text-red-700" id="coupon-error" />
      </div>
      <div
        id="total-discount"
        className={`flex justify-between text-sm {hidden}`}
      >
        {/* <span className="text-gray-500 block  h-10">الخصم</span>
        <b>- 0 د.ع</b> */}
      </div>
      <div className="flex justify-between text-lg mb-5">
        <span className="text-gray-500">الإجمالي</span>
        <span className="flex gap-1">
          <b>{total.toLocaleString()}</b>
          <b>د.ع</b>
        </span>
      </div>
      <div className="flex justify-between text-sm mb-5">
        {/* <small className="text-gray-500">* الأسعار شاملة للضريبة</small> */}
      </div>
      <div className="cart-submit-wrap">
        <span className="flex gap-2 items-center mt-4 mb-1 text-sm font-bold text-blueGray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className=" w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
          <span>الخطوة القادمة عنوان الشحن وطريقة الدفع. </span>
        </span>

        <ActionButton
          text="إتمام الطلب"
          className="bg-[var(--primary)] w-full"
        />
      </div>
    </div>
  );
};

export default OrderSummary;
