import { useDispatch, useSelector } from "react-redux";
import { updateProductData } from "../../../../../lib/redux/slices/productDataSlice";

const InputFields = () => {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.productData);

  const handleChange = (name, value) => {
    dispatch(updateProductData({ [name]: value }));
  };

  // تحقق من نوع المنتج
  const isProductTypeBooks = productData.type === "Books";

  // قائمة الحقول
  const fields = [
    {
      name: "product_name",
      label: "عنوان المنتج",
      tooltipContent: "هذا الحقل لإدخال عنوان المنتج.",
      placeholder: "اسم المنتج",
      value: productData.product_name,
      onChange: (e) => handleChange("product_name", e.target.value),
    },
    {
      name: "slug",
      label: "رابط المنتج",
      tooltipContent: "هذا الحقل يعرض رابط المنتج بناءً على اسم المنتج المدخل.",
      placeholder: "",
      value: productData.slug,
      onChange: (e) => handleChange("slug", e.target.value),
    },
    {
      name: "type",
      label: "نوع المنتج",
      tooltipContent: "هذا الحقل يحدد نوع المنتج، مثل كتب أو منتجات أخرى.",
      placeholder: "اختر نوع المنتج",
      type: "select",
      options: [
        { label: "كتب", value: "Books" },
        { label: "منتجانت", value: "Products" },
      ],
      value: productData.type,
      onChange: (e) => handleChange("type", e.target.value),
    },
    ...(isProductTypeBooks
      ? [
          {
            name: "count_page",
            type: "number",
            label: "عدد الصفحات",
            tooltipContent:
              "هذا الحقل لإدخال عدد الصفحات في حال كان المنتج كتابًا.",
            placeholder: "ادخل عدد الصفحات",
            value: productData.count_page,
            onChange: (e) => handleChange("count_page", e.target.value),
          },
          {
            name: "year_publication",
            label: "سنة النشر",
            tooltipContent: "هذا الحقل لتحديد سنة النشر.",
            type: "calendar",
            value: productData.year_publication,
            onChange: (selectedYear) =>
              handleChange("year_publication", selectedYear),
          },
        ]
      : []),
    {
      name: "wholesale_price",
      type: "number",
      label: "سعر الجملة",
      tooltipContent:
        "هذا الحقل لتحديد سعر الجملة حتى تحصل على تقارير دقيقة بالأرباح.",
      placeholder: "ادخل سعر الجملة",
      value: productData.wholesale_price,
      onChange: (e) => handleChange("wholesale_price", e.target.value),
    },
    {
      name: "price",
      type: "text",
      label: "سعر البيع",
      tooltipContent: "هذا الحقل لإدخال سعر البيع للمنتج.",
      placeholder: "ادخل السعر أو النسبة المئوية",
      value: productData.price,
      onChange: (e) => handleChange("price", e.target.value),
      onBlur: () => {
        const inputValue = productData.price;
        const numberValue = parseFloat(inputValue);
        const percentageMatch = inputValue.match(/^(\d+(\.\d+)?)%$/);

        if (!productData.wholesale_price) {
          handleChange("price", "");
          return;
        }

        if (percentageMatch) {
          const percentage = parseFloat(percentageMatch[1]);
          const calculatedPrice =
            parseFloat(productData.wholesale_price) +
            parseFloat(productData.wholesale_price) * (percentage / 100);
          handleChange("price", calculatedPrice.toString());
        } else if (!isNaN(numberValue)) {
          handleChange("price", numberValue.toString());
        }
      },
    },
    {
      name: "stock",
      type: "number",
      label: "الكمية",
      tooltipContent: "هذا الحقل لتحديد عدد القطع المتوفرة من المنتج.",
      placeholder: "ادخل كمية المنتج",
      value: productData.stock,
      onChange: (e) => handleChange("stock", e.target.value),
    },
    ...(isProductTypeBooks
      ? [
          {
            name: "status",
            label: "الحالة",
            tooltipContent:
              "هذا الحقل يحدد حالة المنتج، سواء كان نشطًا أو غير نشط.",
            placeholder: "اختر الحالة",
            type: "select",
            options: [
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ],
            value: productData.status,
            onChange: (e) => handleChange("status", e.target.value),
          },
        ]
      : []),
  ];
  return fields;
};

export default InputFields;
