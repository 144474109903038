import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../lib/redux/slices/sidebarSlice";
import { AnimatePresence, motion } from "framer-motion";
import useClickOutside from "../../Hooks/useClickOutside";

const SidebarComponent = ({ bg = false, id, children, className = "" }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.sidebars.openSidebars[id]);

  // Function to close sidebar
  const handleClose = () => {
    dispatch(toggleSidebar({ id }));
  };

  const sidebarRef = useClickOutside(handleClose, isOpen);

  return (
    <AnimatePresence>
      {isOpen && (
        <div
          className={`fixed inset-0 bg-[#0000004b] z-[899] ${
            bg ? "hidden" : "block"
          }`}
        >
          <motion.div
            ref={sidebarRef}
            className={`fixed right-0 top-0 text-primary bg-white w-[350px] h-full mr-auto flex flex-col ${className}`}
            style={{ zIndex: 900, borderRight: "1px solid hsl(0, 0%, 90%)" }}
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.3 }}
          >
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default SidebarComponent;
