import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import InfoIcon from "../../../../components/icons/InfoIcon";
import Select from "react-select";
import Calendar from "../../../../components/common/Calendar/Calendar"; // استيراد مكون التقويم
import customStyles from "../select/customStyles";

const TextInput = ({
  name,
  label,
  tooltipContent,
  placeholder,
  type = "text",
  value,
  onChange,
  disabled = false,
  options = [],
  defaultValue,
  validateInput,
  onBlur,
}) => (
  <div>
    {label !== "" ? (
      <label className="mx-2 mb-1 flex items-center gap-2" htmlFor={name}>
        {label}
        <span id={`tooltip-${name}`} className="cursor-pointer">
          <InfoIcon />
        </span>
        {/* <span className="required text-2xl">*</span> */}
        <ReactTooltip
          anchorId={`tooltip-${name}`}
          content={tooltipContent}
          place="top"
        />
      </label>
    ) : null}
    {type === "select" ? (
      <Select
        id={name}
        name={name}
        value={
          options.find((option) => option.value === value) ||
          options.find((option) => option.value === defaultValue)
        }
        onChange={(selectedOption) =>
          onChange({ target: { name, value: selectedOption.value } })
        }
        options={options} // تمرير الخيارات إلى react-select
        styles={customStyles}
        isDisabled={disabled}
        className="block w-full  rounded-md"
      />
    ) : type === "calendar" ? (
      <Calendar
        value={value}
        onChange={(selectedYear) =>
          onChange({ target: { name, value: selectedYear } })
        }
      />
    ) : (
      <input
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        // onChange={onChange}
        onChange={(e) => {
          if (validateInput) validateInput(e);
          onChange(e);
        }}
        disabled={disabled}
        onBlur={onBlur ? onBlur : undefined}
        className="block w-full rounded-md p-2"
      />
    )}
  </div>
);

export default TextInput;
