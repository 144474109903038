import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../lib/axios/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import useClickOutside from "../../../Hooks/useClickOutside";
import {
  openModal,
  closeModal,
} from "../../../lib/redux/slices/modalSlice";

const SearchPage = ({ className, id }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [message, setMessage] = useState("");
  const isOpen = useSelector((state) => state.modal.openModals[id]);
  // const isOpen = true;

  const handleSearch = async (e) => {
    setQuery(e.target.value);

    if (!query.trim()) {
      setMessage("Please enter a search term.");
      setResults([]);
      return;
    }

    try {
      const response = await axiosInstance.get(`/search`, {
        params: { query },
      });

      if (response.data && response.data.data) {
        setResults(response.data.data);
        setMessage("");
      } else {
        setResults([]);
        setMessage("No results found.");
      }
    } catch (error) {
      console.error("Error fetching search results", error);
      setMessage("Error fetching search results");
      setResults([]); // Clear the results on error
    }
  };

  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const handlOpenModal = () => {
    dispatch(openModal(id));
  };
  const handlCloseModal = () => {
    dispatch(closeModal(id));
  };

  const isOpenRef = useClickOutside(handlCloseModal, isOpen);

  const handleBlur = () => {
    setResults([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        handleBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  console.log(isOpen);

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 15,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-[9999] flex items-start justify-center pt-24">
          <div className="fixed inset-0 bg-slate-900/25 backdrop-blur transition-opacity opacity-100" />
          <div className="relative w-full max-w-lg transform px-4 transition-all opacity-100 scale-100">
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className={`relative px-3 flex bg-white border focus-within:border-[var(--sec)] ${className}`}
              style={{
                border: "1px solid hsl(0, 0%, 90%)",
                borderRadius: "5px",
                fontSize: "15px",
              }}
            >
              <div
                className="relative flex justify-between items-center"
                style={{ width: "100vw" }}
              >
                <input
                  ref={isOpenRef}
                  type="text"
                  value={query}
                  onChange={handleSearch}
                  onBlur={handleBlur}
                  placeholder="عنوان الكتاب، المؤلف، دار النشر..."
                  className="text-xs py-1 pl-3 pr-10 w-[100vw] focus-border-none h-full"
                  style={{ padding: "8px", fontSize: "1rem" }}
                />
                <svg
                  className="pointer-events-none h-6 w-6 fill-slate-400"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.47 21.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-9.97-4.28a6.75 6.75 0 0 1-6.75-6.75h-1.5a8.25 8.25 0 0 0 8.25 8.25v-1.5ZM3.75 10.5a6.75 6.75 0 0 1 6.75-6.75v-1.5a8.25 8.25 0 0 0-8.25 8.25h1.5Zm6.75-6.75a6.75 6.75 0 0 1 6.75 6.75h1.5a8.25 8.25 0 0 0-8.25-8.25v1.5Zm11.03 16.72-5.196-5.197-1.061 1.06 5.197 5.197 1.06-1.06Zm-4.28-9.97c0 1.864-.755 3.55-1.977 4.773l1.06 1.06A8.226 8.226 0 0 0 18.75 10.5h-1.5Zm-1.977 4.773A6.727 6.727 0 0 1 10.5 17.25v1.5a8.226 8.226 0 0 0 5.834-2.416l-1.061-1.061Z" />
                </svg>
              </div>
              {results.length > 0 && (
                <ul
                  className="absolute right-0 left-0 top-[120%] py-2 rounded border bg-white"
                  style={{ zIndex: 999 }}
                >
                  {results.map((result, index) => (
                    <li key={index} className="px-4 py-3 hover:bg-[var(--bg)]">
                      {result.name}
                    </li>
                  ))}
                </ul>
              )}
              {message && (
                <p className="text-red-500 text-xs mt-2">{message}</p>
              )}
            </motion.div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchPage;
