import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Toaster } from "sonner";

import NotificationsSidebar from "../../components/Sidebar/NotificationsSidebar";
import SearchPage from "../../components/common/Search/searchPage";
import NavBar from "../../components/navbar/navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/footer";
import CartMenu from "./cart/cartMenu";

const Index = () => {
  const location = useLocation();
  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [location]);
  return (
    <div className="relative h-auto ">
      <NavBar />
      <Outlet />
      <CartMenu />
      <Sidebar />
      <SearchPage id="SearchPage" />
      <NotificationsSidebar />
      <Toaster
        richColors
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
            textAlign: "right",
          },
          className: "my-toast sm:w-full right-0",
        }}
      />
    </div>
  );
};

export default Index;
