import React from "react";
import PropTypes from "prop-types";
import ActionButton from "../button/actionButton";
import { DeleteIcon } from "../../../assets/icons/CustomIcons";

const DeletePopup = ({
  show,
  setOpenModal,
  onConfirm,
  message,
}) => {
  if (!show) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}
    >
      <div
        size="md"
        popup
        className="px-8 pt-8 pb-6 max-w-md flex justify-center items-center"
        style={{
          backgroundColor: "#fff",
          minWidth: "340px",
          border: "1px solid #f1f5f9",
          borderRadius: "5px",
          zIndex: 10,
        }}
      >
        <div className="text-center w-full">
          <div className="mx-auto mb-6 h-[5rem] w-[5rem] bg-red-50 p-2 rounded-full">
            <DeleteIcon className="flex items-center justify-center p-3 text-red-500 rounded-full bg-red-100" />
          </div>

          <h3 className="mb-6 text-lg font-normal text-gray-500">{message}</h3>
          <div className="flex items-center justify-center gap-4">
            <ActionButton
              text="نعم, حذف"
              className="py-3 px-4 h-10"
              style={{ flex: 1 }}
              onClick={() => {
                onConfirm();
                setOpenModal(false);
              }}
            />
            <ActionButton
              text="لا ، إلغاء"
              className="py-3 px-4 bg-transparent text-gray-900 h-9"
              onClick={() => setOpenModal(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DeletePopup.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  icon: PropTypes.elementType,
  svgIcon: PropTypes.element,
};

DeletePopup.defaultProps = {
  message: "هل انت متاكد من الحذف؟",
}

export default DeletePopup;
