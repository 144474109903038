import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import customStyles from "../../../pages/dashboard/components/select/customStyles";
import ActionButton from "../button/actionButton";
import CloseButton from "../button/CloseButton";
import useClickOutside from "../../../Hooks/useClickOutside";
import PropTypes from "prop-types";

const animatedComponents = makeAnimated();

const generateSlug = (name) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\u0600-\u06FF\w-]+/g, "");
};

const ModalForm = ({ open, onClose, formTitle, btnText, fields, loading , handleSubmit }) => {
  const ModalFormRef = useClickOutside(onClose, open);

  if (!open) return null;

  const handleInputChange = (e, field) => {
    const { name, value } = e.target;
    const newValue = name === "name" ? generateSlug(value) : value;

    if (field.onChange) {
      field.onChange({
        target: { name, value: newValue },
      });
    }
  };

  const handleSelectChange = (selectedOption, field) => {
    const newValue = field.isMulti
      ? selectedOption.map((opt) => opt.value)
      : selectedOption.value;

    if (field.onChange) {
      field.onChange({
        target: { name: field.name, value: newValue },
      });
    }
  };

  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[900] bg-black bg-opacity-50">
      <div
        ref={ModalFormRef}
        className={`bg-white rounded shadow relative m-6 sm:w-[550px] w-full`}
      >
        <div className="border-b flex justify-between items-center py-4 px-6 mb-2">
          <h2 className="text-lg">{formTitle}</h2>
          <CloseButton onClick={onClose} />
        </div>
        <div className="max-h-[80vh] overflow-y-auto p-6">
          <form encType="multipart/form-data">
            <div
              className={`grid ${
                fields.length > 5 ? "sm:grid-cols-2" : "grid-cols-1"
              } gap-3`}
            >
              {fields.map((field) => (
                <div className="mb-3" key={field.name}>
                  <label htmlFor={field.name}>{field.label}</label>
                  {field.type === "select" ? (
                    <Select
                      className="mt-2"
                      closeMenuOnSelect={!field.isMulti}
                      components={animatedComponents}
                      value={
                        field.options.find(
                          (option) => option.value === field.value
                        ) || null
                      }
                      placeholder={`حدد ${field.label}`}
                      isMulti={field.isMulti || false}
                      styles={customStyles}
                      options={field.options}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, field)
                      }
                    />
                  ) : field.type === "discount" ? (
                    <div className="mt-2 h-[49px] flex justify-center items-center gap-2 p-2 rounded border focus-within:border-[var(--sec)]">
                      <span className={`${field.discount_type === "fixed" && "hidden"}`}>%</span>
                      <input
                        type="number"
                        className="w-full focus-border-none rounded"
                        style={{padding:"0px"}}
                        name={field.name}
                        id={field.name}
                        value={field.value || ""}
                        max={999}
                        onChange={(e) => handleInputChange(e, field)}
                      />
                    </div>
                  ) : (
                    <input
                      type={field.type}
                      className="mt-2 h-[49px] w-full p-2 border rounded"
                      name={field.name}
                      id={field.name}
                      value={field.value}
                      onChange={(e) => handleInputChange(e, field)}
                    />
                  )}
                  {/* {field.required && !field.value && (
                    <span className="text-red-600 error-message mx-2 mt-1">
                      هذا الحقل مطلوب
                    </span>
                  )} */}
                </div>
              ))}
            </div>
            <ActionButton
              className="mt-5 w-full bg-[var(--sec)]"
              text={btnText}
              type="submit"
              loading={loading}
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

ModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formTitle: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  fields: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

ModalForm.defaultProps = {
  btnText: "أضافة",
  loading: false,
};


export default ModalForm;
