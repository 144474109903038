// src/redux/slices/favoritesSlice.js

import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance";

const initialState = {
  favorites: [],
  status: "idle",
  error: null,
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    setFavorites: (state, action) => {
      state.favorites = action.payload;
    },
    addFavorite: (state, action) => {
      state.favorites.push(action.payload);
    },
    removeFavorite: (state, action) => {
      state.favorites = state.favorites.filter(
        (favorite) => favorite.slug !== action.payload
      );
    },
    setLoading: (state) => {
      state.status = "loading";
    },
    setError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    setStatusIdle: (state) => {
      state.status = "idle";
    },
  },
});

export const {
  setFavorites,
  addFavorite,
  removeFavorite,
  setLoading,
  setError,
  setStatusIdle,
} = favoritesSlice.actions;

export const fetchFavorites = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/favorites");
    dispatch(setFavorites(response.data));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const toggleFavorite = (slug) => async (dispatch, getState) => {
  dispatch(setLoading());
  try {
    const { favorites } = getState().favorites;
    const isFavorite = favorites.some((favorite) => favorite.slug === slug);

    if (isFavorite) {
      // Remove from favorites
      await axiosInstance.post("/favorites/remove", {
        slug: slug,
      });
      dispatch(removeFavorite(slug));
    } else {
      // Add to favorites
      await axiosInstance.post("/favorites/add", { slug: slug });
      dispatch(addFavorite({ slug: slug }));
    }
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export default favoritesSlice.reducer;
