import React from "react";
import CustomItems from "./CustomItems";
import { IconOrders, IconProfile } from "../../assets/icons/CustomIcons";

const SidebarContent = () => {
  return (
    <div className="flex flex-col py-2">
      <CustomItems
        text="معلوماتي الشخصية"
        className={`items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md`}
        svgIcon={<IconProfile className="w-6 h-6 mr-2" />}
      />
      <CustomItems
        text="طلباتي"
        className={`items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md`}
        svgIcon={<IconOrders className="w-6 h-6 mr-2" />}
      />
      <CustomItems
        text="عناويني"
        className={`items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md`}
        svgIcon={
          <svg
            className="w-6 h-6 mr-2"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
            <circle cx="12" cy="10" r="3"></circle>
          </svg>
        }
      />
      <a
        href="#/recently_viewed"
        className="flex gap-2 items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md"
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
        <span>شاهدتها مؤخرا</span>
      </a>
      <a
        href="#/wishlist"
        className="flex gap-2 items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md"
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
        </svg>
        <span>مفضلتي</span>
        <span className="ml-auto bg-gray-200 px-2 py-0.5 text-xs rounded-full">
          0
        </span>
      </a>
      <a
        href="#/change_password"
        className="flex gap-2 items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md"
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
        </svg>
        <span>تغيير كلمة المرور</span>
      </a>
      <a
        href="#"
        className="flex gap-2 items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md"
        aria-label="المكافآت"
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
        <span>المكافآت</span>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="flex gap-2 items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md"
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
          <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
        </svg>
        <span>رصيدي من النقاط</span>
      </a>
      <a
        href="#/logout"
        className="flex gap-2 items-center p-2 mb-2 text-gray-700 hover:bg-gray-100 rounded-md"
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M17 16l4-4m0 0l-4-4m4 4H3"></path>
        </svg>
        <span>تسجيل الخروج</span>
      </a>
    </div>
  );
};

export default SidebarContent;
