import React, { useState } from "react";
import "../cart/cart.css";
import { DeleteIcon, Minus, Plus } from "../../../assets/icons/CustomIcons";
import {
  removeFromCart,
  showCart,
  updateQuantity,
} from "../../../lib/redux/slices/cartSlice";
import { useDispatch } from "react-redux";
const CheckoutItem = ({ item }) => {
  const dispatch = useDispatch();

  const handleUpdateQuantity = (type, quantity) => {
    dispatch(updateQuantity({ itemId: item.id, quantity, type }))
      .unwrap()
      .then(() => {
        dispatch(showCart());
      })
      .catch((error) => {});
  };

  const handleRemove = () => {
    dispatch(removeFromCart(item.product.slug))
      .unwrap()
      .then(() => {
        console.log("Product removed successfully");
        dispatch(showCart()); // إعادة جلب بيانات السلة
      })
      .catch((error) => {
        console.error("Failed to remove product:", error);
      });
  };

  const itemQuantity = () => {
    const total = item.product.price * item.quantity;
    return total.toLocaleString();
  };
  


  return (
    <section className="cart-item bg-white p-5 xs:p-7 rounded-md mb-5 relative">
      <div className="md:flex rtl:space-x-reverse md:space-x-12 items-start justify-between mb-8 last:mb-0">
        <div className="flex flex-1 relative rtl:space-x-reverse space-x-4">
          <a href="https://darpagasbook.com/qQReeYm" className="shrink-0">
            <img
              src={item.product.images[0].image}
              alt={item.product.product_name || "Product Image"}
              className="flex-none w-24 h-24 border object-contain border-gray-200 bg-gray-100 rounded-md object-center"
            />
          </a>
          <div className="space-y-1">
            <h1 className="text-gray-900 leading-6 text-lg font-medium mb-1">
              {item.product.product_name}
            </h1>
            <span className="text-sm text-gray-500 line-through hidden">
              {/* <span>{item.product.price.toLocaleString()}</span> */}
              {/* <span>د.ع</span> */}
            </span>
            <p className="text-sm text-gray-500 ">
              {item.product.authors &&
                item.product.authors[0] &&
                item.product.authors[0].name}
            </p>
            <div className="flex gap-1 text-sm text-gray-500 ">
              <span>{item.product.price.toLocaleString()}</span>
              <span>د.ع</span>
            </div>
            <i className="sicon-discount-calculator text-gray-500 offer-icon hidden" />
            <span className="text-sm text-gray-500 offer-name hidden" />
          </div>
          <DeleteIcon onClick={handleRemove} className="w-6 h-6 absolute top-0 left-2 hover:text-red-600 hover:bg-[var(--bg)] rounded-full" />
        </div>
        <div className="flex-1 flex gap-3 border-t border-b border-[var(--border)] py-3 md:p-0 md:border-none mt-5 md:mt-0 justify-between items-center md:items-start">
          <div className="flex rounded border border-[var(--border)] h-9 overflow-hidden">
            <button
              className="w-12 flex items-center justify-center hover:bg-[var(--bg)] border-l"
              onClick={() => handleUpdateQuantity("decrease", 1)}
              type="button"
            >
              <Minus />
            </button>
            <input
              className="min-w-16 text-center focus-border-none"
              type="text"
              style={{
                border: "none",
                borderRadius: "0px",
              }}
              value={item.quantity}
              min="1"
              id="quantity"
              readOnly
            />
            <button
              className="w-12 flex items-center justify-center hover:bg-[var(--bg)] border-r"
              onClick={() => handleUpdateQuantity("increase", 1)}
              type="button"
            >
              <Plus />
            </button>
          </div>
          <div className="flex gap-1 text-primary font-bold text-sm">
            <span>المجموع:</span>
            <span>{itemQuantity()}</span>
            <span>د.ع</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckoutItem;
