// modalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openModals: {},
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, action) {
      const { id } = action.payload;
      state.openModals[id] = true;
    },
    closeModal(state, action) {
      const id = action.payload;
      state.openModals[id] = false;
    },
    toggleModal(state, action) {
      const id = action.payload;
      state.openModals[id] = !state.openModals[id];
    },
  },
});

// تصدير الإجراءات والمحدد
export const { openModal, closeModal, toggleModal } = modalSlice.actions;
export default modalSlice.reducer;
