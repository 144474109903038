import React, { useState } from "react";
import ButtonOrders from "../../components/orders/ButtonOrders";
import CustomTable from "../../components/Table/CustomTable";
import Pagination from "../../../../components/common/pagination/pagination";
import { Toaster } from "sonner";
import { useSelector } from "react-redux";
import {
  newPublisher,
  getPublisher,
} from "../../../../lib/redux/slices/publisherSlice";

export default function Requests() {
  const data = [
    { name: "Product 1", price: 10, category: "Category 1" },
    { name: "Product 2", price: 20, category: "Category 2" },
    { name: "Product 3", price: 30, category: "Category 1" },
    // Add more data as needed
  ];

  const { publishers, status, error } = useSelector(
    (state) => state.publishers
  );
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const itemsPerPage = 10;
  const columns = ["اسم المشتري", "رقم الهاتف","العنوان","تاريخ الطلب","الأجمال", "الحالة"];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = publishers
    .slice(indexOfFirstItem, indexOfLastItem)
    .map((publisher) => ({
      id: publisher.id,
      info: [
        publisher.image || "-",
        publisher.name || "-",
        // author.products_count || "-",
        // author.products_count = "77"|| "-",
      ],
    }));

  const handleDelete = (item) => {};

  return (
    <div className="content-bar">
      <div style={{ position: "relative", marginTop: "15px" }}>
        <h2
          style={{
            marginRight: "20px",
            fontSize: "32px",
            fontWeight: "lighter",
          }}
        >
          المنتجات
        </h2>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الطلبات</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الكل</div>
      </div>

      <ButtonOrders />
      {/* <CustomTable data={data} /> */}
      <button className="btn-style my-3">تحميل الكل</button>
      <CustomTable
        columns={columns}
        data={currentData}
        onDelete={handleDelete}
        loading={loading}
      />
      {/* <DeletePopup
        show={showModal}
        setOpenModal={setShowModal}
        onConfirm={confirmDelete}
      /> */}
      {loading ? null : (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(publishers.length / itemsPerPage)}
          onPageChange={onPageChange}
          previousLabel="السابق"
          nextLabel="التالي"
          showIcon
        />
      )}
      <Toaster
        richColors
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
            textAlign: "right",
          },
          className: "my-toast",
        }}
      />
    </div>
  );
}
