import axios from "axios";
import Cookies from "universal-cookie";

const API_URL = "https://api.kitabyy.com";
// const API_URL = "http://127.0.0.1:8000";
// const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/api";

const cookies = new Cookies();
const token = cookies.get("Bearer");
const csrfToken = cookies.get("XSRF-TOKEN");

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: token ? `Bearer ${token}` : "",
    // "X-XSRF-TOKEN": csrfToken || "", 
  },
  // withCredentials: true,
});

export default axiosInstance;
