import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance";
import { toast } from "sonner";

// جلب جميع الخصومات مع المنتجات المرتبطة بها
export const getDiscounts = createAsyncThunk(
  "discounts/getDiscounts",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/product-discounts/get", {
        params: { page, limit }, // إرسال معلمات البجنيشن
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// إضافة خصم جديد
export const addDiscount = createAsyncThunk(
  "discounts/addDiscount",
  async (discountData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/product-discounts/add", discountData);
      toast.success("تمت إضافة الخصم بنجاح");
      dispatch(getDiscounts());
      return response.data;
    } catch (error) {
      toast.error("حدث خطأ أثناء إضافة الخصم. يرجى المحاولة مرة أخرى.");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// تحديث خصم
export const updateDiscount = createAsyncThunk(
  "discounts/updateDiscount",
  async ({ discountData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/product-discounts/update`, discountData);
      toast.success("تم تحديث الخصم بنجاح");
      dispatch(getDiscounts());
      return response.data;
    } catch (error) {
      toast.error("حدث خطأ أثناء تحديث الخصم. يرجى المحاولة مرة أخرى.");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// حذف خصم
export const deleteDiscount = createAsyncThunk(
  "discounts/deleteDiscount",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/product-discounts/delete/${id}`);
      toast.success("تم حذف الخصم بنجاح");
      dispatch(getDiscounts());
      return id;
    } catch (error) {
      toast.error("حدث خطأ أثناء حذف الخصم. يرجى المحاولة مرة أخرى.");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const discountsSlice = createSlice({
  name: "discounts",
  initialState: {
    discounts: {
      data: [],
      total: 0,
    },
    loading: false,
    status: "idle",
    statusCode: null,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDiscounts.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(getDiscounts.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.statusCode = 200;
        state.discounts.data = action.payload.data;
        state.discounts.total = action.payload.total;
      })
      .addCase(getDiscounts.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.statusCode = action.payload?.status || 500;
        state.error = action.payload;
      })
      .addCase(addDiscount.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(addDiscount.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.statusCode = 201;
        state.discounts.data.push(action.payload);
      })
      .addCase(addDiscount.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.statusCode = action.payload?.status || 500;
        state.error = action.payload;
      })
      .addCase(updateDiscount.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(updateDiscount.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.statusCode = 200;
        const index = state.discounts.data.findIndex(discount => discount.id === action.payload.id);
        if (index !== -1) {
          state.discounts.data[index] = action.payload;
        }
      })
      .addCase(updateDiscount.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.statusCode = action.payload?.status || 500;
        state.error = action.payload;
      })
      .addCase(deleteDiscount.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.statusCode = null;
      })
      .addCase(deleteDiscount.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.statusCode = 200;
        state.discounts.data = state.discounts.data.filter(discount => discount.id !== action.payload);
      })
      .addCase(deleteDiscount.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.statusCode = action.payload?.status || 500;
        state.error = action.payload;
      });
  },
});

export const { clearError } = discountsSlice.actions;
export default discountsSlice.reducer;
