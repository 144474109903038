import axios from "axios";
const baseURL = 'http://localhost:8000/api';

// انشاء حساب
const RGISTER = "register";

// تسجيل الدخول
const LOGIN = "login";

// جلب مدار الموقع
const GET_ADMIN = "getAdmin"



// جلب جميع المنتجات
const GET_PRODUCTS = "products";

export { baseURL, RGISTER, LOGIN ,GET_ADMIN , GET_PRODUCTS };


// api/authorAPI.js
export const fetchAuthors = async (token) => {
  const response = await axios.get(`${baseURL}/authors`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const deleteAuthor = async (authorId, token) => {
  await axios.delete(`${baseURL}/authors/${authorId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addAuthor = async (formData, token) => {
  await axios.post(`${baseURL}/authors`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProducts = async (formData, token) => {
  await axios.post(`${baseURL}/authors`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

