// src/redux/slices/categorySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axios/axiosInstance';
import { toast } from "sonner";


// دالة مساعدة للتعامل مع الأخطاء
const handleError = (error) => {
  if (error.response && error.response.data) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
  return {
    status: 500,
    message: "An unexpected error occurred: " + error.message,
  };
};

// Action لاستدعاء الفئات
export const getCategories = createAsyncThunk('categories/getCategories', async () => {
  const response = await axiosInstance.get('/category');
  return response.data;
});

// Action لإضافة فئة جديدة
export const addCategory = createAsyncThunk('categories/addCategory', async (category, { dispatch, rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/category', category);
    toast.success("تمت الإضافة بنجاح");
    dispatch(getCategories()); // تحديث البيانات بعد الإضافة
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      toast.error("يرجى تسجيل الدخول.");
    } else if (error.response.status === 426) {
      toast.error('هذا الرابط موجود بالفعل في قاعدة البيانات. يرجى اختيار رابط مختلف.');
    } else {
      toast.error("حدث خطأ أثناء الإضافة، يرجى المحاولة مرة أخرى. في حال تكرر الخطأ، تواصل مع الدعم الفني.");
    }
    return rejectWithValue(handleError(error));
  }
});

// Action لحذف فئة
export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (categoryId, { dispatch, rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/category/${categoryId}`);
    toast.success("تم حذف الفئة بنجاح");
    dispatch(getCategories()); // تحديث البيانات بعد الحذف
    return categoryId;
  } catch (error) {
    if (error.response.status === 401) {
      toast.error("يرجى تسجيل الدخول.");
    } else {
      toast.error("حدث خطأ أثناء الحذف، يرجى المحاولة مرة أخرى. في حال تكرر الخطأ، تواصل مع الدعم الفني.");
    }
    return rejectWithValue(handleError(error));
  }
});

// Action لتحديث بيانات فئة
export const updateCategory = createAsyncThunk('categories/updateCategory', async ({ categoryId, category }, { dispatch, rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`/category/${categoryId}`, category);
    toast.success("تم تحديث الفئة بنجاح");
    dispatch(getCategories()); // تحديث البيانات بعد التحديث
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      toast.error("يرجى تسجيل الدخول.");
    } else if (error.response.status === 426) {
      toast.error('هذا الرابط موجود بالفعل في قاعدة البيانات. يرجى اختيار رابط مختلف.');
    } else {
      toast.error("حدث خطأ أثناء التحديث، يرجى المحاولة مرة أخرى. في حال تكرر الخطأ، تواصل مع الدعم الفني.");
    }
    return rejectWithValue(handleError(error));
  }
});

const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    status: 'idle',
    error: null,
    statusCode: null,
  },
  reducers: {
    clearCategoryError: (state) => {
      state.error = null;
      state.statusCode = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling getCategories
      .addCase(getCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.statusCode = action.payload ? action.payload.status : null;
      })
      // Handling addCategory
      .addCase(addCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories.push(action.payload);
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
        state.statusCode = action.payload ? action.payload.status : null;
      })
      // Handling deleteCategory
      .addCase(deleteCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = state.categories.filter((category) => category.id !== action.payload);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
        state.statusCode = action.payload ? action.payload.status : null;
      })
      // Handling updateCategory
      .addCase(updateCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.categories.findIndex((category) => category.id === action.payload.id);
        if (index !== -1) {
          state.categories[index] = action.payload;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
        state.statusCode = action.payload ? action.payload.status : null;
      });
  },
});

export const { clearCategoryError } = categorySlice.actions;

export default categorySlice.reducer;
