import React from "react";

function Footer() {
  const email = "info@booksbasra.com";

  return (
    <footer
      section-id="18ed00c3-bc7b-451b-8cc9-91f798f3747a"
      style={{
        overflow: "hidden",
        backgroundColor: "#111822",
        color: "#fff",
        padding: "20px",
      }}
    >
      <div className="container-1">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <h5>من نحن</h5>
            <p>
              الحِبرُ: هو المداد الذي يُكتب به والجمع أحبار وحبور وكل ما حَسُنَ من
              خطٍ وكلامٍ وشعر ويُقال الحِبر هو الأثر...
              ونحنُ في حِبر نجمع لكم الحِبر الذي لا يفنى حتى نُبقِي الأثر، ونُخلّد
              الفكرة...
              مكتبة حِبر تسعى لتكون وُجهة ثقافية ومعرفية للقارئ في سلطنة عمان عن
              طريق توفير مختلف تصنيفات الكتب وأنواعها، كما تسعى لنشر المعرفة في
              الوسط الثقافي، لتُشكّل مع قرائِها منظومة ثقافية تعزز من الحراك
              الثقافي في المجتمع...
            </p>
            <p style={{ color: "#616161" }}>
              <i className="far fa-envelope"></i>{" "}
              <span>
                <a href={`mailto:${email}`} style={{ color: "#616161" }}>
                  {email}
                </a>
              </span>
            </p>
            <a href="tel:+9647746299476" style={{ color: "#616161" }}>
              <i className="fas fa-phone"></i> <span>+964 7746299476 </span>
            </a>
            <div style={{ marginTop: "15px" }}>
              <a
                href="https://www.facebook.com/books.basra"
                target="_blank"
                rel="noopener noreferrer"
                className="kalles-social-media__btn facebook cb ttip_nt tooltip_top"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.twitter.com/books.basra"
                target="_blank"
                rel="noopener noreferrer"
                className="kalles-social-media__btn twitter cb ttip_nt tooltip_top"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/books.basra"
                target="_blank"
                rel="noopener noreferrer"
                className="kalles-social-media__btn instagram cb ttip_nt tooltip_top"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6">
            <h5></h5>
            <ul></ul>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6">
            <h5></h5>
            <ul></ul>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <h5>طرق الدفع المتاحة</h5>
            <div className="footer-img">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/b/b7/MasterCard_Logo.svg"
                alt=""
                style={{ padding: "4px" }}
              />
              <img
                src=".\assets\pg\admins\img\visa-3.svg"
                alt="visa circle"
                style={{ padding: "4px" }}
              />
              <img
                src="https://www.zaincash.iq/assets/images/logo.png"
                className="zincash"
                alt="زين كاش - Zin Cash"
                style={{ padding: "4px" }}
              />
              <img
                src="https://media.zid.store/static/cod.png"
                alt=""
                style={{ padding: "4px" }}
              />
            </div>
            <h5>توصيل سريع لجميع محافظات العراق</h5>
            <div className="footer-img">
              <img
                src="https://media.zid.store/static/mandob.png"
                alt=""
                style={{ padding: "4px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="copyrights">
        <div className="container">
          <div className="flex-wrap d-flex justify-between flex-md-nowrap justify-content-center justify-content-md-between">
            <div>
              <p className="copyrights-text">
                {" "}
                <span
                  href="#"
                  target="_blank"
                  className="footer-marketing-link"
                >
                  هذه الصفحة وُجدت بكل حب من أجلكم
                </span>
              </p>
            </div>
            <div className="flex-wrap d-flex justify-content-center vat-commercial-container"></div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
