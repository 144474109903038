const customStyles = {
  control: (provided, state) => ({
    ...provided,
    "&:hover": {
      border: "1px solid var(--sec)",
    },
    border: state.isFocused ? "1px solid var(--sec)" : "1px solid var(--border)",
    // backgroundColor: state.hasValue && "#e8f0fe",
    // borderColor: state.hasValue && "#e8f0fe",
    boxShadow: "none",
    padding: "5px 0px",
    height:"49px"
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  multiValue: (provided) => ({
    ...provided,
    justifyContent: "space-between",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: "5px 6px",
    marginRight: "3px",
    fontWeight: "500",
  }),
  input: (provided) => ({
    ...provided,
    border: "none", 
    boxShadow: "none", 
  }),
};

export default customStyles;