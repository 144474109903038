import React from "react";
import useResize from '../../Hooks/useResize';
const Reviews = () => {
  const [ref, size] = useResize();
  return (
    <div className="px-3 py-6 mx-auto">
      <div className="">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
          مراجعات القراء{" "}
        </h2>
        <div className="flex items-center mt-3">
          <div>
            <div className="flex items-center" title={0.0}>
              <svg
                className="flex-shrink-0 w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              <svg
                className="flex-shrink-0 w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              <svg
                className="flex-shrink-0 w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              <svg
                className="flex-shrink-0 w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              <svg
                className="flex-shrink-0 w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              <span className="rtl:mr-2 ltr:ml-2 text-sm text-gray-900">
                <span className="font-bold">0.0</span>/5
              </span>
            </div>
            <p className="sr-only">0.0 out of 5 stars</p>
          </div>
          <p className="rtl:mr-2 ltr:ml-2 text-sm text-gray-900">
            من 0 مراجعات
          </p>
        </div>
        <div className="mt-6">
          <h3 className="sr-only">تقييمات ومراجعات </h3>
          <dl className="space-y-3">
            <div className="flex items-center text-sm">
              <dt className="flex flex-1 items-center">
                <p className="w-3 font-medium text-gray-900">
                  5<span className="sr-only"> نجوم</span>
                </p>
                <div
                  aria-hidden="true"
                  className="flex flex-1 items-center rtl:mr-1 ltr:ml-1"
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <div className="relative flex-1 rtl:mr-3 ltr:ml-3">
                    <div className="h-3 bg-gray-100 rounded-full border border-gray-200" />
                    <div
                      style={{ width: "calc(0 * 100%)" }}
                      className="absolute inset-y-0 bg-yellow-400 rounded-full border border-yellow-400"
                    />
                  </div>
                </div>
              </dt>
              <dd className="rtl:mr-3 ltr:ml-3 w-10 text-sm tabular-nums text-right text-gray-900">
                0 %
              </dd>
            </div>
            <div className="flex items-center text-sm">
              <dt className="flex flex-1 items-center">
                <p className="w-3 font-medium text-gray-900">
                  4<span className="sr-only"> نجوم</span>
                </p>
                <div
                  aria-hidden="true"
                  className="flex flex-1 items-center rtl:mr-1 ltr:ml-1"
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <div className="relative flex-1 rtl:mr-3 ltr:ml-3">
                    <div className="h-3 bg-gray-100 rounded-full border border-gray-200" />
                    <div
                      style={{ width: "calc(0 * 100%)" }}
                      className="absolute inset-y-0 bg-yellow-400 rounded-full border border-yellow-400"
                    />
                  </div>
                </div>
              </dt>
              <dd className="rtl:mr-3 ltr:ml-3 w-10 text-sm tabular-nums text-right text-gray-900">
                0 %
              </dd>
            </div>
            <div className="flex items-center text-sm">
              <dt className="flex flex-1 items-center">
                <p className="w-3 font-medium text-gray-900">
                  3<span className="sr-only"> نجوم</span>
                </p>
                <div
                  aria-hidden="true"
                  className="flex flex-1 items-center rtl:mr-1 ltr:ml-1"
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <div className="relative flex-1 rtl:mr-3 ltr:ml-3">
                    <div className="h-3 bg-gray-100 rounded-full border border-gray-200" />
                    <div
                      style={{ width: "calc(0 * 100%)" }}
                      className="absolute inset-y-0 bg-yellow-400 rounded-full border border-yellow-400"
                    />
                  </div>
                </div>
              </dt>
              <dd className="rtl:mr-3 ltr:ml-3 w-10 text-sm tabular-nums text-right text-gray-900">
                0 %
              </dd>
            </div>
            <div className="flex items-center text-sm">
              <dt className="flex flex-1 items-center">
                <p className="w-3 font-medium text-gray-900">
                  2<span className="sr-only"> نجوم</span>
                </p>
                <div
                  aria-hidden="true"
                  className="flex flex-1 items-center rtl:mr-1 ltr:ml-1"
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <div className="relative flex-1 rtl:mr-3 ltr:ml-3">
                    <div className="h-3 bg-gray-100 rounded-full border border-gray-200" />
                    <div
                      style={{ width: "calc(0 * 100%)" }}
                      className="absolute inset-y-0 bg-yellow-400 rounded-full border border-yellow-400"
                    />
                  </div>
                </div>
              </dt>
              <dd className="rtl:mr-3 ltr:ml-3 w-10 text-sm tabular-nums text-right text-gray-900">
                0 %
              </dd>
            </div>
            <div className="flex items-center text-sm">
              <dt className="flex flex-1 items-center">
                <p className="w-3 font-medium text-gray-900">
                  1<span className="sr-only"> نجوم</span>
                </p>
                <div
                  aria-hidden="true"
                  className="flex flex-1 items-center rtl:mr-1 ltr:ml-1"
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <div className="relative flex-1 rtl:mr-3 ltr:ml-3">
                    <div className="h-3 bg-gray-100 rounded-full border border-gray-200" />
                    <div
                      style={{ width: "calc(0 * 100%)" }}
                      className="absolute inset-y-0 bg-yellow-400 rounded-full border border-yellow-400"
                    />
                  </div>
                </div>
              </dt>
              <dd className="rtl:mr-3 ltr:ml-3 w-10 text-sm tabular-nums text-right text-gray-900">
                0 %
              </dd>
            </div>
          </dl>
        </div>
        <div className="mt-10">
          <h3 className="text-lg font-extrabold text-gray-900">
            قيِّم الكتاب{" "}
          </h3>
          <p className="mt-1 text-sm text-gray-600">
            شاركنا رأيك وتقييمك للكتاب.{" "}
          </p>
          <div
            // wire:click="confirmAddingReview"
            className="justify-center items-center text-center px-8 py-2 mt-6 w-full text-sm font-medium text-gray-900 bg-white rounded border-gray-300 cursor-pointer hover:bg-gray-50 sm:w-auto lg:w-full"
            style={{border:"1px solid var(--gainsboro)"}}
          >
            أضف مراجعتك{" "}
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="sr-only">أحدث المراجعات </h3>
        <div className="flow-root" id="reviewsList">
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={45}
              height={45}
              className="w-10 h-10 mx-auto"
              viewBox="0 0 45 45"
              stroke={`var(--sec)`}
            >
              <g
                fill="none"
                fillRule="evenodd"
                transform="translate(1 1)"
                strokeWidth={2}
              >
                <circle cx={22} cy={22} r={6} strokeOpacity={0}>
                  <animate
                    attributeName="r"
                    begin="1.5s"
                    dur="3s"
                    values="6;22"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="stroke-opacity"
                    begin="1.5s"
                    dur="3s"
                    values="1;0"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="stroke-width"
                    begin="1.5s"
                    dur="3s"
                    values="2;0"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx={22} cy={22} r={6} strokeOpacity={0}>
                  <animate
                    attributeName="r"
                    begin="3s"
                    dur="3s"
                    values="6;22"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="stroke-opacity"
                    begin="3s"
                    dur="3s"
                    values="1;0"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="stroke-width"
                    begin="3s"
                    dur="3s"
                    values="2;0"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx={22} cy={22} r={8}>
                  <animate
                    attributeName="r"
                    begin="0s"
                    dur="1.5s"
                    values="6;1;2;3;4;5;6"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
            </svg>
          </div>
          <div className="pt-6 -mt-12 divide-y divide-gray-200">
            <div className="my-12">
              <div className="mx-auto max-w-4xl">
                <div className="p-4 bg-yellow-50 rtl:border-r-4 ltr:border-l-4 border-yellow-400">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="w-5 h-5 text-yellow-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="rtl:mr-3 ltr:ml-3">
                      <p className="text-sm text-yellow-700">
                        لا توجد مراجعات بعد.{" "}
                        <span
                          // wire:click="confirmAddingReview"
                          className="font-medium text-yellow-700 underline cursor-pointer hover:text-yellow-600"
                        >
                          كن صاحب أول مراجعة واكتب مراجعتك الآن.{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
