import React from "react";
import { useSelector } from "react-redux";
import ActionButton from "../../../../components/common/button/actionButton";

const CartPOE = ({ className, invoiceId }) => {
  const invoice = useSelector((state) =>
    state.invoice.invoices.find((inv) => inv.id === invoiceId)
  );
  const items = (invoice && invoice.data.items) || [];

  const subtotal = items.reduce(
    (acc, item) => acc + (item.quantity || 0) * (item.price || 0),
    0
  );

  // const tax = subtotal * 0.01;
  const tax = 0;
  const shipping = 0;
  const total = subtotal + tax + shipping;

  return (
    <div className={`${className} border-l border-gray-300 h-full`}>
      <div className="bg-gray-50 flex flex-col h-full">
        <div className="relative h-full grid grid-rows-[1fr,auto] overflow-hidden">
          <div className="overflow-y-auto flex-1">
            <table
              className="w-full"
              style={{ position: "sticky", top: -1 }}
            >
              <thead
                className="sticky top-0 bg-white"
                style={{ borderBottom: "1px solid var(--gainsboro)" }}
              >
                <tr className="text-center px-3 py-2">
                  <th className="text-right">عنوان المنتج</th>
                  <th className=""></th>
                  <th className=""></th>
                  <th className="">المجموع</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td className="px-3 py-2 text-right w-[60%]">
                      {item.name}
                    </td>
                    <td className="px-3 py-2 w-[12%]">{item.price}</td>
                    <td className="px-3 py-2 w-[8%]">{item.quantity}</td>
                    <td className="px-3 py-2 w-[20%]">{item.price * item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            className="amount p-3 grid grid-cols-2 bg-white"
            style={{ borderTop: "1px solid var(--gainsboro)" }}
          >
            <span>المجموع الفرعي</span>
            <span className="text-left">IQD {subtotal.toLocaleString()}</span>
            <span>نسبة الخصم</span>
            <span className="text-left">IQD {tax.toLocaleString()}</span>

            <span className="font-bold mt-1 text-lg">المجموع النهائي</span>
            <span className="font-bold text-left mt-1 text-lg">
              IQD {total.toLocaleString()}
            </span>
            <ActionButton text="اتمام الشراء" className="col-span-2 mt-4 h-[45px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPOE;
