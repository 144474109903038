import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import CustomTable from "../../components/Table/CustomTable";
import DeletePopup from "../../../../components/common/Popup modal/deletePopup";
import Pagination from "../../../../components/common/pagination/pagination";
import { Toaster, toast } from "sonner";
import DynamicFormPopup from "../../../../components/common/Popup modal/FormPopup";
import { fetchAuthors, deleteAuthor } from "../../../../api/api";
const Discounts = () => {
  const navigate = useNavigate();
  const [discounts, setDiscounts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // loading
  const [deleteLoading, setDeleteLoading] = useState(true);
  // const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteUser, setDeleteUser] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fields = [
    { name: 'username', label: 'اسم المستخدم', type: 'text', required: true },
    { name: 'email', label: 'البريد الإلكتروني', type: 'email', required: true },
    { name: 'age', label: 'العمر', type: 'number' },
    {
      name: 'gender',
      label: 'الجنس',
      type: 'select',
      options: [
        { value: 'male', label: 'ذكر' },
        { value: 'female', label: 'أنثى' },
      ],
    },
  ];

  const initialFormState = {
    username: '',
    email: '',
    age: '',
    gender: '',
  };

  const handleFormSubmit = (formData) => {
    setLoading(true);
    // Logic to handle form submission
    console.log(formData);
    setLoading(false);
    setIsModalOpen(false);
  };

  const itemsPerPage = 10;
  const cookies = new Cookies();
  const token = cookies.get("Bearer");
  const columns = ["كود الخصم","تاريخ البداء", "تاريخ الانتهاء", "مدة الخصم"];

  const onPageChange = (page) => setCurrentPage(page);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = discounts
    .slice(indexOfFirstItem, indexOfLastItem)
    .map((discount) => ({
      id: discount.id,
      info: [
        discount.image || "-",
        discount.name || "-",
        // discount.products_count || "-",
        (discount.products_count = "77" || "-"),
      ],
    }));

  const addDis = () => {
    navigate("add");
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (!token || !selectedItem) return;
    setLoading(true);
    try {
      await deleteAuthor(selectedItem, token);
      toast.success("تم حذف المستخدم بنجاح");
      setDiscounts((prevAuthors) =>
        prevAuthors.filter((author) => author.id !== selectedItem)
      );
      setShowModal(false);
      setDeleteUser(true);
      setSelectedItem(null);
    } catch (error) {
      console.error("خطأ في حذف المستخدم:", error);
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      } else {
        toast.error("حدث خطأ أثناء حذف المستخدم");
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    fetch("http://localhost/api/discounts")
      .then((response) => response.json())
      .then((data) => setDiscounts(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>الخصومات</h2>
        <button className="btn-style" onClick={() => setIsModalOpen(true)}>
          إضافة خصم جديد
        </button>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الخصومات</div>
      </div>
      <CustomTable
        columns={columns}
        data={currentData}
        onDelete={handleDelete}
        loading={loading}
      />
      <DeletePopup
        show={showModal}
        setOpenModal={setShowModal}
        onConfirm={confirmDelete}
      />
      {loading ? null : (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(discounts.length / itemsPerPage)}
          onPageChange={onPageChange}
          previousLabel="السابق"
          nextLabel="التالي"
          showIcon
        />
      )}
      <DynamicFormPopup
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="نموذج تسجيل"
        fields={fields}
        onSubmit={handleFormSubmit}
        loading={loading}
        initialFormState={initialFormState}
      />
      <Toaster
        richColors
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
            textAlign: "right",
          },
          className: "my-toast",
        }}
      />
    </div>
  );
};

export default Discounts;
