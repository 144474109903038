import React, { useState } from "react";
import ActionButton from "../../../../components/common/button/actionButton";
import Cookies from "universal-cookie";
import { Toaster, toast } from "sonner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addAuthor } from "../../../../api/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const AddAuthor = () => {
  const animatedComponents = makeAnimated();
  const [editorData, setEditorData] = useState("");
  const [accept, setAccept] = useState(false);
  const [form, setForm] = useState({
    name: "",
    slug: "",
    type: "كاتب",
    status: "Active",
    description: "",
    image: null,
  });
  const [errImage, setErrImage] = useState("");
  const [errSlug, setErrSlug] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(""); // إضافة حالة لعرض رسالة الخطأ
  const cookies = new Cookies();
  const token = cookies.get("Bearer");

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid hsl(0, 0%, 90%)",
      "&:hover": {
        border: "1px solid hsl(0, 0%, 80%)",
      },
      padding: "5px 0px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    multiValue: (provided) => ({
      ...provided,
      justifyContent: "space-between",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "5px 6px",
      marginRight: "3px",
      fontWeight: "500",
    }),
  };

  const generateSlug = (name) => {
    return name
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\u0600-\u06FF\w-]+/g, "");
  };

  const refresh = () => {
    setForm({
      name: "",
      slug: "",
      type: "كاتب",
      status: "Active",
      description: "",
      image: null,
    });
    setEditorData("");
    setImagePreview(null);
    setErrImage("");
    setErrSlug("");
    setAccept(false);
    setErrorMessage(""); // إعادة تعيين رسالة الخطأ
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAccept(true);
    if (!form.name || !form.status || !form.image) {
      toast.error("الرجاء إدخال الاسم واختيار صورة");
      return;
    }
    setLoading(true);
    setSubmitLoading(true);

    const formData = new FormData();
    formData.append("name", form.name);
    formData.append("slug", form.slug);
    formData.append("type", form.type);
    formData.append("status", form.status);
    formData.append("description", form.description);
    formData.append("image", form.image);

    try {
      await addAuthor(formData, token);
      toast.success("تم إضافة المؤلف بنجاح");
      refresh();
    } catch (error) {
      console.error("حدث خطأ أثناء إضافة المؤلف:", error);

      if (error.response) {
        if (error.response.status === 425) {
          setErrorMessage(error.response.data.message);
          setErrImage(error.response.status);
        } else if (error.response.status === 426) {
          setErrorMessage("الرابط موجود بالفعل يرجى اختيار رابط مختلف.");
          setErrSlug(error.response.status);
        } else {
          setErrorMessage("حدث خطأ أثناء إضافة المؤلف");
        }
      } else {
        setErrorMessage("حدث خطأ أثناء إضافة المؤلف");
      }
    } finally {
      setLoading(false);
      setSubmitLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newForm = { ...form, [name]: value };
    if (name === "name") {
      newForm.slug = generateSlug(value);
    }
    setForm(newForm);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        toast.error("حجم الصورة يجب ألا يتجاوز 5 ميجابايت.");
        return;
      }
      setForm((prevForm) => ({
        ...prevForm,
        image: file,
      }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  console.log(form.type);

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>أضافة مؤلف</h2>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الأقسام</div>
        <div className="url-path slash">/</div>
        <div className="url-path">أضافة مؤلف</div>
      </div>
      <div className="container-form">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-3 items-stretch mb-3">
            <div className="">
              <label htmlFor="name">الأسم</label>
              <input
                type="text"
                className="mt-2"
                name="name"
                id="name"
                value={form.name}
                onChange={handleChange}
              />
              {form.name.trim() === "" && accept && (
                <span className="text-red-600 error-message mx-2 mt-1">
                  هذا الحقل مطلوب
                </span>
              )}
            </div>
            <div className="">
              <label htmlFor="slug">الرابط</label>
              <input
                type="text"
                className="mt-2"
                name="slug"
                id="slug"
                value={form.slug}
                onChange={handleChange}
              />
              {form.slug.trim() === "" && accept && (
                <span className="text-red-600 error-message mx-2 mt-1">
                  هذا الحقل مطلوب
                </span>
              )}
              {errSlug === 426 && accept && form.slug.trim() !== "" && (
                <span className="text-red-600 error-message mx-2 mt-1">
                  الرابط موجود بالفعل يرجى اختيار رابط مختلف.
                </span>
              )}
            </div>
            <div className="">
              <label htmlFor="type">نوع المؤلف</label>
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                defaultValue={[]}
                placeholder="حدد نوع مؤلف"
                isMulti={false}
                styles={customStyles}
                options={[
                  { label: "كاتب", value: "كاتب" },
                  { label: "روائي", value: "روائي" },
                  { label: "شاعر", value: "شاعر" },
                  { label: "مترجم", value: "مترجم" },
                ]}
                onChange={(selectedOptions) =>
                  setForm({
                    ...form,
                    type: selectedOptions.value,
                  })
                }
              />
            </div>
            <div className="">
              <label htmlFor="status">الحالة</label>
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                defaultValue={[]}
                placeholder="اختر حالة المؤلف"
                isMulti={false}
                styles={customStyles}
                options={[
                  { label: "Active", value: "Active" },
                  { label: "Inactive", value: "Inactive" },
                ]}
                onChange={(selectedOptions) =>
                  setForm({
                    ...form,
                    status: selectedOptions.value,
                  })
                }
              />
            </div>
            <div
              className="col-span-1 md:col-span-2 p-3"
              style={{
                height: "100px",
                border: "1px solid hsl(0, 0%, 90%)",
                borderRadius: "5px",
              }}
            >
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                />
              )}
            </div>
          </div>

          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorData(data);
              setForm((prevForm) => ({
                ...prevForm,
                description: data,
              }));
            }}
          />
          <div className="flex items-stretch flex-wrap gap-3 mt-3">
            <input
              type="file"
              name="image"
              className="file-btn"
              id="image"
              onChange={handleImageChange}
            />
            <label
              htmlFor="image"
              className="file-btn"
            >
              اختر صورة
            </label>
            <ActionButton className="px-8" text="حفظ" type="submit" loading={submitLoading} />
          </div>
        </form>
      </div>
      <Toaster
        richColors
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
            textAlign: "right",
          },
          className: "my-toast",
        }}
      />
    </div>
  );
};

export default AddAuthor;
