import React, { useContext, useEffect, useState, useMemo } from "react";
import Cookie from "universal-cookie";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { User } from "../../context/UserContext";
import { validateToken } from "../../lib/redux/slices/authSlice";
import LoadingPage from "../../components/common/loding/LoadingPage";

export default function PersistLogin() {
  const { auth, setAuth } = useContext(User);
  const token = auth.token;
  const dispatch = useDispatch();
  const cookie = useMemo(() => new Cookie(), []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // const token = cookie.get("Bearer");

    if (token) {
      dispatch(validateToken()).then((result) => {
        if (validateToken.fulfilled.match(result)) {
          const newToken = result.payload.token;
          const userDetails = result.payload.user;
          const expiresDate = new Date();
          expiresDate.setFullYear(expiresDate.getFullYear() + 1);
          cookie.set("Bearer", newToken, {
            path: "/",
            expires: expiresDate,
            sameSite: "Strict",
          });
          setAuth({ token: newToken, userDetails });
        } else {
          setAuth({ token: null, userDetails: null });
        }
        setLoading(false);
      }).catch((error) => {
        if(error.status === 401){
          cookie.remove("Bearer");
        }
        console.error("Error validating token:", error);
        setAuth({ token: null, userDetails: null });
        setLoading(false);
      });
    } else {
      setAuth({ token: null, userDetails: null });
      setLoading(false);
    }
  }, [dispatch, cookie, setAuth]);

  return loading ? <LoadingPage loading={loading} /> : <Outlet />;
}
