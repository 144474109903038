import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance";
import { toast } from "sonner";

// دالة مساعدة للتعامل مع الأخطاء
const handleError = (error) => {
  if (error.response && error.response.data) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
  return {
    status: 500,
    message: "An unexpected error occurred: " + error.message,
  };
};

// Action لجلب جميع المؤلفين
export const getAuthors = createAsyncThunk("authors/getAuthors", async () => {
  const response = await axiosInstance.get("/authors");
  return response.data;
});

// Action لجلب مؤلف بناءً على السلاگ
export const getAuthorBySlug = createAsyncThunk(
  "authors/getAuthorBySlug",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/authors/${slug}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

// Action لإضافة مؤلف جديد
export const newAuthor = createAsyncThunk(
  "authors/newAuthor",
  async (author, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/authors", author);
      toast.success("تمت الإضافة بنجاح");
      dispatch(getAuthors());
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("يرجى تسجيل الدخول.");
      } else if (error.response.status === 426) {
        toast.error('هذا الرابط موجود بالفعل في قاعدة البيانات. يرجى اختيار رابط مختلف.');
      } else {
        toast.error(
          "حدث خطأ أثناء الإضافة، يرجى المحاولة مرة أخرى. في حال تكرر الخطأ، تواصل مع الدعم الفني."
        );
      }
      return rejectWithValue(handleError(error));
    }
  }
);

// Action لحذف مؤلف
export const deleteAuthor = createAsyncThunk(
  "authors/deleteAuthor",
  async (authorId, { dispatch, rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/authors/${authorId}`);
      dispatch(getAuthors());
      toast.success("تمت الحذف بنجاح");
      return authorId;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("يرجى تسجيل الدخول.");
      } else {
        toast.error(
          "حدث خطأ أثناء الحذف، يرجى المحاولة مرة أخرى. في حال تكرر الخطأ، تواصل مع الدعم الفني."
        );
      }
      return rejectWithValue(handleError(error));
    }
  }
);

// Action لتحديث بيانات مؤلف
export const updateAuthor = createAsyncThunk(
  "authors/updateAuthor",
  async ({ authorId, author }, {dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/authors/${authorId}`, author);
      dispatch(getAuthors());
      toast.success("تمت التحديث بنجاح");
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("يرجى تسجيل الدخول.");
      } else if (error.response.status === 426) {
        toast.error('هذا الرابط موجود بالفعل في قاعدة البيانات. يرجى اختيار رابط مختلف.');
      } else {
        toast.error(
          "حدث خطأ أثناء التحديث، يرجى المحاولة مرة أخرى. في حال تكرر الخطأ، تواصل مع الدعم الفني."
        );
      }
      return rejectWithValue(handleError(error));
    }
  }
);

const authorSlice = createSlice({
  name: "authors",
  initialState: {
    authors: [],
    selectedAuthor: null,
    status: "idle",
    error: null,
    statusCode: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.statusCode = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling getAuthors
      .addCase(getAuthors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAuthors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.authors = action.payload;
      })
      .addCase(getAuthors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
        state.statusCode = action.payload.status;
      })
      // Handling newAuthor
      .addCase(newAuthor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(newAuthor.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.authors.push(action.payload);
      })
      .addCase(newAuthor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
        state.statusCode = action.payload.status;
      })
      // Handling deleteAuthor
      .addCase(deleteAuthor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAuthor.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.authors = state.authors.filter(
          (author) => author.id !== action.payload
        );
      })
      .addCase(deleteAuthor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
        state.statusCode = action.payload.status;
      })
      // Handling updateAuthor
      .addCase(updateAuthor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAuthor.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.authors.findIndex(
          (author) => author.id === action.payload.id
        );
        if (index !== -1) {
          state.authors[index] = action.payload;
        }
      })
      .addCase(updateAuthor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
        state.statusCode = action.payload.status;
      })
      // Handling getAuthorBySlug
      .addCase(getAuthorBySlug.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAuthorBySlug.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedAuthor = action.payload;
      })
      .addCase(getAuthorBySlug.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
        state.statusCode = action.payload.status;
      });
  },
});

export const { clearError } = authorSlice.actions;

export default authorSlice.reducer;
