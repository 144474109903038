// src/pages/Setting.jsx
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSiteName,
  setLogo,
  setTheme,
  setSiteLanguage,
  setCurrencies,
  setSiteStatus,
  setSiteClosedMessage,
  setClosedSections,
  setSocialLinks,
  setSEO,
  setGoogleAnalyticsCode,
  setNotifications,
  setBackupSchedule,
} from "../../../../lib/redux/slices/settingsSlice";
import AccessControl from "./AccessControl";

const Setting = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const [activeTab, setActiveTab] = useState("general");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (setter, value) => {
    dispatch(setter(value));
  };

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>إعدادات الموقع</h2>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">إعدادات الموقع</div>
      </div>
      <div className="container-form">
        <div className="h-full">
          <div className="flex mb-6">
            <button
              onClick={() => handleTabChange("general")}
              className={`tab-button ${
                activeTab === "general" ? "active" : ""
              }`}
            >
              الإعدادات العامة
            </button>
            <button
              onClick={() => handleTabChange("access")}
              className={`tab-button ${activeTab === "access" ? "active" : ""}`}
            >
              إدارة الوصول
            </button>
            <button
              onClick={() => handleTabChange("policies")}
              className={`tab-button ${
                activeTab === "policies" ? "active" : ""
              }`}
            >
              السياسات
            </button>
            <button
              onClick={() => handleTabChange("security")}
              className={`tab-button ${
                activeTab === "security" ? "active" : ""
              }`}
            >
              الأمان
            </button>
            <button
              onClick={() => handleTabChange("social")}
              className={`tab-button ${activeTab === "social" ? "active" : ""}`}
            >
              وسائل التواصل
            </button>
            <button
              onClick={() => handleTabChange("seo")}
              className={`tab-button ${activeTab === "seo" ? "active" : ""}`}
            >
              السيو والتحليلات
            </button>
            <button
              onClick={() => handleTabChange("notifications")}
              className={`tab-button ${
                activeTab === "notifications" ? "active" : ""
              }`}
            >
              التنبيهات والإشعارات
            </button>
            <button
              onClick={() => handleTabChange("backup")}
              className={`tab-button ${activeTab === "backup" ? "active" : ""}`}
            >
              النسخ الاحتياطي
            </button>
          </div>

          {/* محتوى التبويبات */}
          <div className="tab-content bg-[var(--bg)] p-4 border rounded-[5px]">
            {activeTab === "general" && (
              <div>
                <h2 className="text-2xl font-semibold mb-3">
                  الإعدادات العامة
                </h2>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    اسم الموقع
                  </label>
                  <input
                    type="text"
                    value={settings.siteName}
                    onChange={(e) =>
                      handleInputChange(setSiteName, e.target.value)
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="أدخل اسم الموقع"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    شعار الموقع
                  </label>
                  <input
                    type="file"
                    onChange={(e) =>
                      handleInputChange(
                        setLogo,
                        URL.createObjectURL(e.target.files[0])
                      )
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  />
                  {settings.logo && (
                    <img
                      src={settings.logo}
                      alt="Site Logo"
                      className="mt-3 h-16 w-auto"
                    />
                  )}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    الفيم العام
                  </label>
                  <select
                    value={settings.theme}
                    onChange={(e) =>
                      handleInputChange(setTheme, e.target.value)
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  >
                    <option value="light">فاتح</option>
                    <option value="dark">داكن</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    لغة الموقع الأساسية
                  </label>
                  <select
                    value={settings.siteLanguage}
                    onChange={(e) =>
                      handleInputChange(setSiteLanguage, e.target.value)
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  >
                    <option value="ar">العربية</option>
                    <option value="en">الإنجليزية</option>
                  </select>
                </div>
              </div>
            )}
            {activeTab === "access" && (
             <AccessControl />
            )}
            {activeTab === "policies" && (
              <div>
                <h2 className="text-2xl font-semibold mb-3">السياسات</h2>
                <p>يمكنك إدارة سياسات الموقع هنا.</p>
                {/* Add your policies management content here */}
              </div>
            )}
            {activeTab === "security" && (
              <div>
                <h2 className="text-2xl font-semibold mb-3">الأمان</h2>
                <p>يمكنك إدارة إعدادات الأمان هنا.</p>
                {/* Add your security management content here */}
              </div>
            )}
            {activeTab === "social" && (
              <div>
                <h2 className="text-2xl font-semibold mb-3">
                  وسائل التواصل الاجتماعي
                </h2>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    رابط الفيسبوك
                  </label>
                  <input
                    type="text"
                    value={settings.socialLinks.facebook}
                    onChange={(e) =>
                      handleInputChange(setSocialLinks, {
                        facebook: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="أدخل رابط الفيسبوك"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    رابط تويتر
                  </label>
                  <input
                    type="text"
                    value={settings.socialLinks.twitter}
                    onChange={(e) =>
                      handleInputChange(setSocialLinks, {
                        twitter: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="أدخل رابط تويتر"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    رابط انستجرام
                  </label>
                  <input
                    type="text"
                    value={settings.socialLinks.instagram}
                    onChange={(e) =>
                      handleInputChange(setSocialLinks, {
                        instagram: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="أدخل رابط انستجرام"
                  />
                </div>
              </div>
            )}
            {activeTab === "seo" && (
              <div>
                <h2 className="text-2xl font-semibold mb-3">
                  السيو والتحليلات
                </h2>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    عنوان الموقع
                  </label>
                  <input
                    type="text"
                    value={settings.seo.title}
                    onChange={(e) =>
                      handleInputChange(setSEO, { title: e.target.value })
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="أدخل عنوان الموقع"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    وصف الموقع
                  </label>
                  <textarea
                    value={settings.seo.description}
                    onChange={(e) =>
                      handleInputChange(setSEO, { description: e.target.value })
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="أدخل وصف الموقع"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    الكلمات المفتاحية
                  </label>
                  <input
                    type="text"
                    value={settings.seo.keywords}
                    onChange={(e) =>
                      handleInputChange(setSEO, { keywords: e.target.value })
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="أدخل الكلمات المفتاحية"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    كود Google Analytics
                  </label>
                  <input
                    type="text"
                    value={settings.googleAnalyticsCode}
                    onChange={(e) =>
                      handleInputChange(setGoogleAnalyticsCode, e.target.value)
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="أدخل كود Google Analytics"
                  />
                </div>
              </div>
            )}
            {activeTab === "notifications" && (
              <div>
                <h2 className="text-2xl font-semibold mb-3">
                  التنبيهات والإشعارات
                </h2>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    إشعارات البريد الإلكتروني
                  </label>
                  <input
                    type="checkbox"
                    checked={settings.notifications.email}
                    onChange={(e) =>
                      handleInputChange(setNotifications, {
                        email: e.target.checked,
                      })
                    }
                    className="mr-2"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    إشعارات الدفع
                  </label>
                  <input
                    type="checkbox"
                    checked={settings.notifications.push}
                    onChange={(e) =>
                      handleInputChange(setNotifications, {
                        push: e.target.checked,
                      })
                    }
                    className="mr-2"
                  />
                </div>
              </div>
            )}
            {activeTab === "backup" && (
              <div>
                <h2 className="text-2xl font-semibold mb-3">النسخ الاحتياطي</h2>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    جدول النسخ الاحتياطي
                  </label>
                  <select
                    value={settings.backupSchedule}
                    onChange={(e) =>
                      handleInputChange(setBackupSchedule, e.target.value)
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  >
                    <option value="daily">يومي</option>
                    <option value="weekly">أسبوعي</option>
                    <option value="monthly">شهري</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Setting;
