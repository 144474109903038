import React from "react";
import CloseButton from "../../../components/common/button/CloseButton";
import {
  removeFromCart,
  updateQuantity,
  showCart,
} from "../../../lib/redux/slices/cartSlice";
import { useDispatch } from "react-redux";
import { Minus, Plus } from "../../../assets/icons/CustomIcons";

const CartItem = ({ item }) => {
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(removeFromCart(item.product.slug))
      .unwrap()
      .then(() => {
        console.log("Product removed successfully");
        dispatch(showCart()); // إعادة جلب بيانات السلة
      })
      .catch((error) => {
        console.error("Failed to remove product:", error);
      });
  };

  const handleUpdateQuantity = (type, quantity) => {
    dispatch(updateQuantity({ itemId: item.id, quantity, type }))
      .unwrap()
      .then(() => {
        console.log("Quantity updated successfully");
        dispatch(showCart()); // إعادة جلب بيانات السلة
      })
      .catch((error) => {
        console.error("Failed to update quantity:", error);
      });
  };

  return (
    <div
      key={item.id}
      className="relative py-3 px-1 flex gap-3 bg-white border-y justify-start text-xl items-center"
    >
      <img
        src={item.product.images[0].image}
        className="w-20 h-20 object-contain"
        alt={item.product.product_name || "Product Image"}
      />
      <div className="detail flex-grow">
        <p className="text-base text-gray-700">
          {item.product.authors &&
            item.product.authors[0] &&
            item.product.authors[0].name}
        </p>
        <h4 className="truncate mb-2 text-lg mt-[-4px]">
          {item.product.product_name}
        </h4>

        <div className="wrapper flex gap-2">
          <div className="product-qty flex items-center gap-2">
            <button
              onClick={() => handleUpdateQuantity("decrease", 1)}
              className="inline-flex items-center bg-transparent p-1 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              style={{ borderRadius: "5px" }}
            >
              <Minus />
            </button>
            <span>{item.quantity}</span>
            <button
              onClick={() => handleUpdateQuantity("increase", 1)}
              className="inline-flex items-center bg-transparent p-1 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              style={{ borderRadius: "5px" }}
            >
              <Plus />
            </button>
          </div>
          <div className="price flex items-center">
            <span className="ml-1">IQD</span>
            <span>{item.product.price.toLocaleString()}</span>
          </div>
        </div>
      </div>
      <CloseButton onClick={handleRemove} className="absolute top-2 left-3" />
    </div>
  );
};

export default CartItem;
