import { useEffect, useRef } from 'react';

const useClickOutside = (callback, initialOpen = false) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(false);
      }
    };

    if (initialOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, initialOpen]);

  return ref;
};

export default useClickOutside;
