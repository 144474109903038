import React from "react";
import PropTypes from "prop-types";
import { text } from "@fortawesome/fontawesome-svg-core";

const CustomItems = ({
  className,
  svgIcon,
  classSVG,
  text,
  onClick,
  badge,
}) => {
  return (
    <div className={`flex gap-3 ${className}`} onClick={onClick}>
      {svgIcon && <span className={classSVG}>{svgIcon}</span>}
      <span>{text}</span>
      {badge && <span className="bg-gray-700 py-0.5 p-1.5 text-xs rounded-full">{badge}</span>}
    </div>
  );
};

CustomItems.propTypes = {
  svgIcon: PropTypes.element,
  text: PropTypes.string,
  onClick: PropTypes.func,
  badge: PropTypes.number,
};


export default CustomItems;
