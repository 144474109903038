import React from "react";
import { icons } from "../icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgressWithLabel from "../../components/progress/CircularProgressWithLabel";

const FilePreview = ({ fileLoading, file, index, removeFile }) => {
  function formatFileSize(size) {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  }
  return (
    <div
      className="relative"
      style={{
        border: "1px solid var(--gainsboro)",
        borderRadius: "3px",
        width: "150px",
        height: "200px",
        overflow:"hidden"
      }}
    >
      <div className="flex items-center justify-center w-full h-full border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
        <div role="status">
          {fileLoading[index] ? (
          <div >
            <div className="flex justify-center items-center mb-3">
              <CircularProgressWithLabel
                className="justify-center items-center"
                value={50}
              />
            </div>
            <span
            dir="ltr"
              className=" text-slate-950"
            >
              {formatFileSize(file.size)}
            </span>
          </div>
          ) : (
            <img
              src={URL.createObjectURL(file)}
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
              className="w-full h-full"
              alt={`${index + 1}`}
            />
          )}
        </div>
      </div>
      <FontAwesomeIcon
        style={{ bottom: "90%", left: "86%" }}
        className="cursor-pointer text-xl text-red-600 absolute"
        icon={icons.xmark}
        onClick={() => removeFile(index)}
      />
    </div>
  );
};

export default FilePreview;
