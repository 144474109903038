import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartById } from "../../../../lib/redux/slices/cartSlice";
import CustomTable from "../../components/Table/CustomTable";
import Pagination from "../../../../components/common/pagination/pagination";
import profile from "../../../../assets/images/2013-12-09_00292.jpg";
const CartDetails = () => {
  const dispatch = useDispatch();
  const { cart, loading, error } = useSelector((state) => state.cart);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const itemsPerPage = 10;

  useEffect(() => {
    if (id) {
      dispatch(fetchCartById(id));
    }
  }, [dispatch, id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!cart || !cart.items || !cart.user) {
    return <div>No data available</div>;
  }

  // دالة لإضافة الفاصلة إلى السعر
function formatCurrency(value) {
  return value.toLocaleString();
}

  const columns = ["المنتج","سعر المنتج", "الكمية", "المجموع"];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentData = Array.isArray(cart.items)
    ? cart.items.slice(indexOfFirstItem, indexOfLastItem).map((cartItem) => ({
        id: cartItem.id,
        info: [
          cartItem.product.product_name || "-",
          cartItem.product.price || "-",
          cartItem.quantity,
          formatCurrency(cartItem.quantity * cartItem.product.price),
        ],
      }))
    : [];

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>تفاصيل السلة</h2>
      </div>

      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">السلة</div>
        <div className="url-path slash">/</div>
        <div className="url-path">تفاصيل السلة</div>
      </div>

      <div>
        <div
          className="flex w-full mb-6 items-center bg-[#f9f9f9] p-4"
          style={{ border: "1px solid hsl(0, 0%, 90%)", borderRadius: "5px" }}
        >
          <span className="rounded-full overflow-hidden h-14 w-14">
            {true ? (
              <img
                className="object-cover h-full w-full"
                src={profile}
                alt=""
              ></img>
            ) : (
              <svg
                className="absolute w-12 h-12 text-gray-400 -left-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </span>
          <div className="flex w-full">
            <div className="px-5 py-3 flex-1">
              <h3 className="font-bold text-lg">{cart.user.username}</h3>
              <p>{cart.user.name}</p>
            </div>
            <div
              className="px-5 py-3 flex-1"
              style={{
                borderRight: "1px solid hsl(0, 0%, 90%)",
                borderLeft: "1px solid hsl(0, 0%, 90%)",
              }}
            >
              <h3 className="font-bold text-lg">{cart.items.length}</h3>
              <p>إجمالي المنتجات</p>
            </div>
            <div
              className="px-5 py-3 flex-1"
              style={{
                borderLeft: "1px solid hsl(0, 0%, 90%)",
              }}
            >
              <h3 className="font-bold text-lg">{cart.total_products}</h3>
              <p>إجمالي العدد</p>
            </div>
            <div className="px-5 py-3 flex-1">
              <h3 className="font-bold text-lg">{formatCurrency(cart.total_amount)}</h3>
              <p>المبلغ الإجمالي</p>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={currentData}
          loading={loading}
          Notfound="لا توجد بيانات"
          showExtraButton={true}
          control={false}
          extraButtonUrl="show"
          classExtraButton="hover:text-slate-50"
        />
        {/* {currentPage === 1 ? null : ( */}
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(cart.items.length / itemsPerPage)}
          onPageChange={onPageChange}
          previousLabel="السابق"
          nextLabel="التالي"
          showIcon={true}
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default CartDetails;
