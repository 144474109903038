// redux/slices/resetSlice.js
import { createSlice } from "@reduxjs/toolkit";

const resetSlice = createSlice({
  name: 'reset',
  initialState: { reset: false },
  reducers: {
    triggerReset: (state) => {
      state.reset = true;
    },
    clearReset: (state) => {
      state.reset = false;
    },
  },
});

export const { triggerReset, clearReset } = resetSlice.actions;
export default resetSlice.reducer;
