// icons.js
import {
  faHome,
  faUser,
  faCog,
  faChartPie,
  faBox,
  faCashRegister,
  faPuzzlePiece,
  faBasketShopping,
  faRectangleAd,
  faTags,
  faUsers,
  faUserTie,
  faGear,
  faArrowRightFromBracket,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import InfoIcon from "./InfoIcon";
// export const icons = {
//   home: faHome,
//   user: faUser,
//   users: faUsers,
//   userTie: faUserTie,
//   seating: faGear,
//   Exit: faArrowRightFromBracket,
//   cog: faCog,
//   chartPie: faChartPie,
//   faBox: faBox,
//   cashRegister: faCashRegister,
//   puzzlePiece: faPuzzlePiece,
//   basketShopping: faBasketShopping,
//   rectangleAd: faRectangleAd,
//   tags: faTags,
//   infoCircle: faInfoCircle,
//   xmark: faCircleXmark,
//   InfoIcon: InfoIcon,
// };

export const icons = {
  home: faHome,
  user: faUser,
  users: faUsers,
  userTie: faUserTie,
  seating: faGear,
  Exit: faArrowRightFromBracket,
  cog: faCog,
  chartPie: faChartPie,
  faBox: faBox,
  cashRegister: faCashRegister,
  faPuzzlePiece: faPuzzlePiece,
  BasketShopping: faBasketShopping,
  RectangleAd: faRectangleAd,
  Tags: faTags,
  xmark: faCircleXmark,
};
