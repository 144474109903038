import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../../../components/common/button/actionButton";
import Cookies from "universal-cookie";
import axios from "axios";
import { Toaster, toast } from "sonner";

const AddUser = () => {
  const [success, setSuccess] = useState(false);
  const [accept, setAccept] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [usernameErr, setUsernameErr] = useState(false);
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // cookie
  const cookie = new Cookies();
  const token = cookie.get("Bearer");

  const [form, setForm] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    address: "",
    type: "User",
    status: "Active",
    permissions: "",
    gender: "حدد الجنس",
    password: "",
    repeat: "",
  });

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    try {
      const response = await axios.post(
        `http://127.0.0.1:8000/api/addUser`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsernameErr(false);
      setEmailErr(false);

      if (response.status === 200) {
        setForm({
          name: "",
          username: "",
          email: "",
          phone: "",
          address: "",
          type: "User",
          gender: "حدد الجنس",
          permissions: "",
          password: "",
        });
        toast.success("تم اضافة مستخدم جديد بنجاح");
        setAccept(false);
        setSuccess(true);
      }
    } catch (err) {
      setAccept(true);

      if (
        err.response.data.message &&
        err.response.data.message.includes("email")
      ) {
        setEmailErr(true);
      } else setEmailErr(false);

      console.error("خطأ أثناء التسجيل:", err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="content-bar" style={{ position: "relative" }}>
      <div className="title-bar">
        <h2>أضفافة مستخدم جديد</h2>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">المستخدمين</div>
        <div className="url-path slash">/</div>
        <div className="url-path">أضفافة مستخدم جديد</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div className="">
            <label htmlFor="name">الأسم</label>
            <input
              type="text"
              className="input-box"
              name="name"
              id="name"
              value={form.name}
              onChange={handleChange}
            />
            {form.name.trim() === "" && accept && (
              <span className="text-red-600 error-message">
                هذا الحقل مطلوب
              </span>
            )}
          </div>

          <div className="">
            <label htmlFor="username">أسم المستخدم</label>
            <input
              type="text"
              className="input-box"
              name="username"
              id="username"
              value={form.username}
              onChange={handleChange}
            />
            {form.username === "" && accept && (
              <span className="text-red-600">هذا الحقل مطلوب</span>
            )}

            {form.username.length < 5 && accept && form.username !== "" && (
              <span className="text-red-600">
                يجب أن يكون اسم المستخدم على الأقل 5 أحرف
              </span>
            )}

            {usernameErr && accept && (
              <span className="text-red-600">اسم المستخدم مستخدم بالفعل.</span>
            )}
          </div>

          <div className="">
            <label htmlFor="email">الأميل</label>
            <input
              type="email"
              className="input-box"
              name="email"
              id="email"
              value={form.email}
              onChange={handleChange}
            />
            {form.email === "" && accept && (
              <span className="text-red-600">هذا الحقل مطلوب</span>
            )}
            {emailErr && accept && form.email !== "" && (
              <span className="text-red-600">
                البريد الإلكتروني مستخدم بالفعل.
              </span>
            )}
          </div>
          <div className="">
            <label htmlFor="phone">رقم الهاتف</label>
            <input
              type="phone"
              className="input-box"
              name="phone"
              id="phone"
              value={form.phone}
              onChange={handleChange}
            />
          </div>
          <div className="">
            <label htmlFor="address">العنوان</label>
            <input
              type="text"
              className="input-box"
              name="address"
              id="address"
              value={form.address}
              onChange={handleChange}
            />
          </div>
          <div className="">
            <label htmlFor="gender">الحنس</label>
            <select
              name="gender"
              className="input-box"
              value={form.gender}
              onChange={handleChange}
            >
              <option disabled>حدد الجنس</option>
              <option value="Male">ذكر</option>
              <option value="Female">أنثى</option>
            </select>
          </div>
          <div className="">
            <label htmlFor="type">نوع المستخدم</label>
            <select
              name="type"
              className="input-box"
              value={form.type}
              onChange={handleChange}
            >
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </select>
          </div>

          <div className="">
            <label htmlFor="permissions">الصلاحيات</label>
            <select
              name="permissions"
              className="input-box"
              value={form.permissions}
              onChange={handleChange}
              disabled={form.type !== "Admin"}
            >
              <option value="Admin">ادارة الطلبات</option>
              <option value="Admin">ادراة المستخدمين</option>
              <option value="Admin">أضافة المنتجات</option>
              <option value="Admin">ادارة عامة</option>
            </select>
          </div>
          <div className="">
            <label htmlFor="status">الحالة</label>
            <select
              name="permissions"
              className="input-box"
              value={form.status}
              onChange={handleChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="">
            <label htmlFor="password">كلمة المرور</label>
            <input
              type="password"
              className="input-box"
              name="password"
              id="password"
              value={form.password}
              onChange={handleChange}
            />
            {form.password.length < 8 && accept && (
              <span className="text-red-600">كلمة السر قصيرة</span>
            )}
          </div>
        </div>
        <ActionButton text="أضافة" type="submit" loading={loading} />
        {err !== "" && <span className="m-0 text-center">{err}</span>}
      </form>
      <Toaster
        richColors
        // position="bottom-right"
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
          },
          className: "my-toast",
        }}
      />
      <button
        onClick={() =>
          toast("تم إنشاء الحدث", {
            action: {
              label: "تراجع",
              onClick: () => console.log("تراجع"),
            },
          })
        }
      >
        إعطائي رسالة
      </button>
    </div>
  );
};

export default AddUser;
