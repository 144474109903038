import React, { useEffect, useState } from "react";
import ProductSlider from "../../../components/common/sliders/ProductSlider";
import axios from "axios";
import ProductCard from "../../../components/common/card/ProductCard";

const Books = () => {
  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("https://api.kitabyy.com/products");
        setProducts(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("There was an error fetching the products!", error);
      }
    };

    fetchProducts();
  }, []);

  // useEffect(() => {
  //   // const response = await axios.get("https://api.kitabyy.com/products");
  //   axios.get("https://api.kitabyy.com/product/العادات-العصبية");
  // }, []);

  return (
    <div className="block sm:flex p-4">
      <div className="w-auto mx-auto grid grid-cols-6 gap-x-3 gap-y-6">
        {products.length > 0 ? (
          products.map((product) => <ProductCard product={product} />)
        ) : (
          <p>no</p>
        )}
      </div>
    </div>
  );
};

export default Books;
