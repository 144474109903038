import React, { useEffect, useState } from "react";
import SidebarComponent from "../../../../components/Sidebar/SidebarComponent";
import SideContent from "./SideContent";

const Sidebar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024); 
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    isSmallScreen ? (
      <SidebarComponent className="top-[75px]" id="ControlPanelSide">
        <SideContent className="w-full" />
      </SidebarComponent>
    ) : (
      <SideContent className="w-[300px]" />
    )
  );
};

export default Sidebar;
