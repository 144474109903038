import React, { useState, useRef, useEffect } from "react";
import axiosInstance from "../../../lib/axios/axiosInstance";

const  SearchBar = ({ className }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const inputRef = useRef(null); // استخدام useRef للإشارة إلى صندوق الإدخال

  const handleSearch = async (e) => {
    setQuery(e.target.value);

    if (e.target.value.length > 2) {
      try {
        // const response = await axiosInstance.get(
        //   `/search?query=${e.target.value}`
        // );
        const response = await axiosInstance.get(`/search`, {
          params: { query }
        });
        setResults(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
        // التعامل مع الأخطاء هنا
      }
    } else {
      setResults([]);
    }
  };

  const handleBlur = () => {
    setResults([]); // إخفاء النتائج عند فقدان الفوكس
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        handleBlur(); // إخفاء النتائج عند النقر خارج صندوق الإدخال
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  return (
    <div
      ref={inputRef} // إضافة المرجع إلى العنصر
      className={`relative mx-3 flex border bg-[var(--bg)] focus-within:border-[var(--sec)] ${className}`}
      style={{
        border: "1px solid hsl(0, 0%, 90%)",
        borderRadius: "5px",
        padding: "0 15px 0 0",
        fontSize: "15px",
      }}
    >
      <div className="relative flex justify-center items-center">
        <input
          type="text"
          value={query}
          onChange={handleSearch}
          onBlur={handleBlur} // إضافة حدث onBlur
          placeholder="عنوان الكتاب، المؤلف، دار النشر..."
          className="text-xs py-1 pl-3 pr-10 flex-1 focus-border-none h-full"
          style={{ padding: "8px", fontSize: "1rem", backgroundColor:"transparent" }}
        />
        <svg
          className="pointer-events-none absolute left-4 h-6 w-6 fill-slate-400"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20.47 21.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-9.97-4.28a6.75 6.75 0 0 1-6.75-6.75h-1.5a8.25 8.25 0 0 0 8.25 8.25v-1.5ZM3.75 10.5a6.75 6.75 0 0 1 6.75-6.75v-1.5a8.25 8.25 0 0 0-8.25 8.25h1.5Zm6.75-6.75a6.75 6.75 0 0 1 6.75 6.75h1.5a8.25 8.25 0 0 0-8.25-8.25v1.5Zm11.03 16.72-5.196-5.197-1.061 1.06 5.197 5.197 1.06-1.06Zm-4.28-9.97c0 1.864-.755 3.55-1.977 4.773l1.06 1.06A8.226 8.226 0 0 0 18.75 10.5h-1.5Zm-1.977 4.773A6.727 6.727 0 0 1 10.5 17.25v1.5a8.226 8.226 0 0 0 5.834-2.416l-1.061-1.061Z" />
        </svg>
      </div>
      {results.length > 0 && (
        <ul
          className="absolute right-0 left-0 top-[120%] py-2 rounded border bg-white"
          style={{ zIndex: 999 }}
        >
          {results.map((result, index) => (
            <li key={index} className="px-4 py-3 hover:bg-[var(--bg)]">
              {result.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
