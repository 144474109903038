import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import ProductSlider from "../../../../components/common/sliders/ProductSlider";
import {
  fetchSliders,
  addSlider,
  updateSlider,
  deleteSlider,
} from "../../../../lib/redux/slices/slidersSlice";
import ActionButton from "../../../../components/common/button/actionButton";
import ModalForm from "../../../../components/common/Popup modal/ModalForm";
import {
  closeModal,
  openModal,
  toggleModal,
} from "../../../../lib/redux/slices/modalSlice";
import { getCategories } from "../../../../lib/redux/slices/categorySlice";
import {
  DeleteIcon,
  IconEdit,
  ListVertical,
} from "../../../../assets/icons/CustomIcons";
import useClickOutside from "../../../../Hooks/useClickOutside";
import SpinnerCircle from "../../../../components/common/Spinner/SpinnerCircle";
import DeletePopup from "../../../../components/common/Popup modal/deletePopup";

const Sliders = () => {
  const idS = "slidersID";
  const idS2 = "Control";
  const DeleteID = "DeleteID";
  const EditID = "OpenEditModal";

  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState();
  const { sliders, loading, status } = useSelector((state) => state.sliders);

  const [form, setForm] = useState({
    title: "",
    category_id: "",
  });

  const openModals = useSelector((state) => state.modal.openModals);
  const isOpen = (id) => openModals[id] || false;

  const { categories, categoriesStatus } = useSelector(
    (state) => state.category
  );

  const handleClose = (id) => {
    dispatch(closeModal(id));
  };

  const handleOpenModal = (id) => {
    dispatch(openModal({ id }));
    setForm({
      title: "",
      category_id: "",
    });
  };

  const handletoggleModal = (id) => {
    dispatch(toggleModal(id));
  };

  // جلب البيانات
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchSliders());
    }
  }, [status, dispatch]);

  // الاضافة
  const categoryOptions = categories.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  const fields = [
    {
      name: "title",
      label: "الأسم",
      type: "text",
      required: true,
      disabled: false,
    },
    {
      label: "التصنيف",
      name: "category_id",
      type: "select",
      required: true,
      options: categoryOptions,
      disabled: false,
    },
  ];

  useEffect(() => {
    dispatch(getCategories());
  }, [categoriesStatus, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  // التعديل
  const handleOpenEditModal = (slider) => {
    setForm({
      id: slider.id,
      title: slider.title,
      category_id: slider.category_id,
    });
    dispatch(toggleModal(EditID));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.title || !form.category_id) {
      toast.error("جميع الحقول مطلوبة.");
      return;
    }

    if (form.id) {
      await dispatch(updateSlider(form));
      dispatch(toggleModal(EditID));
    } else {
      await dispatch(addSlider(form));
      dispatch(toggleModal(idS));
    }
    setForm({
      title: "",
      category_id: "",
    });
  };
  const droRef = useClickOutside(() => handleClose(idS2), isOpen(idS2));

  // الحذف
  const handleDelete = (item) => {
    setSelectedItem(item);
    dispatch(toggleModal(DeleteID));
  };

  const confirmDelete = () => {
    if (!selectedItem) return;
    dispatch(deleteSlider(selectedItem));
  };

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>سلايدر</h2>
        <ActionButton
          className="bg-[var(--sec)]"
          text="اضافة سلايدر جديد"
          onClick={() => handleOpenModal(idS)}
        />
      </div>

      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">المتجر</div>
        <div className="url-path slash">/</div>
        <div className="url-path">سلايدر</div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <SpinnerCircle classSvg="h-16 w-16" />
        </div>
      ) : sliders.length > 0 ? (
        sliders.map((slider) => (
          <div
            key={slider.id}
            className="mb-8 p-4 border border-gray-300 rounded-lg"
          >
            <div className="mt-4">
              {slider.category?.products &&
              slider.category.products.length > 0 ? (
                <>
                  <div className="flex items-center justify-between">
                    <h1 className="text-2xl text-bold">
                      {slider && slider.title}
                    </h1>
                    <div className="relative" ref={droRef}>
                      <ListVertical
                        className="h-8 text-gray-700"
                        onClick={() => handletoggleModal(idS2)}
                      />
                      {isOpen(idS2) && (
                        <div
                          className="absolute left-0 mt-2 w-48 bg-white border z-50 rounded shadow py-2"
                          style={{ border: "1px solid hsl(0, 0%, 90%)" }}
                        >
                          <div
                            className="flex items-center gap-2 px-4 py-2 hover:text-[#f6b207] text-gray-700 hover:bg-[#f1f5f9]"
                            onClick={() => handleOpenEditModal(slider)}
                          >
                            <IconEdit className="h-5 " />
                            تعديل
                          </div>
                          <div
                            className="px-4 py-2 flex items-center gap-2 text-gray-700 hover:text-red-500 hover:bg-[#f1f5f9]"
                            onClick={() => handleDelete(slider.id)}
                          >
                            <DeleteIcon className="h-5 " />
                            حذف
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <ProductSlider
                    navBtn={false}
                    label={slider.title}
                    products={slider.category.products}
                  />
                </>
              ) : (
                <div className="flex justify-center items-center">
                  <p className="text-gray-500">لا توجد منتجات في هذا سلايدر.</p>
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center">
          <p className="text-gray-500">لا توجد سلايدرات.</p>
        </div>
      )}

      <DeletePopup
        show={isOpen(DeleteID)}
        setOpenModal={() => handleClose(DeleteID)}
        onConfirm={confirmDelete}
      />

      <ModalForm
        open={isOpen(idS) || isOpen(EditID)}
        onClose={() => {
          handleClose(idS);
          handleClose(EditID);
        }}
        formTitle={form.id ? "تعديل السلايدر" : "إضافة سلايدر جديد"}
        handleSubmit={handleSubmit}
        fields={fields}
        loading={loading}
        btnText={form.id ? "تحديث" : "أضافة"}
      />
    </div>
  );
};

export default Sliders;
