import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthors,
  clearError as clearAuthorError,
} from "../../../../lib/redux/slices/authorSlice";
import ShowCard from "./showCard";
import { useNavigate } from "react-router-dom";

const ShowAuthors = () => {
    const nav = useNavigate();
  const dispatch = useDispatch();
  const {
    authors,
    error: authorsError,
    loading,
  } = useSelector((state) => state.authors);

  useEffect(() => {
    dispatch(getAuthors());
    return () => {
      dispatch(clearAuthorError());
    };
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (authorsError) return <p>Error: {authorsError}</p>;

  return (
    <div className="authors-list w-full lg:w-[70%] mx-auto pb-6 px-4 mt-4">
      <h2 className="text-xl font-bold mb-4 bg-white px-4 py-6">المؤلفون : {authors.length}</h2>
      <div className="grid grid-cols-6 gap-2 ">
        {authors.length > 0 ? (
          authors.map((author) => <ShowCard data={author} onClick={() => nav(`/show/author/${author.slug}`)} />)
        ) : (
          <p>لا توجد مؤلفين لعرضهم</p>
        )}
      </div>
    </div>
  );
};

export default ShowAuthors;
