import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeletePopup from "../../../../components/common/Popup modal/deletePopup";
import Pagination from "../../../../components/common/pagination/pagination";
import CustomTable from "../../components/Table/CustomTable";
import {
  addDiscount,
  deleteDiscount,
  getDiscounts,
  updateDiscount,
} from "../../../../lib/redux/slices/discountsSlice";
import ActionButton from "../../../../components/common/button/actionButton";
import {
  closeModal,
  openModal,
  toggleModal,
} from "../../../../lib/redux/slices/modalSlice";

import ModalForm from "../../../../components/common/Popup modal/ModalForm";
import useDiscountFields from "./discountFields";
import {
  resetDiscountData,
  updateDiscountData,
} from "../../../../lib/redux/slices/discountDataSlice";

function DiscountProducts() {
  const { Fields } = useDiscountFields();
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteUser, setDeleteUser] = useState(false);
  const onPageChange = (page) => setCurrentPage(page);
  const itemsPerPage = 10;
  
  const openModals = useSelector((state) => state.modal.openModals);
  const isOpen = (id) => openModals[id] || false;
  
  const Notfound = "لم يتم العثور على أي خصومات";
  
  const idEdit = "idEdit";
  const idAdd = "idAdd";
  
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.discounts.loading);
  const discountData = useSelector((state) => state.discountData);
  const discounts = useSelector(
    (state) => state.discounts.discounts?.data || []
  );
  const totalDiscounts = useSelector(
    (state) => state.discounts.discounts?.total || 0
  );

  console.log(discountData);

  useEffect(() => {
    dispatch(getDiscounts({ page: currentPage, limit: itemsPerPage }));
  }, [dispatch, deleteUser, currentPage]);

  const handleOpenModal = useCallback(
    (id) => {
      dispatch(openModal({ id }));
      dispatch(resetDiscountData());
    },
    [dispatch]
  );

  const handleClose = useCallback(
    (id) => {
      dispatch(closeModal(id));
    },
    [dispatch]
  );

  const columns = [
    "عنوان المنتج",
    "السعر الأصلي",
    "خصم",
    "السعر بعد الخصم",
    "تاريخ البدأ",
    "تاريخ الانتهاء",
    "حد الأستخدام",
    "الحالة",
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentData = Array.isArray(discounts)
    ? discounts.slice(indexOfFirstItem, indexOfLastItem).map((discount) => {
        const price = Number(discount.product?.price);
        const limit = discount.usage_limit
          ? `${discount.times_used ? discount.times_used : 0}/${discount.usage_limit}`
          : `${discount.times_used ? discount.times_used : "مفتوح الحد"}`;

        const discountValue = Number(discount.discount_value);
        const discountStatus = discount.status === "active" ? "نشط" : "غير نشط";
        let discountedPrice = price;

        if (discount.discount_type === "percentage") {
          discountedPrice = price - price * (discountValue / 100);
        } else if (discount.discount_type === "fixed") {
          discountedPrice = price - discountValue;
        }

        return {
          id: discount.id,
          info: [
            discount.product?.product_name || "-",
            discount.product?.price.toLocaleString() || "-",
            `${discountValue.toLocaleString()}${
              discount.discount_type === "percentage" ? "%" : ""
            }`,
            discountedPrice.toLocaleString() || "-",
            discount.start_date || "-",
            discount.end_date || "-",
            limit || "-",
            discountStatus || "-",
          ],
        };
      })
    : [];

  // التعديل
  const handlEdit = (id) => {
    const discount = discounts.find((item) => item.id === id);
    if (discount) {
      dispatch(updateDiscountData(discount));
      dispatch(toggleModal(idEdit));
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!discountData.product_id) {
        return;
      }
  
      if (discountData.id === "") {
        const response = await dispatch(addDiscount(discountData));
        dispatch(toggleModal(idAdd));
      } else {
        const response = await dispatch(updateDiscount({id:discountData.id ,discountData}));
        dispatch(toggleModal(idEdit));
      }
    },
    [dispatch, idAdd, idEdit, discountData]
  );

  // الحذف
  const handleDelete = (item) => {
    setSelectedItem(item);
    setShowModal(true);
    console.log(selectedItem);
  };

  const confirmDelete = () => {
    if (!selectedItem) return;

    dispatch(deleteDiscount(selectedItem));
  };


  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>خصم المنتجات</h2>
        <ActionButton
          text="إضافة خصم جديد"
          className="bg-[var(--sec)]"
          onClick={() => handleOpenModal(idEdit)}
        />
      </div>

      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">أدارة الخصومات</div>
        <div className="url-path slash">/</div>
        <div className="url-path">خصم المنتجات</div>
      </div>
      <CustomTable
        columns={columns}
        data={currentData}
        onDelete={handleDelete}
        onEdit={handlEdit}
        loading={loading}
        Notfound={Notfound}
      />
      <DeletePopup
        show={showModal}
        setOpenModal={setShowModal}
        onConfirm={confirmDelete}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalDiscounts / itemsPerPage)}
        onPageChange={onPageChange}
        previousLabel="السابق"
        nextLabel="التالي"
        showIcon={true}
      />
      <ModalForm
        open={isOpen(idAdd) || isOpen(idEdit)}
        onClose={() => {
          handleClose(idAdd);
          handleClose(idEdit);
        }}
        formTitle={`${discountData.id === "" ? "إضافة خصم جديد" : "تعديل الخصم"}`}
        handleSubmit={handleSubmit}
        fields={Fields}
        loading={loading}
        btnText={"تحديث أضافة"}
      />
    </div>
  );
}

export default DiscountProducts;
