// RequierAuth.js
import React ,{ useContext } from "react";
import { User } from "../../context/UserContext";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

export default function RequireAuth() {
  const user = useContext(User);
  const location = useLocation();
  // cookie
  const cookie = new Cookies();
  const token = cookie.get("Bearer");

  return token ? (
    <Outlet />
  ) : (
    <Navigate state={{ form: location }} replace to="/Login" />
  );
}
