import React from 'react';

const FileInput = ({ onChange, multiple = false }) => {
  const fileInputRef = React.createRef();

  const handleFileChange = (event) => {
    onChange(event);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="file-input">
      <input
        type="file"
        name="images"
        className="file-btn"
        id="files"
        ref={fileInputRef}
        multiple={multiple}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <input
        type="button"
        className="file-btn"
        value="صورة المنتج"
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default FileInput;
