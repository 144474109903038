import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axios/axiosInstance';
import { toast } from "sonner";

// Action لاستدعاء البيانات
export const getPublisher = createAsyncThunk('publishers/getPublisher', async () => {
  try {
    const response = await axiosInstance.get('/publisher');
    return response.data;
  } catch (error) {
    throw new Error('فشل في استدعاء الناشرين: ' + error.message);
  }
});

// Action لإضافة ناشر جديد
export const newPublisher = createAsyncThunk('publishers/newPublisher', async (publisher, { dispatch, rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/publisher', publisher);
    toast.success("تمت الإضافة بنجاح");
    dispatch(getPublisher()); // تحديث قائمة الناشرين بعد الإضافة
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.message);
      return rejectWithValue({ status: error.response.status, message: error.response.data.message });
    } else {
      toast.error('فشل في إضافة الناشر: ' + error.message);
      return rejectWithValue({ status: 500, message: 'فشل في إضافة الناشر: ' + error.message });
    }
  }
});

// Action لحذف ناشر
export const deletePublisher = createAsyncThunk('publishers/deletePublisher', async (publisherId, { dispatch, rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/publisher/${publisherId}`);
    toast.success("تم الحذف بنجاح");
    dispatch(getPublisher()); // تحديث قائمة الناشرين بعد الحذف
    return publisherId;
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.message);
      return rejectWithValue({ status: error.response.status, message: error.response.data.message });
    } else {
      toast.error('فشل في حذف الناشر: ' + error.message);
      return rejectWithValue({ status: 500, message: 'فشل في حذف الناشر: ' + error.message });
    }
  }
});

// Action لتحديث بيانات ناشر
export const updatePublisher = createAsyncThunk('publishers/updatePublisher', async ({ publisherId, publisher }, { dispatch, rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`/publisher/${publisherId}`, publisher);
    toast.success("تم التحديث بنجاح");
    dispatch(getPublisher()); // تحديث قائمة الناشرين بعد التحديث
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.message);
      return rejectWithValue({ status: error.response.status, message: error.response.data.message });
    } else {
      toast.error('فشل في تحديث الناشر: ' + error.message);
      return rejectWithValue({ status: 500, message: 'فشل في تحديث الناشر: ' + error.message });
    }
  }
});

const publisherSlice = createSlice({
  name: 'publishers',
  initialState: {
    publishers: [],
    status: 'idle',
    error: null,
    statusCode: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.statusCode = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling getPublisher
      .addCase(getPublisher.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPublisher.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.publishers = action.payload;
      })
      .addCase(getPublisher.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.statusCode = action.payload ? action.payload.status : null;
      })
      // Handling newPublisher
      .addCase(newPublisher.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(newPublisher.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.publishers.push(action.payload);
      })
      .addCase(newPublisher.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
        state.statusCode = action.payload ? action.payload.status : null;
      })
      // Handling deletePublisher
      .addCase(deletePublisher.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePublisher.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.publishers = state.publishers.filter((publisher) => publisher.id !== action.payload);
      })
      .addCase(deletePublisher.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
        state.statusCode = action.payload ? action.payload.status : null;
      })
      // Handling updatePublisher
      .addCase(updatePublisher.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePublisher.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.publishers.findIndex((publisher) => publisher.id === action.payload.id);
        if (index !== -1) {
          state.publishers[index] = action.payload;
        }
      })
      .addCase(updatePublisher.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
        state.statusCode = action.payload ? action.payload.status : null;
      });
  },
});

export const { clearPublisherError } = publisherSlice.actions;

export default publisherSlice.reducer;
