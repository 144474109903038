// src/redux/settingsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  siteName: '',
  logo: null,
  theme: 'light', // options: 'light', 'dark'
  siteLanguage: 'ar', // default language
  currencies: ['USD'],
  siteStatus: 'online', // options: 'online', 'maintenance'
  siteClosedMessage: '',
  closedSections: {
    products: false,
    policies: false,
    account: false,
    cart: false,
    checkout: false,
  },
  socialLinks: {
    facebook: '',
    twitter: '',
    instagram: '',
  },
  seo: {
    title: '',
    description: '',
    keywords: '',
  },
  googleAnalyticsCode: '',
  notifications: {
    email: true,
    push: true,
  },
  backupSchedule: 'weekly', // options: 'daily', 'weekly', 'monthly'
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSiteName: (state, action) => {
      state.siteName = action.payload;
    },
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setSiteLanguage: (state, action) => {
      state.siteLanguage = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setSiteStatus: (state, action) => {
      state.siteStatus = action.payload;
    },
    setSiteClosedMessage: (state, action) => {
      state.siteClosedMessage = action.payload;
    },
    setClosedSections: (state, action) => {
      state.closedSections = { ...state.closedSections, ...action.payload };
    },
    setSocialLinks: (state, action) => {
      state.socialLinks = { ...state.socialLinks, ...action.payload };
    },
    setSEO: (state, action) => {
      state.seo = { ...state.seo, ...action.payload };
    },
    setGoogleAnalyticsCode: (state, action) => {
      state.googleAnalyticsCode = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = { ...state.notifications, ...action.payload };
    },
    setBackupSchedule: (state, action) => {
      state.backupSchedule = action.payload;
    },
    resetSettings: () => initialState,
  },
});

export const {
  setSiteName,
  setLogo,
  setTheme,
  setSiteLanguage,
  setCurrencies,
  setSiteStatus,
  setSiteClosedMessage,
  setClosedSections,
  setSocialLinks,
  setSEO,
  setGoogleAnalyticsCode,
  setNotifications,
  setBackupSchedule,
  resetSettings,
} = settingsSlice.actions;

export default settingsSlice.reducer;
