import { useState, useRef, useEffect } from 'react';

const useResize = () => {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const ref = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setSize({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    // Update size on initial render
    handleResize();

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  return [ref, size];
};

export default useResize;
