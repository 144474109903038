import React from "react";
import PercentageIndicator from "./PercentageIndicator";
import PerformanceItem from "./PerformanceItem";

const PerformanceOverview = () => {
  return (
    <div className="flex flex-wrap gap-4">
      <PerformanceItem title="Product Views" value="4,394" percentage={-36} />
      <PerformanceItem title="مبيعات" value="1,284" percentage={15} />
      <PerformanceItem title="الأرباح" value="$26,839" percentage={0.6} />
      <PerformanceItem title="العملاء الجدد" value="3,872" percentage={19} />
      <PerformanceItem title="العملاء العائدون" value="1,267" percentage={21} />
    </div>
  );
};

export default PerformanceOverview;
