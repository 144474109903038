import React , { createContext, useState, useEffect } from "react";
import Cookie from "universal-cookie";

export const User = createContext({});

export default function UserProvider({ children }) {
    const cookie = new Cookie();
    const [auth, setAuth] = useState(() => {
        const token = cookie.get("Bearer");
        if (token) {
            return { token };
        }
        return {};
    });

    useEffect(() => {
        const token = cookie.get("Bearer");
        if (token) {
            setAuth((prevAuth) => ({ ...prevAuth, token }));
        }
    }, []);

    return <User.Provider value={{ auth, setAuth }}>{children}</User.Provider>;
}