import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorBySlug } from "../../../../lib/redux/slices/authorSlice"; // تأكد من المسار الصحيح
import { useParams } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Avatar from "../../../../assets/icons/Avatar.svg";
import ProductCard from "../../../../components/common/card/ProductCard";
import SpinnerCircle from "../../../../components/common/Spinner/SpinnerCircle";

const AuthorDetails = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { selectedAuthor, status } = useSelector((state) => state.authors);

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (slug && selectedAuthor?.slug !== slug) {
      dispatch(getAuthorBySlug(slug));
    }
  }, [dispatch, slug, selectedAuthor?.slug]);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 300);
    return () => clearTimeout(timer);
  }, [status]);

  const authorProducts = useMemo(() => {
    return selectedAuthor?.products || [];
  }, [selectedAuthor?.products]);

  if (status === "failed") {
    return (
      <div className="mt-64 flex items-center justify-center">
        <p>
          عفوًا، حدثت مشكلة. يرجى المحاولة لاحقًا أو التواصل مع الدعم الفني لحل
          المشكلة في حال استمرت.
        </p>
      </div>
    );
  }

  if (!selectedAuthor && status !== "loading") {
    return (
      <div className="mt-64 flex items-center justify-center">
        <p>لم يتم العثور على المؤلف</p>
      </div>
    );
  }

  return (
    <div className="w-full lg:w-[70%] mx-auto pb-6 px-0 xs:px-4 mt-4 ">
      {status === "loading" && showLoading ? (
        <div className="flex flex-col gap-3 h-full pt-24 items-center justify-center">
          <SpinnerCircle />
          <p>جاري التحميل...</p>
        </div>
      ) : (
        <>
          <div className="flex gap-2 bg-white py-6 px-4">
            <div className="h-24 p-2 aspect-square overflow-hidden">
              <img
                src={selectedAuthor?.image || Avatar}
                alt={selectedAuthor?.name || ""}
                loading="lazy"
                className="object-cover outline outline-offset-2 rounded-full outline-gray-200 outline-2 w-full h-full"
              />
            </div>
            <div>
              <h2 className="text-2xl font-bold mt-2">
                {selectedAuthor?.name}
              </h2>
            </div>
          </div>

          {selectedAuthor?.description && (
            <div className="mt-4 w-full bg-white">
              <p className="text-gray-700 mb-4 p-4 text-justify-right-last">
                {selectedAuthor.description}
              </p>
            </div>
          )}

          {authorProducts.length > 0 && (
            <div className="mt-4 w-full bg-white p-4">
              <div className="grid xxs:grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {authorProducts.map((product) => (
                  <LazyLoad key={product.id} height={200} offset={100} once>
                    <ProductCard product={product} />
                  </LazyLoad>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AuthorDetails;
