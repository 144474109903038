import React from 'react';
import PercentageIndicator from './PercentageIndicator'; // تأكد من استيراد مكون PercentageIndicator إذا كان مستخدمًا

const PerformanceItem = ({ title, value = 0, percentage = 0, lastDays = 7 }) => {
    const isPercentagePositive = percentage >= 0; // تحديد ما إذا كانت النسبة إيجابية أم سلبية

    return (
        <div className="perf-item">
            <p className="text-lg font-semibold">{title}</p>
            <div className="flex gap-2 items-center">
                <p className="text-3xl font-bold">{value}</p>
                <PercentageIndicator percentage={percentage} />
            </div>
            <p className="text-gray-500 text-sm mt-2">Last {lastDays} Days</p>
        </div>
    );
};

export default PerformanceItem;
