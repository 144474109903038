import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "../../../components/common/sliders/Slider";
import { Swiper, SwiperSlide } from "swiper/react";
import ActionButton from "../../../components/common/button/actionButton";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, showCart } from "../../../lib/redux/slices/cartSlice";
import ProductSlider from "../../../components/common/sliders/ProductSlider";
import {
  getProducts,
  showProduct,
} from "../../../lib/redux/slices/productsSlices";
import FavoriteButton from "../../../components/common/button/FavoriteButton";
import ReviewsTab from "../../../components/Reviews/ReviewsTab";
import Rating from "../../../components/Reviews/Rating";
import { ShoppingCart } from "../../../assets/icons/CustomIcons";
import SpinnerCircle from "../../../components/common/Spinner/SpinnerCircle";
import "./index.css";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const {
    products,
    loading: loadingProduct,
    statusCode,
  } = useSelector((state) => state.products);
  const currentProduct = useSelector((state) => state.products.currentProduct);

  const { slug } = useParams();
  const [images, setImages] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [mainImage, setMainImage] = useState("");
  const { loading, error: errCart } = useSelector((state) => state.cart);

  useEffect(() => {
    scrollToTop();
  }, [slug]);

  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch(getProducts());
    dispatch(showProduct(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    if (!loadingProduct && currentProduct?.images?.length > 0) {
      setImages(currentProduct.images);
      setMainImage(currentProduct.images[0].image);
    }
  }, [currentProduct]);

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => quantity > 1 && setQuantity(quantity - 1);

  const formatCurrency = (number) => {
    // تنسيق الرقم باللغة الإنجليزية
    const formattedNumber = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);

    return formattedNumber;
  };

  const ProductDetailsAddToCart = (slug) => {
    dispatch(addToCart({ slug, quantity: quantity }));
    dispatch(showCart());
  };

  const copyProductLink = () => {
    const link = document.getElementById("productLink").value;
    navigator.clipboard.writeText(link).then(() => {
      alert("Link copied to clipboard");
    });
  };

  if (loadingProduct) {
    return (
      <div className="mx-auto mt-56">
        <SpinnerCircle />
        <span className="flex justify-center mt-3">جاري التحميل ...</span>
      </div>
    );
  }

  if (statusCode === 404) {
    return <div>المنتج غير متوفر.</div>;
  }

  const customBreakpoints = {
    1380: { slidesPerView: 5 },
    1024: { slidesPerView: 4 },
    768: { slidesPerView: 3 },
    600: { slidesPerView: 2 },
    480: { slidesPerView: 2 },
    0: { slidesPerView: 2 },
  };

  console.log(currentProduct);

  // if (currentProduct.discountData) {
  //   const discountPrice = currentProduct.discountData.price;
  //   console.log("سعر الخصم:", discountPrice);
  // } else {
  //   console.log("لا توجد بيانات خصم متاحة.");
  // }

  return (
    !loadingProduct &&
    currentProduct && (
      <div className="lg:w-[85%] xl:w-[70%] mx-auto md:w-full">
        <section className="cont md:border-x border-y bg-white">
          <div className="sh-prod md-720:flex block">
            <div className="image-prod mx-auto">
              {currentProduct?.images?.length > 0 && (
                <img
                  className="w-full"
                  id="mainImage"
                  src={mainImage}
                  alt={currentProduct?.product_name}
                />
              )}

              {currentProduct?.images?.length > 1 && (
                <div className="mt-3">
                  <Slider
                    className="flex"
                    prevButtonSelector=".custom-prev-button"
                    nextButtonSelector=".custom-next-button"
                    spaceBetween={8}
                    slidesPerView="auto"
                    loop={false}
                  >
                    {currentProduct.images.map((image, imgIndex) => (
                      <SwiperSlide
                        className="flex"
                        style={{ width: "60px", height: "60px" }}
                        key={imgIndex}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "1px solid hsl(0, 0%, 90%)",
                          }}
                          src={image.image}
                          className="swiper-slide thumbnail object-contain"
                          alt={currentProduct.product_name}
                          loading="lazy"
                          onClick={() => setMainImage(image.image)}
                        />
                      </SwiperSlide>
                    ))}
                  </Slider>
                  <div className="swbtn-grup">
                    <div className="btn-prev fas fa-chevron-right"></div>
                    <div className="btn-next fas fa-chevron-left"></div>
                  </div>
                </div>
              )}
            </div>

            <div className="prodation">
              <div className="sh-product-name">
                <div className="path">
                  <i className="fa-sharp fa-solid fa-house"></i>
                  <p>كتب</p>
                  <p className="slash">/</p>
                  <p>
                    {currentProduct?.categories?.length > 0 &&
                      currentProduct.categories[0].name}
                  </p>

                  <p className="slash">/</p>
                  <p>{currentProduct.product_name}</p>
                </div>
                <div className="flex justify-between items-center">
                  <h1>{currentProduct.product_name}</h1>
                  <span>
                    <FavoriteButton slug={currentProduct.slug} />
                  </span>
                </div>
                {currentProduct.discountData &&
                currentProduct.discountData.price ? (
                  <div className="flex gap-3">
                    <span
                      className="discounted-price formatted-number"
                      data-number={currentProduct.discountData.price}
                    >
                      {formatCurrency(currentProduct.discountData.price)}
                    </span>
                    <span
                      className="original-price formatted-number text-[1.2rem] line-through text-red-500"
                      data-number={currentProduct.price}
                    >
                      {formatCurrency(currentProduct.price)}
                    </span>
                  </div>
                ) : (
                  <span
                    className="formatted-number"
                    data-number={currentProduct.price}
                  >
                    {formatCurrency(currentProduct.price)}
                  </span>
                )}

                <div className="quantity">
                  <div
                    className="flex"
                    style={{ border: "1px solid hsl(0, 0%, 90%)" }}
                  >
                    <button
                      style={{
                        borderLeft: "1px solid hsl(0, 0%, 90%)",
                        width: "40px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                      className="change-quantity-btn"
                      onClick={decreaseQuantity}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      className="focus-border-none"
                      style={{
                        borderRadius: "0px",
                        height: "100%",
                      }}
                      value={quantity}
                      min="1"
                      id="quantityInput"
                      readOnly
                    />
                    <button
                      style={{
                        borderRight: "1px solid hsl(0, 0%, 90%)",
                        width: "40px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                      className="change-quantity-btn"
                      onClick={increaseQuantity}
                    >
                      +
                    </button>
                  </div>

                  <div className=" flex gap-2">
                    {/* <ActionButton
                      className="h-12 add-to-cart-btn"
                      text="اشتري الآن"
                    /> */}
                    <ActionButton
                      className="h-12 disabled:opacity-75 bg-[var(--sec)]"
                      text="اضافة الى السلة"
                      loading={loading}
                      svgIcon={<ShoppingCart className="w-6 h-6" />}
                      onClick={() =>
                        ProductDetailsAddToCart(currentProduct.slug)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="priceframe">
                <span className="flex gap-2">
                  <p>المؤلف :</p>
                  {currentProduct?.authors?.length > 0 &&
                    currentProduct.authors.map((author, index) => (
                      <React.Fragment key={author.id}>
                        <p
                          className="cursor-pointer hover:underline"
                          onClick={() => nav(`/show/author/${author.slug}`)}
                        >
                          {author.name}
                        </p>
                        {index < currentProduct.authors.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                </span>

                <span className="flex gap-2">
                  <p>الناشر:</p>
                  {currentProduct &&
                  currentProduct.publisher &&
                  currentProduct.publisher.length > 0 ? (
                    currentProduct.publisher.map((publisher, index) => (
                      <React.Fragment key={publisher.id}>
                        <p
                          className="cursor-pointer hover:underline"
                          onClick={() => nav(`/publishers/${publisher.slug}`)}
                        >
                          {publisher.name}
                        </p>
                        {index < currentProduct.publisher.length - 1 && ", "}
                      </React.Fragment>
                    ))
                  ) : (
                    <span>لا يوجد ناشرون</span>
                  )}
                </span>

                <span className="flex gap-2">
                  <p>التصنيف:</p>
                  {currentProduct &&
                  currentProduct.categories &&
                  currentProduct.categories.length > 0 ? (
                    currentProduct.categories.map((category, index) => (
                      <React.Fragment key={category.id}>
                        <p
                          className="cursor-pointer hover:underline"
                          onClick={() => nav(`/categories/${category.slug}`)}
                        >
                          {category.name}
                        </p>
                        {index < currentProduct.categories.length - 1 && ", "}
                      </React.Fragment>
                    ))
                  ) : (
                    <span>لا يوجد تصنيفات</span>
                  )}
                </span>
                <span className="flex gap-2">
                  عدد الصفحات : {currentProduct.count_page}
                </span>
                <span className="flex gap-2">
                  سنة النشر : {currentProduct.year_publication}
                </span>
              </div>
              <div className="share-icons">
                <input
                  type="hidden"
                  value={window.location.href}
                  id="productLink"
                  readOnly
                />
                <span onClick={copyProductLink}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                    />
                  </svg>
                </span>
                <i className="fa-brands fa-threads"></i>
                <i className="fa-brands fa-whatsapp"></i>
                <i className="fa-brands fa-threads"></i>
                <i className="fa-brands fa-facebook"></i>
              </div>
            </div>
          </div>
        </section>
        <section className="cont md:border-x border-y pb-8 bg-white flex gap-3 justify-center items-center flex-wrap">
          <Rating rating={3.5} votes={150} />
          <span className="h-full w-[1px] bg-gray-300" />
          <div className="flex flex-col justify-center items-center">
            <span className="ratings-rate font-hairline text-blueGray-700">
              <span className="text-3xl text-gray-700">{1900}</span>
            </span>
            <div>عدد المشاهدات</div>
          </div>
        </section>
        <section className="cont bg-white border">
          <div className="mb-3">
            <h3
              className="w-full my-3 text-2xl p-2 font-bold"
              style={{ borderBottom: "1px solid hsl(0, 0%, 90%)" }}
            >
              نُبذة عن الكتاب
            </h3>
            <div
              className="mb-6 px-2 text-justify-right-last"
              dangerouslySetInnerHTML={{ __html: currentProduct.description }}
            />
          </div>
        </section>
        <section className="cont md:border-x border-y pb-8 bg-white">
          <ProductSlider
            label="قد يعجبك ايضاً"
            products={products}
            breakpoints={customBreakpoints}
          />
        </section>
        <ReviewsTab className="cont p-0 md:border-x border-y" />
      </div>
    )
  );
};

export default ProductDetails;
