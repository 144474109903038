import * as React from "react";
import UserProvider from "./context/UserContext";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./lib/redux/store";
import "./style/responsive.css";
import "./style/index.css";
import CmsRoute from "./routes/CmsRoute";


const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <UserProvider>
      <Provider store={store}>
        <CmsRoute />
      </Provider>
    </UserProvider>
  </BrowserRouter>
);
