import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Discounts() {
  const navigate = useNavigate();

  const addDis = () => {
    navigate("/admin/discounts/add");
  };

  const [name, setName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories data when the component mounts
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch("/your-categories-endpoint");
      const data = await response.json();

      if (response.ok) {
        setCategories(data);
      } else {
        console.error("خطأ في جلب الفئات:", data.error || response.statusText);
      }
    } catch (error) {
      console.error("خطأ في الطلب:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      setErrorMessage("عفوًا، كل الحقول مطلوبة");
      return;
    }

    try {
      const response = await fetch("/your-server-endpoint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage("تم إضافة التصنيف بنجاح");
        setName("");

        // Fetch categories again after adding a new one
        fetchCategories();

        setTimeout(() => {
          window.location.href = "add_category";
        }, 3000);
      } else {
        setErrorMessage(`هناك خطأ: ${data.error || response.statusText}`);
      }
    } catch (error) {
      console.error("خطأ في الطلب:", error);
      setErrorMessage("حدث خطأ أثناء معالجة الطلب");
    }
  };

  const handleEdit = (categoryId) => {
    // Handle edit action, e.g., navigate to the edit page
    console.log("Edit category:", categoryId);
  };

  const handleDelete = async (categoryId) => {
    try {
      const response = await fetch("/your-delete-endpoint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ del_id: categoryId }),
      });

      const data = await response.json();

      if (response.ok) {
        // Fetch categories again after deleting one
        fetchCategories();
      } else {
        console.error("خطأ في حذف الفئة:", data.error || response.statusText);
      }
    } catch (error) {
      console.error("خطأ في الطلب:", error);
    }
  };

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>إضافة كوبون خصم جديد</h2>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الأقسام</div>
        <div className="url-path slash">/</div>
        <div className="url-path">التصنيفات</div>
      </div>

      <div className="container-form">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="التصنيف"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input className="seve" type="submit" name="sub_form" value="حفظ" />
        </form>
      </div>

      <table className="table teble-bordered" role="table">
        <thead>
          <tr>
            <th width="2%">#</th>
            <th className="text-right" width="25%">
              الفئات
            </th>
            <th className="text-right" width="25%">
              عدد المنتجات
            </th>
            <th className="text-right" width="48%">
              التحكم
            </th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.categorie_id}>
              <td>
                <span className="badge">{category.categorie_id}</span>
              </td>
              <td>{category.categorie_name}</td>
              <td>0</td>
              <td data-title="التحكم" className="text-center">
                <button
                  onClick={() => handleEdit(category.categorie_id)}
                  style={{
                    padding: "3px 10px",
                    fontWeight: "500",
                    color: "#fff",
                    borderRadius: "5px",
                    backgroundColor: "#95a5a6",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  تعديل
                </button>
                <button
                  onClick={() => handleDelete(category.categorie_id)}
                  style={{
                    padding: "3px 10px",
                    color: "#fff",
                    fontWeight: "500",
                    borderRadius: "5px",
                    backgroundColor: "rgb(223, 20, 10)",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  حذف
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Discounts;
