import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../../../lib/redux/slices/productsSlices";
import { addToInvoice } from "../../../../lib/redux/slices/invoiceSlice";

const ProductPOE = ({ invoiceId }) => {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector((state) => state.products);
  const [freeShipping, setFreeShipping] = useState(true);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const handleAddToCart = (product) => {
    if (!invoiceId) {
      console.error("Invoice ID is not defined");
      return;
    }

    const productData = {
      id: product.id,
      image: product.images[0].image,
      name: product.product_name,
      price: product.price,
      quantity: 1,
    };
    dispatch(addToInvoice({ invoiceId, product: productData }));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="product-poe">
      <div className="flex-grow overflow-y-auto">
        <div className="grid gap-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 2xl:grid-cols-5">
          {Array.isArray(products) &&
            products.map((product) => (
              <div
                className="max-w-xs rounded overflow-hidden shadow-lg bg-white"
                style={{ border: "1px solid hsl(0, 0%, 90%)" }}
                onClick={() => handleAddToCart(product)}
                key={product.id}
              >
                <div className="relative" style={{ paddingBottom: "150%" }}>
                  {freeShipping && (
                    <div
                      className="absolute top-0 right-0 z-50 bg-green-500 text-white text-xs px-2 py-1"
                      style={{ borderBottomLeftRadius: "5px" }}
                    >
                      توصيل مجانا
                    </div>
                  )}
                  {product.images && product.images[0] && (
                    <img
                      className="absolute inset-0 w-full h-full object-cover"
                      src={product.images[0].image}
                      alt={product.product_name}
                      loading="lazy"
                      width="1080"
                      height="1900"
                      srcSet={`${product.images[0].image} 720w`}
                      sizes="(max-width: 1080px) 100vw, 1080px"
                    />
                  )}
                </div>
                <div className="px-2 pb-4">
                  <div
                    className="font-bold text-xl title-card"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {product.product_name}
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-end">
                      <span className="text-sm text-gray-600 ml-1">IQD</span>
                      {product && (
                        <span
                          className="font-bold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          {product.price}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductPOE;
