import React from "react";

export default function Cancelled() {
  return (
        <div className="content-bar">
          <div style={{ position: 'relative', marginTop: '15px' }}>
            <h2 style={{ marginRight: '20px', fontSize: '32px', fontWeight: 'lighter' }}>المنتجات</h2>
          </div>
  
          <div className="path-bar">
            <div className="url-path active-path">لوحة التحكم</div>
            <div className="url-path slash">/</div>
            <div className="url-path">الطلبات</div>
            <div className="url-path slash">/</div>
            <div className="url-path">تم الغاء الطلب</div>
          </div>
          <table
            id="no-more-teble"
            className="table table-borderd"
            role="table"
          >
            <thead>
              <tr>
                <th width="2%">#</th>
                <th className="text-right" width="15%">
                  اسم المنتج
                </th>
                <th className="text-right" width="15%">
                  اسم المشتري
                </th>
                <th className="text-right" width="10%">
                  رقم الهاتف
                </th>
                <th className="text-right" width="10%">
                  المدينة
                </th>
                <th className="text-right" width="10%">
                  تاريخ الطلب
                </th>
                <th className="text-right" width="10%">
                  سعر الطلب
                </th>
                <th className="text-right" width="15%">
                  الحالة
                </th>
                <th className="text-right" width="30%">
                  التحكم
                </th>
              </tr>
            </thead>
          </table>
          <button className="btn-style">تحميل الكل</button>
        </div>
  );
}
