import React, { useState, useEffect } from "react";
import Sidebar from "../components/sidebar/sidebar";
import Headers from "../components/headers/headers";
import { Outlet } from "react-router-dom";
import "./Dashboard.css";
import { Toaster } from "sonner";
const Dashboard = () => {
  return (
    <div className="content">
      <Headers />
      <Sidebar />
      <Outlet />
      <Toaster
        richColors
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
            textAlign: "right",
          },
          className: "my-toast",
        }}
      />
    </div>
  );
};

export default Dashboard;
