import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const data = [
  { label: "موقع الفرز", value: 0, color: "#cfd8dc" },
  { label: "مندوب الاستلام", value: 0, color: "#cfd8dc" },
  { label: "الطلبات المسجلة", value: 0, color: "#cfd8dc" },
  { label: "الطلبات المستلمة", value: 6, color: "#00bcd4" },
  { label: "مندوب التسليم", value: 9, color: "#00bcd4" },
  { label: "مكتب المحافظة", value: 1, color: "#f44336" },
  { label: "المشاكل", value: 0, color: "#cfd8dc" },
  { label: "الطلبات الراجعة", value: 0, color: "#cfd8dc" },
  { label: "مخزن الارجاعات", value: 0, color: "#cfd8dc" },
  { label: "اعادة الارسال", value: 0, color: "#cfd8dc" },
  { label: "تم الارجاع", value: 2, color: "#ff9800" },
];

const ButtonOrders = () => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            position: "relative",
            fontSize: "16px",
            width: "8rem",
            height: "8rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #ddd",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <div
            className="text-center"
            style={{
              width: "5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgressbar
              value={item.value}
              maxValue={10}
              styles={buildStyles({
                pathColor: item.color,
                trailColor: "#cfd8dc",
              })}
            />
            <div
              style={{
                position: "absolute",
                zIndex: "10",
                textAlign: "center",
              }}
            >
              <strong>{item.value}</strong>
            </div>
          </div>
          <div
            style={{
              marginTop: "0.6rem",
              textAlign: "center",
              fontSize: "0.8rem",
              fontWeight: "500",
            }}
          >
            {item.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ButtonOrders;
