import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Reviews from "./Reviews";

function ReviewsTab({ className }) {
  return (
    <div className={`${className}`}>
      <div className="bg-white rounded px-6 pb-6">
        <Tabs>
          <TabList
            className="flex mb-4"
            style={{ borderBottom: "1px solid var(--gainsboro)" }}
          >
            <Tab
              className="flex-1 py-4 text-center cursor-pointer px-2"
              selectedClassName="text-[var(--sec)] tab-selected"
            >
              مراجعات
            </Tab>
            <div className="w-[2px] bg-[var(--gainsboro)]" />
            <Tab
              className="flex-1 py-4 text-center cursor-pointer p-2"
              selectedClassName="text-[var(--sec)] tab-selected"
            >
              القراء
            </Tab>
            <div className="w-[2px] bg-[var(--gainsboro)]" />
            <Tab
              className="flex-1 py-4 text-center cursor-pointer p-2"
              selectedClassName="text-[var(--sec)] tab-selected"
            >
              اقتباسات
            </Tab>
          </TabList>

          <TabPanel>
            <Reviews />
          </TabPanel>

          <TabPanel>
            <div>
              <p>لا يوجد أي مراجعات حتى الآن.</p>
            </div>
          </TabPanel>

          <TabPanel>
            <div>
              <p>لا يوجد أي اقتباسات حتى الآن.</p>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default ReviewsTab;
