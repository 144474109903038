import React, { useState, useEffect } from "react";
import DeletePopup from "../../../../components/common/Popup modal/deletePopup";
import Pagination from "../../../../components/common/pagination/pagination";
import { Toaster, toast } from "sonner";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchCarts } from "../../../../lib/redux/slices/cartSlice";
import ActionButton from "../../../../components/common/button/actionButton";

function CartPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const onPageChange = (page) => setCurrentPage(page);
  const itemsPerPage = 10;
  const [selectedItem, setSelectedItem] = useState(null);

  const dispatch = useDispatch();
  const { cart, loading, error } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(fetchCarts());
  }, [dispatch]);

  const handleDeleteItem = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const confirmDeleteItem = async () => {
    // if (!selectedItem) return;

        toast.success("تم حذف المستخدم بنجاح");

    //   setProducts((prevUsers) =>
    //     prevUsers.filter((user) => user.id !== selectedItem)
    //   );
      setShowModal(false);
      setSelectedItem(null);
  };


// دالة لإضافة الفاصلة إلى السعر
function formatCurrency(value) {
  return value.toLocaleString();
}

const columns = ["اسم المستخدم", "عدد المنتجات", "السعر المجموع"];

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;

// Ensure cart is defined and an array before mapping
const currentData = Array.isArray(cart)
  ? cart.slice(indexOfFirstItem, indexOfLastItem).map((cartItem) => ({
      id: cartItem.cart_id,
      info: [
        cartItem.user.name || "-",
        cartItem.total_products,
        cartItem.total_amount ? formatCurrency(cartItem.total_amount) : "-",
      ],
    }))
  : [];

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>السلة</h2>
      </div>

      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">السلة</div>
      </div>
      <CustomTable
        columns={columns}
        data={currentData}
        loading={loading}
        Notfound="لا توجد بيانات"
        showExtraButton={true}
        showEditButton={false}
        onDelete={handleDeleteItem}
        extraButtonUrl="show"
        classExtraButton="hover:text-slate-50"
      />
      <DeletePopup show={showModal} setOpenModal={setShowModal} onConfirm={confirmDeleteItem} />
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(
          (Array.isArray(cart) ? cart.length : 1) / itemsPerPage
        )}
        onPageChange={onPageChange}
        previousLabel="السابق"
        nextLabel="التالي"
        showIcon={true}
      />
      <Toaster
        richColors
        style={{ marginRight: "280px" }}
        toastOptions={{
          style: {
            background: "#fff",
            border: "1px solid hsl(0, 0%, 90%)",
            padding: "15px",
            minHeight:"70px",
            textAlign: "right",
          },
          className: "my-toast",
        }}
      />
    </div>
  );
}

export default CartPage;
