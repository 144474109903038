import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import PropTypes from "prop-types";
import { toast } from "sonner";

import customStyles from "./customStyles";
import CloseButton from "../../../../components/common/button/CloseButton";
import ActionButton from "../../../../components/common/button/actionButton";

const animatedComponents = makeAnimated();

const generateSlug = (name) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\u0600-\u06FF\w-]+/g, "");
};

const AddOptionButton = ({ innerRef, innerProps, onAddOption }) => {
  return (
    <div ref={innerRef} {...innerProps}>
      <button
        style={{ width: "100%", padding: "10px", textAlign: "center" }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onAddOption();
        }}
      >
        اضافة جديد
      </button>
    </div>
  );
};

const CustomSelect = ({
  fetchAction,
  addAction,
  clearErrorAction,
  entitySelector,
  fields,
  formTitle,
  placeholder,
  entities,
  onChange,
  isLoading = false,
  selectedOptions = [],
}) => {
  const dispatch = useDispatch();
  const { status, error, errorCode } = useSelector(entitySelector);

  const [form, setForm] = useState(
    fields.reduce(
      (acc, field) => ({ ...acc, [field.name]: field.defaultValue || "" }),
      {}
    )
  );
  const [accept, setAccept] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const productData = useSelector((state) => state.productData);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchAction());
    }
  }, [status, dispatch, fetchAction]);

  useEffect(() => {
    if (status === "succeeded") {
      setOpenModal(false);
      setForm(
        fields.reduce(
          (acc, field) => ({ ...acc, [field.name]: field.defaultValue || "" }),
          {}
        )
      );
      setLoading(false);
    } else if (status === "failed" && error) {
      dispatch(clearErrorAction());
      setLoading(false);
    }
  }, [status, error, errorCode, dispatch, clearErrorAction, fields]);

  useEffect(() => {
    if (productData[fields[0].name] === "") {
      setForm((prevForm) => ({
        ...prevForm,
        [fields[0].name]: [],
      }));
    }
  }, [productData, fields]);


  const CustomHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setForm((prevForm) => {
      const newForm = { ...prevForm, [name]: value };
      if (name === "name") {
        newForm.slug = generateSlug(value);
      }
      return newForm;
    });
  }, []);

  const options = entities.map((entity) => ({
    label: entity.name,
    value: entity.id,
  }));

  const handleCustomSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAccept(true);

    if (fields.some((field) => field.required && !form[field.name].trim())) {
      setLoading(false);
      toast.error("الرجاء ملء جميع الحقول المطلوبة");
      return;
    }

    const formData = new FormData();
    fields.forEach((field) => {
      formData.append(field.name, form[field.name]);
    });

    dispatch(addAction(formData));
  };

  const onAddOption = () => {
    setOpenModal(true);
  };

  return (
    <div>
      <Select
        className="w-full"
        components={{
          ...animatedComponents,
          MenuList: (props) => (
            <components.MenuList {...props}>
              {props.children}
              <AddOptionButton onAddOption={onAddOption} />
            </components.MenuList>
          ),
        }}
        options={options}
        onChange={onChange}
        styles={customStyles}
        isLoading={isLoading}
        value={selectedOptions}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        isMulti
      />
      {openModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div
            className="bg-white p-6 rounded shadow relative"
            style={{ width: "400px" }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg">{formTitle}</h2>
              <CloseButton onClick={() => setOpenModal(false)} />
            </div>
            <form onSubmit={handleCustomSubmit} encType="multipart/form-data">
              {fields.map((field) => (
                <div className="mb-3" key={field.name}>
                  <label htmlFor={field.name}>{field.label}</label>
                  {field.type === "select" ? (
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      placeholder={`حدد ${field.label}`}
                      isMulti={false}
                      styles={customStyles}
                      options={field.options}
                      onChange={(selectedOptions) =>
                        setForm((prevForm) => ({
                          ...prevForm,
                          [field.name]: selectedOptions
                            ? selectedOptions.value
                            : "",
                        }))
                      }
                    />
                  ) : (
                    <input
                      type={field.type}
                      className="mt-2 w-full p-2 border rounded"
                      name={field.name}
                      id={field.name}
                      value={form[field.name]}
                      onChange={CustomHandleChange}
                      disabled={field.disabled}
                    />
                  )}
                  {field.required &&
                    form[field.name].trim() === "" &&
                    accept && (
                      <span className="text-red-600 error-message mx-2 mt-1">
                        هذا الحقل مطلوب
                      </span>
                    )}
                </div>
              ))}
              <ActionButton
                className="mt-5 w-full"
                style={{ backgroundColor: "#18bc9c" }}
                text="أضافة"
                type="submit"
                loading={loading}
                onClick={handleCustomSubmit}
              />
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

CustomSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  fetchAction: PropTypes.func.isRequired,
  addAction: PropTypes.func.isRequired,
  clearErrorAction: PropTypes.func,
  entitySelector: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "select", "textarea", "file"]).isRequired,
      defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        })
      ),
      required: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  formTitle: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  entities: PropTypes.array.isRequired,
};

export default CustomSelect;
