import React, { useState } from "react";
import SearchBar from "../components/common/Search/SearchBar";
import SearchPage from "../components/common/Search/searchPage";
import { openModal, toggleModal } from "../lib/redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import Statistics2 from "../pages/dashboard/page/statistics/Statistics2";
const TestPage = () => {
  const dispatch = useDispatch();
  const SearchPageID = "SearchPage"
  const isOpen = useSelector((state) => state.modal.openModals[SearchPageID]);
  const handlToggleModal = (id) => {
    dispatch(openModal(id));
    console.log(isOpen);
  };


  return (
    // <div className="flex items-center justify-between p-4 border-b border-gray-200">
    //   {/* <div class="flex flex-col mb-8 bg-yellow-50 p-2 shadow rounded">
    //     <div class="flex items-start">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         class="flex-shrink-0 rtl:ml-2 ltr:mr-2 w-5 h-5 text-green-500"
    //       >
    //         <path
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //           d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
    //         ></path>
    //       </svg>
    //       <div class="text-xs">
    //         <div class="font-extrabold text-blueGray-700">تسوق آمن</div>
    //         <div class="text-blueGray-500 italic">بياناتك محمية دائمًا </div>
    //       </div>
    //     </div>
    //   </div> */}
    // </div>

    <div className="flex flex-1 flex-col py-8 justify-between">
      {/* <Address className="w-full p-4" /> */}
      {/* <LocationInfo /> */}
      {/* <CountrySelector /> */}
      {/* <ProFile /> */}
      {/* <SearchBar /> */}
       <SearchPage id="SearchPage" /> 
      <button onClick={()=> dispatch(toggleModal("SearchPage"))}>Toggle Modal</button>


      <div className="flex justify-center items-center h-12"></div>

      <div className="flex flex-col mb-8 bg-green-50 p-4 border border-green-200 rounded">
        <div className="flex items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="flex-shrink-0 rtl:ml-2 ltr:mr-2 w-6 h-6 text-green-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
            />
          </svg>
          <div className="text-sm">
            <div className="font-semibold text-blueGray-800">
              تجربة تسوق آمنة
            </div>
            <div className="text-blueGray-600">نحن نحمي معلوماتك بكل حرص</div>
          </div>
        </div>
      </div>

      <div
        className="w-full border px-[1.5rem] py-[0.75rem] gap-[1rem] flex justify-start items-center"
        style={{ backgroundColor: "rgb(243 244 246 / var(--tw-bg-opacity))" }}
      >
        <h3>Filters</h3>
        <span
          className="w-[1px] h-full"
          style={{
            backgroundColor: "rgb(209 213 219 / var(--tw-bg-opacity))",
          }}
        />
        <div className="flex gap-[0.5rem]">
          <ul className="flex gap-2 justify-center items-center hover:bg-gray-200 bg-white border rounded-full px-4 py-1">
            <li className="">روايات</li>
            <li className=" rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </li>
          </ul>
        </div>
      </div> 
      {/* <Statistics2 /> */}

    </div>
  );
};

export default TestPage;
