import { createSlice } from '@reduxjs/toolkit';

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    invoices: [],
  },
  reducers: {
    addInvoice: (state, action) => {
      state.invoices.push(action.payload);
    },
    updateInvoice: (state, action) => {
      const { id, data } = action.payload;
      const invoice = state.invoices.find(inv => inv.id === id);
      if (invoice) {
        Object.assign(invoice.data, data);
      }
    },
    removeInvoice: (state, action) => {
      state.invoices = state.invoices.filter(inv => inv.id !== action.payload);
    },
    addToInvoice: (state, action) => {
      const { invoiceId, product } = action.payload;
      const invoice = state.invoices.find(inv => inv.id === invoiceId);
      if (invoice) {
        // Check if the product is already in the invoice
        const existingProduct = invoice.data.items.find(item => item.id === product.id);
        if (existingProduct) {
          // Update quantity if product is already in the invoice
          existingProduct.quantity += product.quantity; // Adjust the quantity field as needed
        } else {
          // Add the product if it does not exist
          invoice.data.items.push(product);
        }
      }
    },
  },
});

export const { addInvoice, updateInvoice, removeInvoice, addToInvoice } = invoiceSlice.actions;
export default invoiceSlice.reducer;
