// src/features/location/locationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_TOKEN = 'e9aaf7a7ecd1c0';

const axiosInstance = axios.create({
  baseURL: 'https://ipinfo.io',
  headers: {
    'Authorization': `Bearer ${API_TOKEN}`,
    'Content-Type': 'application/json',
  },
});

export const fetchLocation = createAsyncThunk(
  'location/fetchLocation',
  async (ip) => {
    const response = await axiosInstance.get(`/${ip}`);
    return response.data;
  }
);

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchLocation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default locationSlice.reducer;
