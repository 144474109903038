import React, { useState, useRef } from "react";
import "../../style/login.css";
import LOGO from "../../assets/logo/logoOnW.png";
import ActionButton from "../../components/common/button/actionButton";
import customStyles from "../dashboard/components/select/customStyles";
import Select from "react-select";
import {
  Provinces as provincesData,
  Districts as districtsData,
  Countries as countriesData,
} from "../../components/address/Governorates.js";

const Info = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  const [provinceOptions, setProvinceOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);

  // Create refs for the select components
  const provinceSelectRef = useRef(null);
  const districtSelectRef = useRef(null);

  const countryOptions = countriesData.map((country) => ({
    label: country.name,
    value: country.id,
  }));

  const getProvincesOptions = (countryId) => {
    const provinces = provincesData[countryId] || [];
    return provinces.map((province) => ({
      label: province.name,
      value: province.id,
    }));
  };

  const getDistrictsOptions = (provinceId) => {
    const districts = districtsData[provinceId] || [];
    return districts.map((district) => ({
      label: district.name,
      value: district.id,
    }));
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedProvince(null);
    setSelectedDistrict(null);
    setProvinceOptions(getProvincesOptions(selectedOption ? selectedOption.value : null));
    setDistrictOptions([]);

    // Clear the selected values for province and district
    if (provinceSelectRef.current) {
      provinceSelectRef.current.setValue(null);
    }
    if (districtSelectRef.current) {
      districtSelectRef.current.setValue(null);
    }
  };

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption);
    setSelectedDistrict(null);
    if (selectedOption) {
      setDistrictOptions(
        getDistrictsOptions(selectedOption.value)
      );
    } else {
      setDistrictOptions([]);
    }
  };

  const handleDistrictChange = (selectedOption) => {
    setSelectedDistrict(selectedOption);
  };

  return (
    <div className="login-form-cont">
      <form className="form sm:w-full" method="post">
        <div className="img-logo">
          <img src={LOGO} alt="Logo" />
        </div>
        <div>
          <div className="mb-2.5 flex flex-col gap-0.5">
            <span>الدولة</span>
            <Select
              closeMenuOnSelect={true}
              placeholder="حدد دولتك"
              options={countryOptions}
              onChange={handleCountryChange}
              styles={customStyles}
            />
          </div>
          <div className="mb-2.5 flex flex-col gap-0.5">
            <span>المدينة</span>
            <Select
              ref={provinceSelectRef}
              closeMenuOnSelect={true}
              placeholder="حدد مدينتك"
              options={provinceOptions}
              onChange={handleProvinceChange}
              styles={customStyles}
              isDisabled={!selectedCountry}
            />
          </div>

          <div className="mb-2.5 flex flex-col gap-0.5">
            <span>المنطقة</span>
            <Select
              ref={districtSelectRef}
              closeMenuOnSelect={true}
              placeholder="حدد المنطقة التي تسكنها"
              options={districtOptions}
              onChange={handleDistrictChange}
              styles={customStyles}
              isDisabled={!selectedProvince}
            />
          </div>
        </div>

        <ActionButton text="متابعة" className="w-full mt-3" />
      </form>
      <span className="mt-5 cursor-pointer">تخطي</span>
    </div>
  );
};

export default Info;
