import React from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Indwz from "../pages/ui/filters/indwx";

// تسجيل العناصر والمقاييس المطلوبة
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const StatisticsPage = () => {
  const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "المبيعات (بالآلاف)",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "الإيرادات (بالآلاف)",
        data: [75, 69, 90, 91, 66, 65, 50],
        fill: false,
        borderColor: "rgb(54, 162, 235)",
        tension: 0.1,
      },
    ],
  };

  const barData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# من الأصوات",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const doughnutData = {
    labels: ["Electronics", "Books", "Clothing"],
    datasets: [
      {
        label: "نسبة المبيعات",
        data: [300, 150, 200],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category", // استخدام 'category' لمحور x
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="statistics-page">
      <h1 className="text-2xl font-bold mb-5">إحصائيات</h1>

      <div className="chart-container mb-10">
        <h2 className="text-xl mb-2">الرسم البياني الخطي</h2>
        <Line data={lineData} options={options} />
      </div>

      <div className="chart-container mb-10">
        <h2 className="text-xl mb-2">الرسم البياني الشريطي</h2>
        <Bar data={barData} />
      </div>

      <div className="chart-container mb-10">
        <h2 className="text-xl mb-2">الرسم البياني الدائري</h2>
        <Doughnut data={doughnutData} />
      </div>
    </div>
  );
};

// export default StatisticsPage;

const Test = () => {
  return (
    <div className="container flex-1 p-6">
      {/* <StatisticsPage /> */}
      {/* <Indwz /> */}
      <div class="flex flex-col">
        <h3 class="text-lg font-semibold">
          Filters<span class="text-sm text-gray-500">, active</span>
        </h3>
        <div
          aria-hidden="true"
          class="hidden md:block h-0.5 bg-gray-300 my-2"
        ></div>
        <div class="flex flex-col">
          <div class="flex items-center justify-between">
            <span class="flex items-center">
              <span class="text-base font-medium">Objects</span>
              <button
                type="button"
                class="ml-2 flex items-center text-red-500 hover:text-red-700 focus:outline-none"
              >
                <span class="sr-only">Remove filter for Objects</span>
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 8 8"
                  class="w-4 h-4"
                >
                  <path
                    d="M1 1l6 6m0-6L1 7"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;
