import { useEffect } from "react";
import { OptionsProduct } from "../../../../lib/redux/slices/productsSlices";
import { useDispatch, useSelector } from "react-redux";
import { updateDiscountData } from "../../../../lib/redux/slices/discountDataSlice";

const useDiscountFields = () => {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.products.productOptions);
  const discountData = useSelector((state) => state.discountData);
  const { loading, status, error } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(OptionsProduct());
  }, [dispatch]);

  useEffect(() => {
    if (discountData.discount_type === "percentage" && discountData.discount_value === "") {
      handleChange("discount_value", "%0"); 
    }
  }, [discountData.discount_type, discountData.discount_value]);

  const OptionsData = options.map((option) => ({
    label: option.product_name,
    value: option.id,
  }));

  const handleChange = (name, value) => {
    dispatch(updateDiscountData({ [name]: value }));
  };

  const Fields = [
    {
      name: "product_id",
      label: "المنتج",
      type: "select",
      options: OptionsData,
      required: true,
      disabled: false,
      value: discountData.product_id || "",
      onChange: (event) => handleChange("product_id", event.target.value),
    },
    {
      name: "discount_type",
      label: "نوع الخصم",
      type: "select",
      required: true,
      options: [
        { value: "percentage", label: "نسبة مئوية" },
        { value: "fixed", label: "ثابت" },
      ],
      value: discountData.discount_type,
      disabled: false,
      onChange: (event) => handleChange("discount_type", event.target.value),
    },
    {
      name: "discount_value",
      label: "قيمة الخصم",
      type: "discount",
      required: true,
      disabled: false,
      discount_type : discountData.discount_type,
      value: discountData.discount_value || "",
      onChange: (event) => handleChange("discount_value", event.target.value),
    },
    {
      name: "start_date",
      label: "تاريخ البدء",
      type: "date",
      required: true,
      disabled: false,
      value: discountData.start_date || "",
      onChange: (event) => handleChange("start_date", event.target.value),
    },
    {
      name: "end_date",
      label: "تاريخ الانتهاء",
      type: "date",
      required: true,
      disabled: false,
      value: discountData.end_date || "",
      onChange: (event) => handleChange("end_date", event.target.value),
    },
    {
      name: "usage_limit",
      label: "حد الاستخدام",
      type: "number",
      required: false,
      disabled: false,
      value: discountData.usage_limit || "",
      onChange: (event) => handleChange("usage_limit", event.target.value),
    },
  ];

  return { Fields, loading, status, error };
};

export default useDiscountFields;
