import React, { useState, useEffect } from "react";
import PerformanceOverview from "./PerformanceOverview";
import "./statistics.css"
import UserChart from "../../components/Charts/UserChart.tsx";
// Statistics 
function Statistics() {
  const [publishers, setPublishers] = useState([]);

  useEffect(() => {
    // يمكنك استخدام fetch أو axios لاسترجاع البيانات من الخادم
    // على سبيل المثال:
    // fetch('رابط الخادم/api/publishers')
    //   .then(response => response.json())
    //   .then(data => setPublishers(data));

    // لغرض التجربة، يتم تكوين البيانات محليًا
    const mockData = [
      {
        publisher_id: 1,
        publisher_name: "دار 1",
        originalPrice: 100,
        wholesalePrice: 80,
      },
    ];
    setPublishers(mockData);
  }, []);

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>الأحصائيات</h2>
      </div>
      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الأحصائيات</div>
      </div>
      <PerformanceOverview />
      <UserChart />
    </div>
  );
}

export default Statistics;
