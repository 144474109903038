import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import CartItem from "./CartItems";
import CartShippingThreshold from "./CartShippingThreshold";
import useClickOutside from "../../../Hooks/useClickOutside";
import Spinner from "../../../components/common/Spinner/Spinner";
import CloseButton from "../../../components/common/button/CloseButton";
import ActionButton from "../../../components/common/button/actionButton";
import {
  showCart,
  openCart,
  closeCart,
} from "../../../lib/redux/slices/cartSlice";
import { ShoppingCart } from "../../../assets/icons/CustomIcons";

const CartMenu = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  
  const isOpen = useSelector((state) => state.cart.isOpen);
  const cart = useSelector((state) => state.cart.cart || { items: [] });
  const loading = useSelector((state) => state.cart.loading);
  const status = useSelector((state) => state.cart.status);
  const statusCode = useSelector((state) => state.cart.statusCode);

  const [loadingUQ, setLoadingUQ] = useState(loading);

  useEffect(() => {
    const loadCart = () => {
      dispatch(showCart())
        .unwrap()
        .then(() => {})
        .catch((error) => {})
        .finally(() => {
          setLoadingUQ(false);
        });
    };

    loadCart();
  }, [dispatch]);

  const items = (cart.cart && cart.cart.items) || [];

  const subtotal = items.reduce(
    (acc, item) => acc + (item.quantity || 0) * (item.product.price || 0),
    0
  );
  const tax = subtotal * 0.01;
  const shipping = 0;
  const total = subtotal + tax + shipping;

  const handleOpen = () => {
    dispatch(openCart());
  };

  const handleClose = () => {
    dispatch(closeCart());
  };

  const SidebarRef = useClickOutside(handleClose, handleOpen);

  const handlCheckoutBtn = () => {
    nav("cart/checkout");
    dispatch(closeCart());
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-[899] bg-[#0000004b]">
          <motion.div
            ref={SidebarRef}
            className="fixed left-0 top-0 text-primary bg-[#f9f9f9] w-[350px] h-full mr-auto flex flex-col"
            style={{ zIndex: 99999, borderRight: "1px solid hsl(0, 0%, 90%)" }}
            initial={{ opacity: 0, x: "-100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "-100%" }}
            transition={{ duration: 0.3 }}
          >
            <div
              className="text-2xl bg-white font-semibold text-onyx border-b border-gainsboro py-5 px-4 flex items-center gap-4"
              style={{ borderBottom: "1px solid hsl(0, 0%, 90%)" }}
            >
              <CloseButton onClick={handleClose} />
              <span className="flex gap-2 items-center justify-end flex-1">
                السلة
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-7 w-7"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              </span>
            </div>
            <div className="flex flex-col flex-grow overflow-hidden">
              <div className="flex flex-col flex-grow overflow-y-auto">
                <div
                  className="flex-grow py-3 space-y-2"
                  style={{ overflowY: "auto" }}
                >
                  {loading && (
                    <motion.div
                      className="flex items-center justify-center"
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Spinner />
                      <p>جاري التحميل...</p>
                    </motion.div>
                  )}

                  <CartShippingThreshold
                    percent={50}
                    threshold="20.000 ر.ع"
                    currentWidth={50}
                  />

                  {statusCode === 401 ? (
                    <div className="mt-28 font-medium flex flex-col gap-4 justify-center items-center">
                      <h1>يرجى تسجيل الدخول اولاً</h1>
                      <ActionButton
                        text={`تسجيل الدخول الآن`}
                        onClick={() => nav("/login")}
                      />
                      {/* <p>{typeof error === "object" ? error.message : error}</p> */}
                    </div>
                  ) : items.length > 0 ? (
                    items.map((item) => <CartItem key={item.id} item={item} />)
                  ) : (
                    <div className="mt-28 flex flex-col gap-4 justify-center items-center">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                          />
                        </svg>
                      </span>
                      سلتك فارغة. ابدأ بالتسوق الآن!
                    </div>
                  )}
                </div>
              </div>
              {statusCode !== 401 && items.length > 0 && (
                <div
                  className="amount p-3 grid grid-cols-2 gap-y-1 w-full bg-white"
                  style={{ borderTop: "1px solid hsl(0, 0%, 90%)" }}
                >
                  <span>المجموع الفرعي</span>
                  <span className=" text-left">
                    IQD<span>{subtotal.toLocaleString()}</span>
                  </span>
                  <span>التوصيل</span>
                  <span className=" text-left">
                    IQD<span>{tax.toLocaleString()}</span>
                  </span>
                  <span
                    className="font-bold mt-1"
                    style={{ fontSize: "1.1rem" }}
                  >
                    المجموع النهائي
                  </span>
                  <span
                    className="font-bold text-left mt-1"
                    style={{ fontSize: "1.1rem" }}
                  >
                    IQD <span>{total.toLocaleString()}</span>
                  </span>
                  <ActionButton
                    onClick={handlCheckoutBtn}
                    text="اتمام الشراء"
                    className="col-span-2 mt-4"
                  />
                </div>
              )}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default CartMenu;
