// Register.js
import LOGO from "../../assets/logo/logoOnW.png";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import ActionButton from "../../components/common/button/actionButton";
import { useDispatch, useSelector } from "react-redux";
import  {SignUp}  from "../../lib/redux/slices/authSlice";

const Register = () => {
  const [accept, setAccept] = useState(false);
  const [form, setForm] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    repeat: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setAccept(true);

    if (form.password !== form.repeat) {
      return; 
    }

    // استدعاء thunk للتسجيل
    const result = await dispatch(SignUp(form));

    if (result.meta.requestStatus === "fulfilled") {
      navigate("/admin");
    }
  }

  return (
    <div className="login-form-cont">
      <form method="post" className="form shadow">
        <div className="img-logo">
          <img src={LOGO} alt="Logo" />
        </div>

        <div className="mb-4">
          <label htmlFor="name">الأسم</label>
          <input
            type="text"
            className="input-box"
            name="name"
            id="name"
            value={form.name}
            onChange={handleChange}
          />
          {form.name.trim() === "" && accept && (
            <span className="text-red-600 error-message">هذا الحقل مطلوب</span>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="email">أسم المستخدم</label>
          <input
            type="text"
            className="input-box"
            name="username"
            id="username"
            value={form.username}
            onChange={handleChange}
          />
          {form.username === "" && accept && (
            <span className="text-red-600">هذا الحقل مطلوب</span>
          )}

          {form.username.length < 5 && accept && form.username !== "" && (
            <span className="text-red-600">
              يجب أن يكون اسم المستخدم على الأقل 5 أحرف
            </span>
          )}

          {/* {usernameErr &&
            form.username.length < 5 &&
            accept &&
            form.email !== "" && (
              <span className="text-red-600">
                البريد الإلكتروني مستخدم بالفعل.
              </span>
            )} */}
        </div>

        <div className="mb-4">
          <label htmlFor="email">الأميل</label>
          <input
            type="email"
            className="input-box"
            name="email"
            id="email"
            value={form.email}
            onChange={handleChange}
          />
          {form.email === "" && accept && (
            <span className="text-red-600">هذا الحقل مطلوب</span>
          )}
          {/* {emailErr && accept && form.email !== "" && (
            <span className="text-red-600">
              البريد الإلكتروني مستخدم بالفعل.
            </span>
          )} */}
        </div>

        <div className="mb-4">
          <label htmlFor="password">كلمة المرور</label>
          <input
            type="password"
            className="input-box"
            id="remeber-me"
            name="password"
            value={form.password}
            onChange={handleChange}
          />
          {form.password.length < 8 && accept && (
            <span className="text-red-600">كلمة السر قصيرة</span>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="password">تأكيد كلمة المرور</label>
          <input
            type="password"
            className="input-box"
            name="repeat"
            value={form.repeat}
            onChange={handleChange}
            id="repeat"
          />
          {form.repeat !== form.password && accept && (
            <span className="text-red-600">كلمة السر غير متطابقة</span>
          )}
        </div>

        <div className="checkbox"></div>
        {/* <ButtonSubmit text="تسجيل" loading={loading}/> */}
        <ActionButton
          type="submit"
          text="تسجيل"
          loading={loading}
          onClick={handleSubmit}
          className="w-full bg-[var(--primary)]"
          style={{ height: "45px" }}
        />

        {/* {err !== "" && <span className="m-0 text-center">{err}</span>} */}
      </form>
    </div>
  );
};

export default Register;
