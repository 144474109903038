import React, { useState } from "react";
import useClickOutside from "../../../Hooks/useClickOutside";
import "./index.css";

const Calendar = ({ value, onChange }) => {
  const [visible, setVisible] = useState(false);
  const [startYear, setStartYear] = useState(
    Math.floor(new Date().getFullYear() / 10) * 10
  );

  // Callback functions for open and close
  const handleOpen = () => setVisible(true);
  const handleClose = () => setVisible(false);

  const clickRef = useClickOutside(handleClose, handleOpen);

  const handleYearClick = (year) => {
    onChange(year);
    setVisible(false);
  };

  const nextDecade = () => {
    setStartYear(startYear + 10);
  };

  const prevDecade = () => {
    setStartYear(startYear - 10);
  };

  const years = Array.from({ length: 10 }, (_, i) => startYear + i);

  return (
    <div className="calendar-container" ref={clickRef}>
      <input
        type="text"
        value={value || ""}
        onClick={handleOpen}
        readOnly
        className="h-[49px]"
        placeholder="اختر السنة"
      />
      {visible && (
        <div className="calendar-dropdown">
          <div className="calendar-header">
            <button onClick={prevDecade}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
            <span>
              {startYear} - {startYear + 9}
            </span>
            <button onClick={nextDecade}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </button>
          </div>
          <div className="calendar-years">
            {years.map((year) => (
              <div
                key={year}
                className={`calendar-year ${year === value ? "selected" : ""}`}
                onClick={() => handleYearClick(year)}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
