import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch } from "react-redux";
const Home = () => {
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
const dispatch = useDispatch();

// useEffect(() => {
//   axiosInstance.get("https://api.kitabyy.com/products")
//   .then(response => {
//     setProducts(response.data);
//     setProductsCount(response.data.length);
//   });

// }, []);

  const maxValue = 10;
  let adjustedProductsCount = productsCount;
  if (productsCount >= maxValue) {
    adjustedProductsCount =  productsCount * 0.40;
  }

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>الصفحة الرئيسية</h2>
      </div>

      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">الصفحة الرئيسية</div>
      </div>
      <h1 className="text-2xl font-bold text-gray-900">
        اهلا بك في لوحة التحكم!
      </h1>

      <div className="panel-bar flex-wrap">
        <div
          className="grid grid-cols-1 items-center justify-center text-center"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              width: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgressbar
              value={adjustedProductsCount}
              maxValue={maxValue}
              text={productsCount}
              styles={buildStyles({
                pathColor: "#f44336",
                trailColor: "#cfd8dc",
                textSize: "2rem",
                textColor: "#18bc9c",
              })}
            />
          </div>
          <h4 className="mt-4 truncate ">عدد المنتجات</h4>
        </div>

        <div
          className="grid grid-cols-1 items-center justify-center text-center"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              width: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgressbar
              text={"0"}
              value={0}
              maxValue={10}
              styles={buildStyles({
                pathColor: "#f44336",
                trailColor: "#cfd8dc",
                textSize: "2rem",
                textColor: "#18bc9c",
              })}
            />
          </div>
          <h4 className="mt-4 truncate ">عدد الطلبات</h4>
        </div>
        <div
          className="grid grid-cols-1 items-center justify-center text-center"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              width: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgressbar
              text={"0"}
              value={0}
              maxValue={10}
              styles={buildStyles({
                pathColor: "#f44336",
                trailColor: "#cfd8dc",
                textSize: "2rem",
                textColor: "#18bc9c",
              })}
            />
          </div>
          <h4 className="mt-4 truncate ">الطلبات في انتظار التأكيد</h4>
        </div>
        <div
          className="grid grid-cols-1 items-center justify-center text-center"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              width: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgressbar
              text={"0"}
              value={0}
              maxValue={10}
              styles={buildStyles({
                pathColor: "#f44336",
                trailColor: "#cfd8dc",
                textSize: "2rem",
                textColor: "#18bc9c",
              })}
            />
          </div>
          <h4 className="mt-4 truncate ">الطلبات المستلمة</h4>
        </div>
        <div
          className="grid grid-cols-1 items-center justify-center text-center"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              width: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgressbar
              text={"0"}
              value={0}
              maxValue={10}
              styles={buildStyles({
                pathColor: "#f44336",
                trailColor: "#cfd8dc",
                textSize: "2rem",
                textColor: "#18bc9c",
              })}
            />
          </div>
          <h4 className="mt-4 truncate ">التقييمات</h4>
        </div>
      </div>

      <div className="">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-5">
          <div
            className="p-4 rounded-lg"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "1px solid var(--gainsboro)",
            }}
          >
            <h4 className="text-lg font-bold">رابط متجرك</h4>
            <span className=" text-zinc-950 ">htps://basraCL.com</span>
          </div>

          <div
            className="p-4 rounded-lg"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "1px solid var(--gainsboro)",
            }}
          >
            <h2 className="text-xl font-semibold mb-2">حدود المنتجات</h2>
            <div>
              <div>
                <p>579/1000</p>
                <div className="w-full bg-gray-700 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full"
                    // style="width: 57.9%;"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-5">
          <div
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "1px solid var(--gainsboro)",
            }}
          >
            <h4 className="text-xl font-semibold mb-2 text-zinc-950 ">
              الزبائن الأكثر طلبا
            </h4>
            <span className=" text-zinc-950 ">لايوجد زبائن حالياً</span>
          </div>
          <div
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "1px solid var(--gainsboro)",
            }}
          >
            <h4 className="text-xl font-semibold mb-2 text-zinc-950 ">
              المنتجات الأكثر طلبا
            </h4>
            <span className=" text-zinc-950 ">لايوجد منتجات مؤخرا</span>
          </div>
          <div
            className="p-4 rounded-lg"
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              border: "1px solid var(--gainsboro)",
            }}
          >
            <h2 className="text-xl font-semibold mb-2">
              المحافظات الأكثر طلبا
            </h2>
            <div>
              <div>
                <div className="flex justify-between items-center mb-2">
                  <span>البصرة</span>
                  <span>0 الطلبات</span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span>المثنى</span>
                  <span>0 الطلبات</span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span>دهوك</span>
                  <span>0 الطلبات</span>
                </div>
                <div className="mt-4 text-right">
                  <div className="text-blue-500">مشاهدة الكل</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Card({ title, value, icon, chart }) {
  return (
    <div className="bg-gray-800 p-4 rounded-lg">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">{title}</h2>
        {icon && <span className="text-2xl">{icon}</span>}
      </div>
      <p className="text-3xl mt-2">{value}</p>
      {chart && <Chart />}
    </div>
  );
}

function InfoCard({ title, content }) {
  return (
    <div className="bg-gray-800 p-4 rounded-lg">
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <div>{content}</div>
    </div>
  );
}

function ProgressBar({ value, max }) {
  return (
    <div>
      <p>
        {value}/{max}
      </p>
      <div className="w-full bg-gray-700 rounded-full h-2.5">
        <div
          className="bg-blue-600 h-2.5 rounded-full"
          style={{ width: `${(value / max) * 100}%` }}
        ></div>
      </div>
    </div>
  );
}

function Chart() {
  return (
    <div className="mt-4">
      <svg width="100%" height="50">
        <line
          x1="0"
          y1="25"
          x2="100%"
          y2="25"
          stroke="#ff6384"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}

function RegionList() {
  const regions = [
    { name: "البصرة", orders: 0 },
    { name: "المثنى", orders: 0 },
    { name: "دهوك", orders: 0 },
  ];
  return (
    <div>
      {regions.map((region, index) => (
        <div key={index} className="flex justify-between items-center mb-2">
          <span>{region.name}</span>
          <span>{region.orders} الطلبات</span>
        </div>
      ))}
      <div className="mt-4 text-right">
        <a href="#" className="text-blue-500">
          مشاهدة الكل
        </a>
      </div>
    </div>
  );
}

export default Home;
