import Avatar from "../../../../assets/icons/Avatar.svg";
import React from "react";
// import images from "./../../assets/images/2013-12-09_00292.jpg";

const ProFile = () => {
  return (
    <>
      <div className="w-full max-w-sm mx-auto bg-white shadow rounded overflow-hidden mt-8 p-6">
        <div className="flex gap-4">
          <span className="h-12 w-12 mt-1.5 overflow-hidden outline outline-offset-2 outline-gray-200 outline-2 rounded-full">
            <img src={Avatar} className="h-full w-full object-cover" alt="" />
            {/* <Avatar className="rounded-full w-full h-full" /> */}
          </span>
          <div className="text-right mt-0.5">
            <div className="text-xl font-bold" title="Haider">
              Haider Abd Ali
            </div>
            <div
              className="text-sm text-gray-400"
              title="albsraryhydr@gmail.com"
            >
              albsraryhydr@gmail.com
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-sm mx-auto bg-white shadow rounded overflow-hidden mt-4 p-6">
        
      </div>
    </>
  );
};

export default ProFile;
