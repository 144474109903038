import React, { useEffect, useState } from "react";
import ProductSlider from "../../components/common/sliders/ProductSlider";
import { toast } from "sonner";
import axios from "axios";
import SlickCarousel from "../../components/carousel/slickCarousel";
import SliderList from "./SliderList";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../lib/axios/axiosInstance";
// import { fetchSliders } from "../../lib/redux/slices/slidersSlice";

const Homepage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const sliders = useSelector((state) => state.sliders.sliders);
  const status = useSelector((state) => state.sliders.status);
  const error = useSelector((state) => state.sliders.error);

   useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await axiosInstance.get("/stor/getSliders"); // استخدم axios بدلاً من axiosInstance إذا كان غير معرف
        setData(response.data);
        setLoading(false); // التوقف عن التحميل بعد الحصول على البيانات
      } catch (error) {
        console.error("Error fetching sliders:", error);
        setLoading(false); // التوقف عن التحميل في حال حدوث خطأ
      }
    };

    fetchSliders();
  }, [status, dispatch]);

  return (
    <div className=" lg:w-[85%] xl:w-[70%] mx-auto">
      <SlickCarousel />
      <div className="px-4 lg:p-0">
        <SliderList sliders={sliders} />
      </div>
    </div>
  );
};

export default Homepage;
