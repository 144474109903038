import React from "react";

const Rating = ({ rating, votes }) => {
  // تحديد عدد النجوم الممتلئة
  const filledStars = Math.floor(rating);
  // تحديد النجوم الفارغة
  const emptyStars = 5 - filledStars;

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-between items-end px-2">
        <span className="ratings-rate font-hairline text-blueGray-700">
          <span className="text-3xl text-gray-700">{rating.toFixed(1)}</span>/
          <span className="text-gray-700 base-rating">5</span>
        </span>
      </div>
      <div className="flex items-center ratings-wrapper">
        <div className="flex items-center">
          <div className="flex">
            {[...Array(filledStars)].map((_, index) => (
              <svg
                key={`filled-${index}`}
                className="flex-shrink-0 w-4 h-4 text-yellow-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ))}
            {[...Array(emptyStars)].map((_, index) => (
              <svg
                key={`empty-${index}`}
                className="flex-shrink-0 w-4 h-4 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ))}
          </div>
        </div>
      </div>
      <span
        className="ratings-count text-blueGray-700 font-hairline mt-1"
        title={`${votes} votes`}
      >
        {votes} من التقييمات
      </span>
    </div>
  );
};

export default Rating;
