import React, { useEffect } from "react";
import ProductSlider from "../../../components/common/sliders/ProductSlider";
import { Link, useNavigate } from "react-router-dom";
import ActionButton from "../../../components/common/button/actionButton";
import { useDispatch, useSelector } from "react-redux";
import { showCart } from "../../../lib/redux/slices/cartSlice";
import CheckoutItem from "./CheckoutItem";
import OrderSummary from "../cart/OrderSummary";
import { getProducts } from "../../../lib/redux/slices/productsSlices";

const CheckoutPage = () => {
  const dispatch = useDispatch();

  const { isOpen, cart, loading, error, status } = useSelector((state) => ({
    isOpen: state.cart.isOpen,
    cart: state.cart.cart || { items: [] },
    loading: state.cart.loading,
    error: state.cart.error,
  }));

  const items = (cart.cart && cart.cart.items) || [];

  const subtotal = items.reduce(
    (acc, item) => acc + (item.quantity || 0) * (item.product.price || 0),
    0
  );
  const tax = subtotal * 0.01;
  const shipping = 0;
  // const total = subtotal + tax && tax + shipping ? shipping : 0;
  const total = subtotal;

  const {
    products,
    loading: loadingProduct,
    error: errorProduct,
  } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    const loadCart = () => {
      dispatch(showCart())
        .unwrap()
        .then(() => {})
        .catch((error) => {})
        .finally(() => {});
    };

    loadCart();
  }, [dispatch]);

  console.log(items);

  const navigator = useNavigate();
  return (
    <div className="lg:w-[85%] xl:w-[70%] mx-auto px-3 sm:px-6 py-8">
      <h1 className="text-2xl font-bold mb-6 mx-2">إتمام عملية الشراء</h1>

      {/* معلومات الشحن */}
      <div className="w-full max-w-7xl px-0 md:px-5 lg-6 mx-auto relative bg-white shadow">
        <div className="hidden lg:grid grid-cols-2 py-6">
          <div className="font-normal text-xl leading-8 text-gray-500">
            المنتج
          </div>
          <p className="font-normal text-xl leading-8 text-gray-500 flex items-center justify-between">
            <span className="w-full max-w-[260px] text-center">الكمية</span>
            <span className="w-full max-w-[200px] text-center">المجموع</span>
          </p>
        </div>
        {/* itrm */}
        {error ? (
          <p>Error: {error}</p>
        ) : items.length > 0 ? (
          items.map((item) => <CheckoutItem key={item.id} item={item} />)
        ) : (
          <div className="py-6 flex flex-col gap-3 justify-center items-center">
            <p>سلتك تنتظر منتجاتك المفضلة!</p>
            <ActionButton text="العودة الى الصفحة الرئيسية." onClick={() => navigator("/")} />
          </div>
        )}
      </div>

      {/* المنتجات المقترحة */}
      <div className="my-8">
        <ProductSlider label="قد يعجبك أيضًا" products={products} />
      </div>
      <OrderSummary total={total} />
    </div>
  );
};

export default CheckoutPage;
