import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/axiosInstance"; // تأكد من المسار الصحيح
import Cookies from "universal-cookie";

// طلب تسجيل الدخول
export const login = createAsyncThunk("auth/login", async (credentials) => {
  const response = await axiosInstance.post("/login", credentials);
  return response.data;
});

// طلب تسجيل المستخدم
export const SignUp = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/register", userData);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// إرسال رابط إعادة تعيين كلمة المرور
export const sendResetLink = createAsyncThunk(
  "auth/sendResetLink",
  async (email, { rejectWithValue }) => {
    try {
      await axiosInstance.post("/password", { email });
    } catch (err) {
      return rejectWithValue(err.response.data || "Failed to send reset link");
    }
  }
);

// إعادة تعيين كلمة المرور
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      await axiosInstance.post("/reset-password", data);
    } catch (err) {
      return rejectWithValue(err.response.data || "Failed to reset password");
    }
  }
);

export const validateToken = createAsyncThunk(
  "auth/validateToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/validateToken");
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const cookies = new Cookies();
        return rejectWithValue("Unauthorized - Invalid token");
      }
      return rejectWithValue(error.message);
    }
  }
);

// تجديد التوكن
export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/refresh",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    refreshToken: null,
    status: "idle",
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder) => {
    // تسجيل الدخول
    builder.addCase(login.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.status = "succeeded";
    });
    builder.addCase(login.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || action.error.message;
    });

    // تسجيل المستخدم
    builder.addCase(SignUp.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(SignUp.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.status = "succeeded";
    });
    builder.addCase(SignUp.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || action.error.message;
    });

    // إرسال رابط إعادة تعيين كلمة المرور
    builder.addCase(sendResetLink.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(sendResetLink.fulfilled, (state) => {
      state.status = "succeeded";
    });
    builder.addCase(sendResetLink.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || action.error.message;
    });

    // إعادة تعيين كلمة المرور
    builder.addCase(resetPassword.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.status = "succeeded";
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || action.error.message;
    });

    // التحقق من التوكن
    builder.addCase(validateToken.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(validateToken.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.status = "succeeded";
    });
    builder.addCase(validateToken.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || action.error.message;
    });

    // تجديد التوكن
    builder.addCase(refreshToken.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.status = "succeeded";
    });
    builder.addCase(refreshToken.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || action.error.message;
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
