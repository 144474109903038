import React, { useState, useEffect } from "react";
// import Spinner from "../../components/common/Spinner/Spinner";
import Spinner from "../Spinner/Spinner";

const LoadingPage = ({ loading }) => {
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <div className={`h-screen w-screen flex items-center justify-center bg-white ${isLoading ? '' : 'hidden'}`}>
      <Spinner/>
    </div>
  );
};

export default LoadingPage;
