import React, { useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ActionButton from "../button/actionButton";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../lib/redux/slices/cartSlice";
import { toast } from "sonner";

const ProductCard = ({ product }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const showProductDetails = (productId) => {
    nav(`/products/${productId}`);
  };

  const [loadingStates, setLoadingStates] = useState({});

  const handleAddToCart = (slug) => {
    setLoadingStates((prev) => ({ ...prev, [slug]: true }));
    dispatch(addToCart({ slug, quantity: 1 }))
      .then(() => {
        toast.success("تمت الأضافة بنجاح");
      })
      .catch((error) => {
        toast.error("حدث خطأ اثناء الأضافة , يرجى المحاولة مره اخرى لاحقا");
      })
      .finally(() => {
        setLoadingStates((prev) => ({ ...prev, [slug]: false }));
      });
  };

  return (
    <div
      className="max-w-md rounded overflow-hidden shadow-lg bg-white"
      style={{ minWidth: "150px" }}
    >
      <div
        onClick={() => showProductDetails(product.slug)}
        className="relative"
        style={{
          paddingBottom: "150%",
        }}
      >
        {product.freeShipping && (
          <div className="absolute top-0 left-0 bg-green-500 text-white text-xs px-2 py-1 rounded-br">
            Free shipping
          </div>
        )}
        {product.images && product.images[0] && (
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={product.images[0].image}
            alt={product.product_name}
            loading="lazy"
            width="1080"
            height="1900"
            srcSet={`
                      ${product.images[0].image} 1080w, 
                      ${product.images[0].image} 540w, 
                      ${product.images[0].image} 720w, 
                      ${product.images[0].image} 360w, 
                      ${product.images[0].image} 270w, 
                      ${product.images[0].image} 180w`}
            sizes="(max-width: 1080px) 100vw, 1080px"
          />
        )}
      </div>
      <div className="px-3 pt-2 pb-4">
        <div
          className="font-bold text-xl title-card"
          style={{ fontSize: "0.95rem" }}
        >
          {product.product_name}
        </div>
        <p
          className="text-gray-700 -mt-1 text-base"
          style={{ fontSize: "0.8rem" }}
        >
          {product.authors && product.authors[0] && product.authors[0].name}
        </p>
        <div className="flex items-center justify-between mt-3">
          <div className="flex items-end">
            <span className="text-sm text-gray-600 ml-1">IQD</span>
            {product && (
              <span className="text-xl font-bold">{product.price}</span>
            )}
          </div>
          <div className="flex items-center">
            <svg
              className="w-4 h-4 text-yellow-500 ml-1"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.1 3.374a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.1 3.374c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.84-.197-1.54-1.118l1.1-3.374a1 1 0 00-.364-1.118L2.61 8.8c-.783-.57-.38-1.81.588-1.81h3.462a1 1 0 00.95-.69l1.1-3.374z" />
            </svg>
            <span className="text-[#111827]">{product.rating}4.9</span>
          </div>
        </div>
      </div>
      <div className="px-3 mb-3 bottom-0 flex gap-2 justify-between items-stretch">
        <ActionButton
          onClick={() => handleAddToCart(product.slug)}
          loading={loadingStates[product.slug]}
          textLoading={false}
          className="flex items-center justify-center text-slate-50 bg-gray-900 w-9 h-9 rounded"
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
          }
        />
        <ActionButton
          className="title-card bg-transparent text-gray-900 hover:bg-gray-900 transition ease-in-out duration-500 hover:text-slate-50 flex-1 h-9 text-center font-medium px-3 rounded"
          style={{
            border: "1px solid hsl(0, 0%, 90%)",
            fontSize: "0.8rem",
          }}
          text="عرض"
          onClick={() => showProductDetails(product.slug)}
        />
      </div>
    </div>
  );
};

export default ProductCard;
