import React from "react";
const Cart = () => {
  return (
    <section className="cart">
      <div className="cart-item-box p-3">
        <h2 className="section-heading">مجموع الطلب</h2>

        <div className="product-card">
          <div
            className="p-3 flex gap-3 justify-start"
            style={{
              position: "relative",
              overflow: "hidden",
              fontSize: "20px",
              alignItems: "center",
            }}
          >
            {/* <img src={book} className="product-img4" width="80px" alt="" /> */}
            <div className="detail">
              <p
                className=""
                style={{ fontSize: "1.1rem", color: "hsl(0, 0%, 39%)" }}
              >
                احمد مدحت
              </p>
              <h4
                className="mb-2"
                style={{ fontSize: "1.3rem", marginTop: "-4px" }}
              >
                قبل الفراق بخطوة
              </h4>
              <div className="wrapper flex gap-2">
                <div className="product-qty">
                  <button id="decrement">
                    <ion-icon name="remove-outline"></ion-icon>
                  </button>
                  <span id="quantity">1</span>
                  <button id="increment">
                    <ion-icon name="add-outline"></ion-icon>
                  </button>
                </div>

                <div className="price gap-3">
                  <span>10,000</span>
                  <span>IQD</span>
                </div>
              </div>
            </div>

            <button className="product-close-btn">
              <ion-icon
                className="btn-close-hover"
                name="close-outline"
                size="large"
              ></ion-icon>
            </button>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="discount-token">
          <label for="discount-token" className="label-default">
            بطاقة الهدايا / كوبون خصم
          </label>

          <div className="wrapper-flix">
            {/* <input type="text" name="discount-token" id="discount-token" className="input-dafault"> */}

            <button className="btn btn-outline">تفعيل</button>
          </div>
        </div>

        <div className="amount">
          <div className="subtotal">
            <span> المجموع الفرعي</span>{" "}
            <span>
              IQD<span id="subtotal">45,900</span>
            </span>
          </div>

          <div className="tax">
            <span>ضريبة</span>{" "}
            <span>
              IQD<span id="tax">500</span>
            </span>
          </div>

          <div className="shipping">
            <span>التسوق</span>{" "}
            <span>
              IQD<span id="shipping">0,0</span>
            </span>
          </div>

          <div className="total">
            <span>المجموع النهائي</span>{" "}
            <span>
              IQD<span id="total">46,400</span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;
