import React, { useState, useEffect } from "react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DeletePopup from "../../../../components/common/Popup modal/deletePopup";
import Pagination from "../../../../components/common/pagination/pagination";
import CustomTable from "../../components/Table/CustomTable";
import {
  getProductsTable,
  deleteProduct,
} from "../../../../lib/redux/slices/productsSlices";

function Products() {
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteUser, setDeleteUser] = useState(false);
  const onPageChange = (page) => setCurrentPage(page);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { products, loading, error } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getProductsTable({ page: currentPage, limit: itemsPerPage }));
  }, [dispatch, currentPage, deleteUser]);

  const conAddProduct = () => {
    navigate("/admin/products/add");
  };

  const columns = [
    "عنوان المنتج",
    "سعر الجملة",
    "السعر الأصلي",
    "عدد المبيعات",
    "الكمية المتبقية",
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentData = Array.isArray(products.data)
    ? products.data.map((product) => {
        const price = Number(product.price);

        return {
          id: product.id,
          info: [
            product.product_name || "-",
            product.wholesale_price || "-",
            price.toLocaleString() || "-",
            product.Quantity || "-",
            product.stock || "-",
          ],
        };
      })
    : [];

  const handleDelete = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (!selectedItem) return;

    dispatch(deleteProduct(selectedItem))
      .unwrap()
      .then(() => {
        toast.success("تم حذف المنتج بنجاح");
        setDeleteUser(true);
      })
      .catch((error) => {
        console.error("خطأ في حذف المنتج:", error);
        toast.error("حدث خطأ أثناء حذف المنتج.");
      })
      .finally(() => {
        setShowModal(false);
        setSelectedItem(null);
      });
  };

  return (
    <div className="content-bar">
      <div className="title-bar">
        <h2>المنتجات</h2>
        <button className="btn-style" onClick={conAddProduct}>
          إضافة منتج جديد
        </button>
      </div>

      <div className="path-bar">
        <div className="url-path active-path">لوحة التحكم</div>
        <div className="url-path slash">/</div>
        <div className="url-path">المنتجات</div>
      </div>
      <CustomTable
        columns={columns}
        data={currentData}
        onDelete={handleDelete}
        loading={loading}
      />
      <DeletePopup
        show={showModal}
        setOpenModal={setShowModal}
        onConfirm={confirmDelete}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(products.total / itemsPerPage)}
        onPageChange={onPageChange}
        previousLabel="السابق"
        nextLabel="التالي"
        showIcon={true}
      />
    </div>
  );
}

export default Products;
