// discountDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const getCurrentDate = (daysToAdd = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + daysToAdd);
  return date.toISOString().split("T")[0];
};

const initialState = {
  id: "",
  product_id: "",
  discount_value: "",
  discount_type: "percentage",
  start_date: getCurrentDate(),
  end_date: getCurrentDate(10), 
  usage_limit: "",
  times_used: 0,
  status: "active",
};


const discountDataSlice = createSlice({
  name: "discountData",
  initialState,
  reducers: {
    updateDiscountData(state, action) {
      return { ...state, ...action.payload };
    },
    resetDiscountData() {
      return initialState;
    },
  },
});

export const { updateDiscountData, resetDiscountData } =
  discountDataSlice.actions;
export default discountDataSlice.reducer;
